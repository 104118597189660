import React, { useState, useEffect, useRef } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { DataActionsMenu } from "./DataActionsMenu";
import { AddProductDialog } from "./AddProductDialog";
import { supabase } from "@/lib/supabase";
import { ChevronDown, ChevronUp, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Pencil, Trash2, Columns3, Search, Loader2, Plus } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import Logger from "@/utils/logger";
import { useAuth } from '@/contexts/AuthContext';
import type { InventoryItem } from "@/types/inventory";
import { cn } from "@/lib/utils";
import { ImportInventoryButton } from "./ImportInventoryButton";
import { ExportInventoryButton } from "./ExportInventoryButton";

const PAGE_SIZE_KEY = "inventory-table-page-size";
const COLUMN_VISIBILITY_KEY = "inventory-table-column-visibility";

interface EnhancedInventoryTableProps {
  inventory: InventoryItem[];
  onItemUpdated?: () => void;
  isLoading?: boolean;
}

export function EnhancedInventoryTable({ 
  inventory, 
  onItemUpdated,
  isLoading = false 
}: EnhancedInventoryTableProps) {
  const { toast } = useToast();
  const { canManageInventory } = useAuth();
  
  // State
  const [sorting, setSorting] = useState<SortingState>([
    { id: "item_name", desc: false }
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(() => {
    try {
      const saved = localStorage.getItem(COLUMN_VISIBILITY_KEY);
      return saved ? JSON.parse(saved) : {};
    } catch {
      return {};
    }
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const [pageSize, setPageSize] = useState(() => {
    try {
      const saved = localStorage.getItem(PAGE_SIZE_KEY);
      return saved ? parseInt(saved, 10) : 20;
    } catch {
      return 20;
    }
  });
  const [pageIndex, setPageIndex] = useState(0);

  // Reset page index when pageSize changes
  useEffect(() => {
    setPageIndex(0);
  }, [pageSize]);

  // Effects
  useEffect(() => {
    localStorage.setItem(PAGE_SIZE_KEY, pageSize.toString());
  }, [pageSize]);

  useEffect(() => {
    localStorage.setItem(COLUMN_VISIBILITY_KEY, JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  const columns: ColumnDef<InventoryItem>[] = [
    {
      accessorKey: "sku",
      enableHiding: false,  // SKU should always be visible
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>SKU</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
    },
    {
      accessorKey: "item_name",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Name</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => (
        <TooltipProvider delayDuration={300}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="truncate max-w-[300px]">
                {row.getValue("item_name")}
              </div>
            </TooltipTrigger>
            <TooltipContent 
              side="right"
              className="bg-white p-3 rounded-lg shadow-lg border border-gray-200"
            >
              <div className="text-sm text-gray-900 whitespace-pre-line leading-relaxed">
                {row.getValue("item_name")}
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ),
    },
    {
      accessorKey: "description",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Category</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => (
        <TooltipProvider delayDuration={300}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="truncate max-w-[180px]">
                {row.getValue("description")}
              </div>
            </TooltipTrigger>
            <TooltipContent
              side="right"
              className="bg-white p-3 rounded-lg shadow-lg border border-gray-200"
            >
              <div className="text-sm text-gray-900">
                {row.getValue("description")}
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ),
    },
    {
      accessorKey: "stockInfo.totalQuantity",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Available</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => {
        const item = row.original;
        const amount = item.stockInfo?.totalQuantity || 0;
        return item.is_weight_based 
          ? `${amount.toFixed(2)} kg`
          : Math.round(amount);
      },
    },
    {
      accessorKey: "stockInfo.batchCount",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Active Batches</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
    },
    {
      accessorKey: "vat_rate",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>VAT Rate (%)</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div className="flex items-center gap-2">
            {canManageInventory && (
              <>
                <AddProductDialog 
                  mode="edit"
                  initialItem={item}
                  onProductAdded={onItemUpdated || (() => {})}
                  variant="icon"
                  trigger={
                    <Button
                      variant="ghost"
                      size="icon"
                      className="text-blue-600 hover:text-blue-800 p-1 rounded-md hover:bg-blue-50 h-8 w-8"
                    >
                      <Pencil className="h-4 w-4" />
                    </Button>
                  }
                />
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this item?')) {
                      handleDelete(item);
                    }
                  }}
                  className="text-red-600 hover:text-red-800 p-1 rounded-md hover:bg-red-50 h-8 w-8"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const handleDelete = async (item: InventoryItem) => {
    try {
      Logger.info('Checking item usage', { sku: item.sku });

      if (item.stockInfo?.batchCount > 0) {
        toast({
          variant: "destructive",
          title: "Cannot delete item",
          description: `This item has ${item.stockInfo.batchCount} active batches. Please delete all batches first.`
        });
        return;
      }

      const { data: invoiceItems, error: checkError } = await supabase
        .from('invoice_items')
        .select('id')
        .eq('sku', item.sku);

      if (checkError) {
        Logger.error('Error checking item usage', checkError);
        throw checkError;
      }

      if (invoiceItems && invoiceItems.length > 0) {
        toast({
          variant: "destructive",
          title: "Cannot delete item",
          description: "This item is used in one or more invoices and cannot be deleted."
        });
        return;
      }

      Logger.info('Deleting item', { sku: item.sku });

      const { error: deleteError } = await supabase
        .from('inventory')
        .delete()
        .eq('sku', item.sku);

      if (deleteError) {
        Logger.error('Error deleting item', deleteError);
        throw deleteError;
      }

      Logger.info('Item deleted successfully', { sku: item.sku });
      toast({
        title: "Success",
        description: "Item deleted successfully"
      });

      if (onItemUpdated) {
        onItemUpdated();
      }
    } catch (error) {
      const err = error as Error;
      Logger.error('Error in handleDelete', error);
      toast({
        variant: "destructive",
        title: "Error deleting item",
        description: err.message
      });
    }
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize
  });

  const table = useReactTable({
    data: inventory,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: (updater) => {
      if (typeof updater === 'function') {
        const newPagination = updater(pagination);
        setPagination(newPagination);
        if (newPagination.pageSize !== pagination.pageSize) {
          localStorage.setItem(PAGE_SIZE_KEY, newPagination.pageSize.toString());
        }
      } else {
        setPagination(updater);
        if (updater.pageSize !== pagination.pageSize) {
          localStorage.setItem(PAGE_SIZE_KEY, updater.pageSize.toString());
        }
      }
    },
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      globalFilter,
      pagination
    }
  });

  // References to the component elements
  const exportButtonRef = useRef<HTMLDivElement>(null);
  const importButtonRef = useRef<HTMLDivElement>(null);

  // Handle actions for DataActionsMenu
  const handleExport = () => {
    // Find the export button in the DOM using the ref and trigger its click
    const exportButton = exportButtonRef.current?.querySelector('button') as HTMLButtonElement;
    if (exportButton) {
      exportButton.click();
    }
  };

  const handleImport = () => {
    // Find the import button in the DOM using the ref and trigger its click
    const importButton = importButtonRef.current?.querySelector('button') as HTMLButtonElement;
    if (importButton) {
      importButton.click();
    }
  };

  const handleAnalyzeCsv = () => {
    // Find the analyse button in the DOM and trigger its click
    if (importButtonRef.current) {
      // Look for the Analyse CSV button within the ImportInventoryButton component
      const analyseButton = importButtonRef.current.querySelector('button:has(.analyse-csv), [data-action="analyse-csv"]') as HTMLButtonElement;
      if (analyseButton) {
        analyseButton.click();
      } else {
        // Fallback to finding the first button with FileSearch icon
        const allButtons = importButtonRef.current.querySelectorAll('button');
        for (const button of allButtons) {
          if (button.innerHTML.includes('FileSearch') || button.textContent?.includes('Analyse CSV')) {
            (button as HTMLButtonElement).click();
            return;
          }
        }
        // If we still can't find it, log a message
        console.log("Analyze CSV button not found");
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="flex flex-col items-center gap-2">
          <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
          <p className="text-sm text-gray-500">Loading inventory...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center gap-4">
          <div className="relative">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search all columns..."
              value={globalFilter ?? ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
              className="pl-8 w-[300px] h-9"
            />
          </div>
          
          {/* Column selector moved next to search box */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="sm" className="h-9">
                <Columns3 className="mr-2 h-4 w-4" />
                Columns
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="bg-white border rounded-md shadow-md w-[180px] p-0"
            >
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  // Get header text based on column ID
                  const headerLabels: { [key: string]: string } = {
                    'sku': 'SKU',
                    'item_name': 'Name',
                    'description': 'Category',
                    'stockInfo.totalQuantity': 'Available',
                    'stockInfo.batchCount': 'Active Batches',
                    'vat_rate': 'VAT Rate (%)',
                    'actions': 'Actions'
                  };
                  
                  // Try both dot and underscore formats for stockInfo fields
                  const label = headerLabels[column.id] ||
                              headerLabels[column.id.replace('_', '.')] ||
                              column.id;

                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="relative flex select-none items-center py-2 pl-8 pr-4 text-sm outline-none hover:bg-gray-100 cursor-pointer data-[state=checked]:bg-gray-50"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) => column.toggleVisibility(!!value)}
                    >
                      {label}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        
        {/* Right side with Add Product button and DataActionsMenu */}
        <div className="flex items-center space-x-2">
          {canManageInventory && (
            <AddProductDialog 
              onProductAdded={onItemUpdated || (() => {})}
              trigger={
                <Button variant="default" className="bg-blue-600 hover:bg-blue-700 h-9">
                  <Plus className="mr-2 h-4 w-4" />
                  Add Product
                </Button>
              }
            />
          )}
          <DataActionsMenu 
            onExport={handleExport}
            onImport={handleImport}
            onAnalyzeCsv={handleAnalyzeCsv}
          />
          
          {/* Hidden components to provide functionality */}
          <div className="hidden">
            <div ref={importButtonRef}>
              <ImportInventoryButton onImportComplete={onItemUpdated || (() => {})} />
            </div>
            <div ref={exportButtonRef}>
              <ExportInventoryButton />
            </div>
          </div>
        </div>
      </div>
      
      {/* ...existing code for table... */}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="bg-gray-50">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead 
                      key={header.id} 
                      className={cn(
                        "text-gray-900",
                        header.column.getCanSort() && 
                        "transition-colors hover:bg-gray-100"
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} className="hover:bg-gray-50">
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-between space-x-2 py-4">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">Rows per page</p>
          <Select
            value={table.getState().pagination.pageSize.toString()}
            onValueChange={(value) => {
              table.setPageSize(Number(value));
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={pageSize} />
            </SelectTrigger>
            <SelectContent side="top" className="bg-white border rounded-md shadow-md p-1">
              {[20, 50, 100, 500].map((size) => (
                <SelectItem key={size} value={`${size}`} className="cursor-pointer">
                  {size}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center space-x-2">
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to first page</span>
              <ChevronsLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRight className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to last page</span>
              <ChevronsRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}