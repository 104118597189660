import type { Batch } from "@/types/batch";

/**
 * Core calculation functions
 */

/**
 * Calculate current available quantity for a batch
 * Simple formula: Original - Sum(Used)
 */
export function getAvailableQuantity(batch: Batch, allBatches?: Batch[]): number {
  if (!batch || !batch.inventory) return 0;
  
  const isWeightBased = batch.inventory.is_weight_based;
  
  // Get initial amount from the base batch
  const initial = isWeightBased ? (batch.weight_kilos || 0) : (batch.quantity || 0);
  
  // Get shrinkage amounts - these are stored as negative numbers, so sum them directly
  const shrinkage = (allBatches || [])
    .filter(b => b.batch_number.startsWith('SHR-') && b.base_id === batch.batch_number)
    .reduce((sum, b) => {
      const amount = isWeightBased ? (b.weight_kilos || 0) : (b.quantity || 0);
      return sum + amount; // Shrinkage values are already negative
    }, 0);
  
  // Sum up used quantities from invoices
  const invoiceUsage = (batch.invoice_items || []).reduce((sum, item) => {
    const amount = isWeightBased ? (item.weight_kilos || 0) : (item.quantity || 0);
    return sum + amount;
  }, 0);
  
  // Available = Initial + Shrinkage (negative) - Invoice Usage
  const available = initial + shrinkage - invoiceUsage;
  
  return Math.max(0, available);
}

/**
 * Check if a batch is expired
 * Simple comparison: Expiry date vs Current date
 */
export function isExpired(batch: Batch): boolean {
  if (!batch?.expiry_date) return false;
  return new Date(batch.expiry_date) < new Date();
}

/**
 * Calculate total value of available quantity
 */
export function getAvailableValue(batch: Batch): number {
  if (!batch?.purchase_cost) return 0;
  return getAvailableQuantity(batch) * batch.purchase_cost;
}

/**
 * Calculate days until expiry (negative for expired items)
 */
export function getDaysUntilExpiry(batch: Batch): number {
  if (!batch?.expiry_date) return 0;
  const today = new Date();
  const expiry = new Date(batch.expiry_date);
  const diffTime = expiry.getTime() - today.getTime();
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
}

/**
 * Format quantity with units
 */
export function formatQuantity(amount: number | null, isWeightBased: boolean): string {
  if (amount === null) return "-";
  return isWeightBased ? `${amount.toFixed(3)} kg` : `${Math.round(amount)}`;
}

/**
 * Get quantity label based on type
 */
export function getQuantityLabel(isWeightBased: boolean): string {
  return isWeightBased ? "Weight (kg)" : "Quantity";
}

/**
 * Gets all related batches for a given batch (original + shrinkage)
 */
export function getRelatedBatches(batch: Batch, allBatches: Batch[]): Batch[] {
  return allBatches.filter(b => 
    b.batch_number === batch.batch_number || // Same batch
    b.base_id === batch.batch_number || // Shrinkage entries for this batch
    (batch.base_id && b.base_id === batch.base_id) || // Sibling shrinkage entries
    b.batch_number === batch.base_id // Original batch if this is a shrinkage
  );
}

/**
 * @deprecated Use getAvailableQuantity instead
 */
export const calculateAvailable = getAvailableQuantity;

/**
 * Get the display quantity for a batch
 */
export function getDisplayQuantity(batch: Batch, showAvailable: boolean = false): string {
  if (!batch) return "-";
  
  const isWeightBased = batch.inventory?.is_weight_based;
  const amount = showAvailable ? getAvailableQuantity(batch) : (isWeightBased ? batch.weight_kilos : batch.quantity);
  
  return formatQuantity(amount, !!isWeightBased);
}

/**
 * Generate a suggested batch number based on existing batches
 */
export async function generateBatchSuggestion(
  batchNumber: string,
  supabase: any
): Promise<string> {
  const currentDate = generateDateSuffix();
  
  // First check if base number exists
  const { data: exactMatch } = await supabase
    .from('batches')
    .select('batch_number')
    .eq('batch_number', batchNumber)
    .maybeSingle();

  if (!exactMatch) {
    // Base number doesn't exist, return it as-is
    return batchNumber;
  }

  // Check if input already has a date pattern
  const datePattern = /^(.*?)-(\d{2})(\d{2})(\d{2})$/;
  const hasDateSuffix = datePattern.test(batchNumber);

  if (hasDateSuffix) {
    // Input already has a date suffix, look for incremented versions
    const { data: existingVersions } = await supabase
      .from('batches')
      .select('batch_number')
      .like('batch_number', `${batchNumber}-_`)  // Look only for incremented versions
      .order('batch_number', { ascending: false });

    if (!existingVersions?.length) {
      // No incremented versions exist yet, start with -1
      return `${batchNumber}-1`;
    }
    
    // Find highest increment
    let maxIncrement = 0;
    existingVersions.forEach((batch: { batch_number: string }) => {
      const match = batch.batch_number.match(`${batchNumber}-(\\d+)`);
      if (match && match[1]) {
        const increment = parseInt(match[1]);
        if (!isNaN(increment)) {
          maxIncrement = Math.max(maxIncrement, increment);
        }
      }
    });

    return `${batchNumber}-${maxIncrement + 1}`;
  }

  // Look for any batches with today's date suffix
  const todayPattern = `${batchNumber}-${currentDate}`;
  const { data: todayMatches } = await supabase
    .from('batches')
    .select('batch_number')
    .like('batch_number', `${todayPattern}%`)
    .order('batch_number', { ascending: false });

  if (!todayMatches?.length) {
    // No batches with today's date exist
    return todayPattern;
  }

  // Look for incremented versions of today's pattern
  const { data: incrementedVersions } = await supabase
    .from('batches')
    .select('batch_number')
    .like('batch_number', `${todayPattern}-_`)
    .order('batch_number', { ascending: false });

  if (!incrementedVersions?.length) {
    // No incremented versions yet, start with -1
    return `${todayPattern}-1`;
  }

  // Find highest increment
  let maxIncrement = 0;
  incrementedVersions.forEach((batch: { batch_number: string }) => {
    const match = batch.batch_number.match(`${todayPattern}-(\\d+)`);
    if (match && match[1]) {
      const increment = parseInt(match[1]);
      if (!isNaN(increment)) {
        maxIncrement = Math.max(maxIncrement, increment);
      }
    }
  });

  return `${todayPattern}-${maxIncrement + 1}`;
}

/**
 * Generate date suffix in MMDDYY format
 */
function generateDateSuffix(): string {
  const now = new Date();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const year = String(now.getFullYear()).slice(-2);
  return `${month}${day}${year}`;
}
