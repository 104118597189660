import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Plus, Loader2 } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import { 
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import * as z from "zod";
import { useQuery } from "@tanstack/react-query";
import { cn } from "@/lib/utils";
import Logger from "@/utils/logger";
import { generateBatchSuggestion } from "@/utils/batchUtils";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/textarea";

// Enhanced form schema with better validation
const createBatchSchema = z.object({
  batch_number: z.string().min(1, "Batch number is required"),
  quantity: z.number().optional(),
  weight_kilos: z.number().optional(),
  expiry_date: z.string().optional(),
  warehouse: z.enum(["(+3°C)", "(+25°C)", "(-25°C)", "Paradiso", "Consignment"]),
  shrinkage_reason: z.string().optional(),
}).refine(
  (data) => data.quantity !== undefined || data.weight_kilos !== undefined,
  {
    message: "Either quantity or weight must be specified",
    path: ["quantity"]
  }
);

type CreateBatchFormValues = z.infer<typeof createBatchSchema>;

interface CreateBatchDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onCreated: (batchNumber: string) => void;
  sku: string;
  suggestedBatch?: string;
  suggestedAmount?: number;
  itemName?: string;
}

export function CreateBatchDialog({
  isOpen,
  onClose,
  onCreated,
  sku,
  suggestedBatch,
  suggestedAmount,
  itemName
}: CreateBatchDialogProps) {
  const { toast, dismiss } = useToast();
  const [loading, setLoading] = React.useState(false);
  const [suggestedBatchNumber, setSuggestedBatchNumber] = React.useState<string | null>(null);
  const [shrinkageMode, setShrinkageMode] = React.useState(false);

  // Date suffix utility
  const generateDateSuffix = () => {
    const now = new Date();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const year = String(now.getFullYear()).slice(-2);
    return `${month}${day}${year}`;
  };

  // Handle batch number existence check
  const checkBatchNumber = async (batchNumber: string): Promise<boolean> => {
    const { data, error } = await supabase
      .from('batches')
      .select('batch_number, sku, inventory:inventory!inner(item_name)')
      .eq('batch_number', batchNumber)
      .maybeSingle();

    if (data) {
      const inventory = data.inventory as { item_name: string } | { item_name: string }[];
      const itemName = Array.isArray(inventory)
        ? inventory[0]?.item_name
        : inventory?.item_name ?? 'Unknown Item';

      // Use smart batch suggestion function
      const suggestedNumber = await generateBatchSuggestion(batchNumber, supabase);
      setSuggestedBatchNumber(suggestedNumber);

      toast({
        variant: "default",
        title: "Batch number suggestion",
        duration: 10000,  // 10 seconds for suggestion toasts
        description: (
          <div className="mt-2 space-y-2">
            <p><span className="font-medium">{batchNumber}</span> is already in use by:</p>
            <p className="text-sm text-muted-foreground">
              SKU: {data.sku}<br />
              Product: {itemName}
            </p>
            <div className="mt-3 p-3 bg-accent/5 rounded-lg border border-accent/20">
              <p className="text-sm font-medium text-accent">Suggested alternative:</p>
              <p className="text-lg font-semibold mt-1">{suggestedNumber}</p>
              <p className="text-xs text-muted-foreground mt-1">
                Press Enter to use this number, or type a different one
              </p>
            </div>
          </div>
        ),
      });
      return true;
    }
    setSuggestedBatchNumber(null);
    return false;
  };

  // Get inventory item details
  const { data: inventoryItem } = useQuery({
    queryKey: ['inventory-item', sku],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('inventory')
        .select('is_weight_based, item_name')
        .eq('sku', sku)
        .single();
        
      if (error) throw error;
      return data;
    }
  });

  const form = useForm<CreateBatchFormValues>({
    resolver: zodResolver(createBatchSchema),
    defaultValues: {
      batch_number: suggestedBatch || "",
      quantity: !inventoryItem?.is_weight_based ? suggestedAmount : undefined,
      weight_kilos: inventoryItem?.is_weight_based ? suggestedAmount : undefined,
      expiry_date: "",
      warehouse: "(+3°C)",
    },
  });

  const onSubmit = async (values: CreateBatchFormValues) => {
    try {
      setLoading(true);

      // If shrinkage mode is active, make values negative
      const finalQuantity = shrinkageMode && values.quantity 
        ? -Math.abs(values.quantity) 
        : values.quantity;
      
      const finalWeight = shrinkageMode && values.weight_kilos 
        ? -Math.abs(values.weight_kilos) 
        : values.weight_kilos;
      
      // Check for duplicate batch and get suggestion
      const isDuplicate = await checkBatchNumber(values.batch_number.trim());
      
      // If duplicate found and no suggestion is being used, stop submission
      if (isDuplicate && values.batch_number !== suggestedBatchNumber) {
        return;
      }

      // Create new batch
      const { data: newBatch, error: createError } = await supabase
        .from('batches')
        .insert({
          batch_number: values.batch_number.trim(),
          sku,
          warehouse: values.warehouse,
          expiry_date: values.expiry_date || null,
          [inventoryItem?.is_weight_based ? 'weight_kilos' : 'quantity']: 
            inventoryItem?.is_weight_based ? values.weight_kilos : values.quantity,
          date_received: new Date().toISOString(),
          notes: "",
          shrinkage_reason: shrinkageMode ? values.shrinkage_reason : null,
        })
        .select()
        .single();

      if (createError) {
        Logger.error('Failed to create batch', {
          error: createError,
          sku,
          batch_number: values.batch_number
        });
        throw createError;
      }

      toast({
        title: "Success",
        description: `Batch ${values.batch_number} has been created.`
      });

      onCreated(values.batch_number);
      onClose();
    } catch (error: any) {
      Logger.error('Error creating batch', {
        sku,
        error: error.message
      });
      toast({
        variant: "destructive",
        title: "Error creating batch",
        description: error.message,
        duration: 5000
      });
    } finally {
      setLoading(false);
    }
  };

  const isWeightBased = inventoryItem?.is_weight_based ?? false;

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          form.reset();
          setSuggestedBatchNumber(null);
        } else {
          // Reset state when opening
          setSuggestedBatchNumber(null);
          form.setValue('batch_number', suggestedBatch || '');
        }
        onClose();
      }}
    >
      <DialogContent className="sm:max-w-[600px] w-[95vw] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold tracking-tight">Create New Batch</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            {/* Product Info Section */}
            <div className="rounded-lg border border-accent/20 bg-accent/5 p-4">
              <div className="flex justify-between items-start gap-4">
                <div className="flex items-center gap-4">
                  <div>
                    <div className="text-sm text-muted-foreground mb-1">SKU</div>
                    <div className="text-lg font-semibold text-accent">{sku}</div>
                  </div>
                  <div className="h-8 w-[1px] bg-accent/20" />
                  <div>
                    <div className="text-sm text-muted-foreground mb-1">Product</div>
                    <div className="text-lg font-semibold">
                      {itemName || inventoryItem?.item_name || 'Loading...'}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Shrinkage Mode Toggle */}
            <div className="flex items-center justify-between p-4 mb-4 bg-muted/20 rounded-lg">
              <div>
                <h3 className="font-medium">Shrinkage Mode</h3>
                <p className="text-sm text-muted-foreground">
                  Enable to record inventory loss or damage
                </p>
              </div>
              <Switch
                checked={shrinkageMode}
                onCheckedChange={setShrinkageMode}
                aria-label="Toggle shrinkage mode"
              />
            </div>

            {/* Batch Number Section */}
            <FormField
              control={form.control}
              name="batch_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-sm font-medium">Batch Number</FormLabel>
                  {suggestedBatch && (
                    <FormDescription>
                      Suggested: {suggestedBatch}
                    </FormDescription>
                  )}
                  <FormControl>
                    <div className="space-y-2">
                      <Input
                        placeholder="Enter batch number"
                        {...field}
                        value={field.value || ''}
                        onChange={(e) => {
                          // Just update the field value
                          const value = e.target.value.trim();
                          field.onChange(value);
                        }}
                        onBlur={async (e) => {
                          // Check for duplicates on blur
                          const value = e.target.value.trim();
                          if (value && value !== suggestedBatchNumber) {
                            await checkBatchNumber(value);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && suggestedBatchNumber) {
                            e.preventDefault();
                            field.onChange(suggestedBatchNumber);
                            setSuggestedBatchNumber(null);
                          }
                        }}
                        className={cn(
                          "transition-all duration-200",
                          "focus:border-accent focus:ring-1 focus:ring-accent"
                        )}
                      />
                      {suggestedBatchNumber && (
                        <div className="p-2 bg-accent/5 rounded border border-accent/20">
                          <div className="text-sm font-medium text-accent">Suggested batch number:</div>
                          <div className="mt-1 flex items-center gap-2">
                            <code className="text-lg font-semibold">{suggestedBatchNumber}</code>
                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              className="h-7"
                              onClick={() => {
                                field.onChange(suggestedBatchNumber);
                                setSuggestedBatchNumber(null);
                              }}
                            >
                              Use this
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Amount Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name={isWeightBased ? "weight_kilos" : "quantity"}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">
                      {isWeightBased ? "Weight (kg)" : "Quantity"}
                    </FormLabel>
                    {suggestedAmount && (
                      <FormDescription>
                        Suggested from delivery note
                      </FormDescription>
                    )}
                    <FormControl>
                      <Input
                        type="number"
                        step={isWeightBased ? "0.001" : "1"}
                        placeholder={isWeightBased ? "Enter weight in kilos" : "Enter quantity"}
                        {...field}
                        value={field.value === undefined ? '' : field.value}
                        onChange={(e) => {
                          const value = e.target.value;
                          field.onChange(value === '' ? undefined : Number(value));
                        }}
                        className={cn(
                          "transition-all duration-200",
                          "focus:border-accent focus:ring-1 focus:ring-accent"
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Warehouse Field */}
              <FormField
                control={form.control}
                name="warehouse"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">Warehouse</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger className="bg-white/50 focus:bg-white">
                          <SelectValue placeholder="Select warehouse" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="(+3°C)">(+3°C)</SelectItem>
                        <SelectItem value="(+25°C)">(+25°C)</SelectItem>
                        <SelectItem value="(-25°C)">(-25°C)</SelectItem>
                        <SelectItem value="Paradiso">Paradiso</SelectItem>
                        <SelectItem value="Consignment">Consignment</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {/* Shrinkage Reason Field */}
            {shrinkageMode && (
              <FormField
                control={form.control}
                name="shrinkage_reason"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">Shrinkage Reason</FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        placeholder="Enter reason for shrinkage"
                        className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {/* Dates Section */}
            <div className="rounded-lg border bg-secondary/5 p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="text-sm text-muted-foreground">
                  Date Received: {new Date().toLocaleDateString()}
                </div>
                <FormField
                  control={form.control}
                  name="expiry_date"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium">Expiry Date</FormLabel>
                      <FormControl>
                        <Input 
                          type="date" 
                          {...field}
                          className="bg-white/50 focus:bg-white"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            {/* Actions */}
            <div className="flex justify-end gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={onClose}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button 
                type="submit"
                className={cn(
                  "bg-accent hover:bg-accent/90",
                  "transition-all duration-200"
                )}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Creating...
                  </>
                ) : (
                  'Create Batch'
                )}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
