import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { AddInvoiceDialog } from "@/components/invoices/AddInvoiceDialog";
import { ViewInvoiceDialog } from "@/components/invoices/ViewInvoiceDialog";
import { EditInvoiceDialog } from "@/components/invoices/EditInvoiceDialog";
import { useAuth } from "@/contexts/AuthContext";
import type { Invoice } from "@/types/invoice";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Search, Trash2, ArrowDownLeft } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { MobileHeader } from "@/components/layout/MobileHeader";
import DeliveryNotesModal from "@/components/DeliveryNotesModal";
import { UnimportedBadge } from "@/components/invoices/UnimportedBadge";
import { EnhancedInvoicesTable } from "@/components/invoices/EnhancedInvoicesTable";
import Logger from "@/utils/logger";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

const Invoices = () => {
  const queryClient = useQueryClient();
  const isMobile = useIsMobile();
  const { canManageInvoices, canViewInvoices } = useAuth();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);

  // Handle refetch wrapper
  const handleRefetch = async () => {
    await refetch();
  };

  // Data fetching
  const { data: invoices, refetch } = useQuery({
    queryKey: ['invoices'],
    enabled: canViewInvoices,
    queryFn: async () => {
      Logger.info('Starting invoice fetch...');
      
      try {
        const { data: invoiceData, error: invoiceError } = await supabase
          .from('invoices')
          .select('*')
          .order('date', { ascending: false });

        if (invoiceError) {
          Logger.error('Error fetching invoices', invoiceError);
          return [];
        }

        Logger.info('Fetched invoices', { count: invoiceData?.length });

        const { data: itemsData, error: itemsError } = await supabase
          .from('invoice_items')
          .select(`
            *,
            inventory:inventory(item_name)
          `)
          .in('invoice_id', invoiceData.map(inv => inv.id));

        if (itemsError) {
          Logger.error('Error fetching invoice items', itemsError);
          return [];
        }

        Logger.info('Fetched invoice items', { count: itemsData?.length });

        const invoicesWithItems = invoiceData.map(invoice => {
          const invoiceItems = itemsData.filter(item => item.invoice_id === invoice.id);
          
          const calculatedTotal = invoiceItems.reduce((sum, item) => {
            const amount = item.measurement_type === 'weight'
              ? (item.weight_kilos || 0)
              : (item.quantity || 1);
            const itemTotal = (item.unit_price || 0) * amount;
            return sum + itemTotal;
          }, 0);

          const items = invoiceItems.map(item => ({
            sku: item.sku,
            quantity: item.quantity,
            weight_kilos: item.weight_kilos,
            item_name: item.inventory?.item_name || '',
            batch_number: item.batch_number,
            warehouse: item.warehouse,
            measurement_type: item.measurement_type,
            unit_price: item.unit_price || 0
          }));

          return {
            id: invoice.id,
            invoice_number: invoice.invoice_number,
            date: invoice.date,
            company: invoice.company,
            notes: invoice.notes,
            status: invoice.status,
            total_amount: calculatedTotal,
            created_at: invoice.created_at,
            updated_at: invoice.updated_at,
            user_id: invoice.user_id,
            items
          };
        });

        return invoicesWithItems;
      } catch (error) {
        Logger.error('Error in invoice fetch', error);
        return [];
      }
    }
  });

  const handleInvoiceDeleted = async (invoiceId?: number | null) => {
    if (!invoiceId) return;

    try {
      const { error } = await supabase
        .from('invoices')
        .delete()
        .eq('id', invoiceId);

      if (error) throw error;

      await queryClient.invalidateQueries({ queryKey: ['invoices'] });
      await refetch();
    } catch (error) {
      const err = error as Error;
      console.error('Error deleting invoice:', err);
    }
  };

  // Redirect if user doesn't have permission to view invoices
  if (!canViewInvoices) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-4rem)]">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Unauthorized Access</h1>
          <p className="text-gray-600">You do not have permission to access this page - please contact support</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* Desktop View */}
      {!isMobile && (
        <div className="hidden lg:block min-h-screen">
          <div className="mb-8 flex items-center justify-between">
            <h1 className="text-2xl font-bold flex items-center gap-2">
              Delivery Notes
              <div className="bg-emerald-100 text-emerald-800 px-2 py-0.5 rounded-full text-sm font-medium">
                {(invoices || []).length} notes
              </div>
            </h1>
            <UnimportedBadge />
          </div>

          {/* Enhanced Table */}
          <EnhancedInvoicesTable 
            invoices={invoices || []} 
            onDelete={handleRefetch}
          />
        </div>
      )}

      {/* Mobile View */}
      {isMobile && (
        <div className="lg:hidden">
          <MobileHeader />
          <div className="fixed top-14 left-0 right-0 bottom-0 bg-white z-10 overflow-y-auto scrollbar-none">
            <div className="border-b">
              <div className="px-4 py-3">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <h1 className="text-xl font-bold">Delivery Notes</h1>
                    <div className="bg-emerald-100 text-emerald-800 px-2 py-0.5 rounded-full text-sm font-medium">
                      {(invoices || []).length} notes
                    </div>
                  </div>
                </div>
              </div>
              <UnimportedBadge />
            </div>
            {canViewInvoices && (
              <div className="px-4 pb-3 mt-3 grid grid-cols-2 gap-2">
                <AddInvoiceDialog
                  onInvoiceAdded={async () => {
                    await queryClient.invalidateQueries({ queryKey: ['invoices'] });
                    await refetch();
                  }}
                />
                <DeliveryNotesModal />
              </div>
            )}

            {(invoices || []).map((invoice) => (
              <div key={invoice.id} className="mx-4 my-3 bg-emerald-50 rounded-lg shadow border-b border-gray-200 p-3">
                <div className="flex justify-between items-start gap-2 mb-3">
                  <div className="flex-1">
                    <div className="flex items-center justify-between">
                      <div className="cursor-pointer" onClick={() => setSelectedInvoice(invoice)}>
                        <h3 className="font-medium text-gray-900 hover:text-blue-600">#{invoice.invoice_number}</h3>
                      </div>
                      {canManageInvoices && (
                        <div className="flex items-center gap-1.5">
                          <EditInvoiceDialog
                            invoice={invoice}
                            onInvoiceUpdated={async () => {
                              await queryClient.invalidateQueries({ queryKey: ['invoices'] });
                              await refetch();
                            }}
                          />
                          <AlertDialog>
                            <AlertDialogTrigger asChild>
                              <button
                                className="text-gray-400 hover:text-red-600 p-1 rounded-full hover:bg-red-50"
                              >
                                <Trash2 className="h-4 w-4" />
                              </button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>Delete Delivery Note</AlertDialogTitle>
                                <AlertDialogDescription>
                                  Are you sure you want to delete this invoice? This action cannot be undone.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={() => invoice.id && handleInvoiceDeleted(invoice.id)}
                                  className="bg-red-600 hover:bg-red-700 focus:ring-red-600"
                                >
                                  Delete
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        </div>
                      )}
                    </div>
                    <div className="cursor-pointer" onClick={() => setSelectedInvoice(invoice)}>
                      <p className="text-sm text-gray-500 hover:text-blue-600 mt-0.5">{invoice.company}</p>
                    </div>
                  </div>
                </div>

                <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
                  <div className="bg-gray-50 p-1.5 rounded">
                    <span className="text-gray-500">Date: </span>
                    <span className="font-medium text-gray-900">{invoice.date}</span>
                  </div>
                  <div className="bg-gray-50 p-1.5 rounded">
                    <span className="text-gray-500">Status: </span>
                    <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
                      invoice.status === 'IMS'
                        ? 'bg-blue-100 text-blue-700'
                        : 'bg-amber-100 text-amber-700'
                    }`}>
                      {invoice.status}
                    </span>
                  </div>
                  <div className="bg-gray-50 p-1.5 rounded">
                    <span className="text-gray-500">Items: </span>
                    <span className="font-medium text-gray-900">{invoice.items?.length || 0}</span>
                  </div>
                  <div className="bg-gray-50 p-1.5 rounded">
                    <span className="text-gray-500">Total: </span>
                    <span className="font-medium text-gray-900">CHF {invoice.total_amount?.toFixed(2) || '0.00'}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* View Invoice Dialog */}
      {selectedInvoice && (
        <ViewInvoiceDialog
          open={!!selectedInvoice}
          onClose={() => setSelectedInvoice(null)}
          invoice={selectedInvoice}
        />
      )}
    </div>
  );
};

export default Invoices;
