import React, { useState, useEffect } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { ViewBatchDialog } from "./ViewBatchDialog";
import { EditBatchDialog } from "./EditBatchDialog";
import { AddBatchDialog } from "./AddBatchDialog";
import { AddShrinkageDialog } from "./AddShrinkageDialog";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import Logger from "@/utils/logger";
import { Trash2, Search, ChevronDown, ChevronUp, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Columns3 } from "lucide-react";
import { useAuth } from '@/contexts/AuthContext';
import type { Batch } from "@/types/batch";
import { calculateAvailable } from "@/utils/batchUtils";
import { cn } from "@/lib/utils";

// Constants
const PAGE_SIZE_KEY = "batches-table-page-size";
const DEFAULT_PAGE_SIZE = 20;
const PAGE_SIZE_OPTIONS = [20, 50, 100, 200];

interface BatchesTableProps {
  batches: Batch[];
  onBatchUpdated: () => void;
  filterText?: string;
  expiredOnly?: boolean;
  hideEmpty?: boolean;
}

interface InvoiceItem {
  invoice: {
    invoice_number: string;
    company: string;
    date: string;
  };
}

export function BatchesTable({
  batches,
  onBatchUpdated,
  filterText = "",
  expiredOnly = false,
  hideEmpty = true
}: BatchesTableProps) {
  const { toast } = useToast();
  const { canEdit, canAddBatches } = useAuth();
  const [selectedBatch, setSelectedBatch] = useState<Batch | null>(null);
  
  // Table state
  const [sorting, setSorting] = useState<SortingState>([
    { id: "date_received", desc: true }
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [pageSize, setPageSize] = useState(() => {
    try {
      const saved = localStorage.getItem(PAGE_SIZE_KEY);
      return saved ? parseInt(saved, 10) : DEFAULT_PAGE_SIZE;
    } catch {
      return DEFAULT_PAGE_SIZE;
    }
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize
  });

  // Component-level cache for availability calculations
  const availabilityCache = React.useMemo(() => new Map<string, number>(), []);

  // Clear cache when batches change
  React.useEffect(() => {
    availabilityCache.clear();
  }, [batches, availabilityCache]);
  
  // Wrapper for availability calculation with caching
  const getAvailability = React.useCallback((batch: Batch) => {
    if (availabilityCache.has(batch.batch_number)) {
      return availabilityCache.get(batch.batch_number)!;
    }
    const amount = calculateAvailable(batch, batches);
    availabilityCache.set(batch.batch_number, amount);
    return amount;
  }, [batches, availabilityCache]);

  // Persist page size changes
  useEffect(() => {
    localStorage.setItem(PAGE_SIZE_KEY, pageSize.toString());
  }, [pageSize]);

  // Reset page index when filters change
  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
  }, [globalFilter, columnFilters]);

  const handleDelete = async (batch: Batch) => {
    try {
      Logger.info('Checking batch usage', { batch_number: batch.batch_number });

      const { data: items, error: checkError } = await supabase
        .from('invoice_items')
        .select(`
          invoice:invoices!inner (
            invoice_number,
            company,
            date
          )
        `)
        .eq('batch_number', batch.batch_number);

      if (checkError) {
        Logger.error('Error checking batch usage', checkError);
        throw checkError;
      }

      if (items && items.length > 0) {
        const invoiceList = (items as unknown as InvoiceItem[])
          .map(item => {
            const date = new Date(item.invoice.date).toLocaleDateString();
            return `${item.invoice.invoice_number} (${item.invoice.company}, ${date})`;
          })
          .join('\n');

        toast({
          variant: "destructive",
          title: "Cannot delete batch",
          description: `This batch is used in the following invoices:\n${invoiceList}`
        });
        return;
      }

      Logger.info('Deleting batch', { batch_number: batch.batch_number });

      const { error: batchError } = await supabase
        .from('batches')
        .delete()
        .eq('batch_number', batch.batch_number);

      if (batchError) {
        Logger.error('Error deleting batch', batchError);
        throw batchError;
      }

      Logger.info('Batch deleted successfully', { batch_number: batch.batch_number });
      toast({
        title: "Success",
        description: "Batch deleted successfully"
      });

      onBatchUpdated();
    } catch (error) {
      const err = error as Error;
      Logger.error('Error in handleDelete', error);
      toast({
        variant: "destructive",
        title: "Error deleting batch",
        description: err.message
      });
    }
  };

  // Column definitions
  const columns: ColumnDef<Batch>[] = [
    {
      accessorKey: "item_name",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Item Name</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => (
        <TooltipProvider delayDuration={300}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div 
                className={cn(
                  "truncate max-w-[250px] cursor-pointer",
                  row.original.batch_number.startsWith('SHR-') 
                    ? "text-red-600 hover:text-red-700" 
                    : "hover:text-blue-600"
                )}
                onClick={() => setSelectedBatch(row.original)}
              >
                {row.getValue("item_name") || ''}
              </div>
            </TooltipTrigger>
            <TooltipContent 
              side="right"
              className="bg-white p-3 rounded-lg shadow-lg border border-gray-200"
            >
              <div className="text-sm text-gray-900 whitespace-pre-line leading-relaxed">
                {row.getValue("item_name")}
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    },
    {
      accessorKey: "batch_number",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Batch #</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => (
        <div 
          className={cn(
            "whitespace-nowrap",
            row.original.batch_number.startsWith('SHR-') && "text-red-600 font-medium"
          )}
        >
          {row.original.batch_number}
        </div>
      )
    },
    {
      accessorKey: "sku",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>SKU</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => (
        <div className="text-gray-900 whitespace-nowrap">{row.original.sku}</div>
      )
    },
    {
      id: "original_quantity",
      header: "Original Qty",
      cell: ({ row }) => {
        const batch = row.original;
        return (
          <div 
            className={cn(
              "whitespace-nowrap",
              batch.batch_number.startsWith('SHR-') && "text-red-600 font-medium"
            )}
          >
            {batch.inventory?.is_weight_based
              ? `${Number(batch.weight_kilos || 0).toFixed(2)} kg`
              : batch.quantity}
          </div>
        );
      }
    },
    {
      id: "available",
      header: "Available",
      cell: ({ row }) => {
        const batch = row.original;
        const available = getAvailability(batch);
        return (
          <div className="text-gray-900 whitespace-nowrap">
            {batch.inventory?.is_weight_based
              ? `${available.toFixed(2)} kg`
              : available}
          </div>
        );
      }
    },
    {
      accessorKey: "expiry_date",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Expiry Date</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => (
        <div className="text-gray-900 whitespace-nowrap">
          {new Date(row.original.expiry_date).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
          })}
        </div>
      )
    },
    {
      accessorKey: "date_received",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Date Received</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => (
        <div className="text-gray-900 whitespace-nowrap">
          {new Date(row.original.date_received).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
          })}
        </div>
      )
    },
    {
      id: "cost",
      header: "Cost",
      cell: ({ row }) => (
        <div className="text-gray-900 whitespace-nowrap">
          {row.original.purchase_cost != null 
            ? `CHF ${Number(row.original.purchase_cost).toFixed(2)}` 
            : '-'}
        </div>
      )
    },
    {
      accessorKey: "warehouse",
      header: "Warehouse",
      cell: ({ row }) => (
        <div className="text-gray-900 whitespace-nowrap">{row.original.warehouse}</div>
      )
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          {canEdit && (
            <EditBatchDialog
              batch={row.original}
              onBatchUpdated={onBatchUpdated}
            />
          )}
          {(canEdit && canAddBatches) && (
            <button
              onClick={() => {
                if (window.confirm('Are you sure you want to delete this batch?')) {
                  handleDelete(row.original);
                }
              }}
              className="text-red-600 hover:text-red-800 p-1 rounded-md hover:bg-red-50"
            >
              <Trash2 className="h-4 w-4" />
            </button>
          )}
        </div>
      )
    }
  ];

  // Apply filters based on props
  const filteredBatches = React.useMemo(() => {
    return batches.filter(batch => {
      const matchesText = (
        batch.batch_number.toLowerCase().includes((globalFilter || filterText).toLowerCase()) ||
        (batch.item_name || '').toLowerCase().includes((globalFilter || filterText).toLowerCase()) ||
        batch.sku.toLowerCase().includes((globalFilter || filterText).toLowerCase())
      );

      const available = getAvailability(batch);
      const isShrinkage = batch.batch_number.startsWith('SHR-');

      if (expiredOnly) {
        const isExpired = new Date(batch.expiry_date) < new Date();
        return matchesText && isExpired && available > 0;
      }

      // Always show SHR transactions regardless of hideEmpty setting
      if (isShrinkage) {
        return matchesText;
      }

      // Only check available amount for non-SHR batches
      if (hideEmpty && available === 0) {
        return false;
      }

      return matchesText;
    });
  }, [batches, globalFilter, filterText, expiredOnly, hideEmpty, getAvailability]);

  // Table configuration
  const table = useReactTable({
    data: filteredBatches,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: (value) => {
      setColumnVisibility(value);
    },
    onPaginationChange: setPagination,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      globalFilter,
      pagination
    }
  });

  return (
    <div className="space-y-4">
      {/* Search, Filters and Actions Row */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="relative w-[300px]">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search all columns..."
              value={globalFilter ?? ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
              className="pl-8 h-9"
            />
          </div>
          
          {/* Column Visibility */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="sm" className="h-9">
                <Columns3 className="mr-2 h-4 w-4" />
                Columns
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="bg-white border rounded-md shadow-md w-[180px] p-0"
            >
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  // Get header text based on column ID
                  const headerLabels: { [key: string]: string } = {
                    'item_name': 'Item Name',
                    'batch_number': 'Batch #',
                    'sku': 'SKU',
                    'original_quantity': 'Original Qty',
                    'available': 'Available',
                    'expiry_date': 'Expiry Date',
                    'date_received': 'Date Received',
                    'cost': 'Cost',
                    'warehouse': 'Warehouse',
                    'actions': 'Actions'
                  };
                  
                  const label = headerLabels[column.id] || column.id;

                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="relative flex select-none items-center py-2 pl-8 pr-4 text-sm outline-none hover:bg-gray-100 cursor-pointer data-[state=checked]:bg-gray-50"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) => column.toggleVisibility(!!value)}
                    >
                      {label}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>

          {/* Filter Checkboxes */}
          <div className="flex items-center gap-4">
            <label className="flex items-center gap-2 text-sm text-gray-600">
              <input
                type="checkbox"
                checked={expiredOnly}
                onChange={(e) => {
                  const newValue = e.target.checked;
                  const event = new CustomEvent('expiredOnlyChange', { detail: newValue });
                  window.dispatchEvent(event);
                }}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              Exp only
            </label>
            <label className="flex items-center gap-2 text-sm text-gray-600">
              <input
                type="checkbox"
                checked={hideEmpty}
                onChange={(e) => {
                  const newValue = e.target.checked;
                  const event = new CustomEvent('hideEmptyChange', { detail: newValue });
                  window.dispatchEvent(event);
                }}
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              Hide inactive
            </label>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex gap-2">
          <div className="flex gap-2 h-9">
            <AddBatchDialog onBatchAdded={onBatchUpdated} />
            <AddShrinkageDialog onBatchAdded={onBatchUpdated} />
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="bg-gray-50">
                {headerGroup.headers.map((header) => (
                  <TableHead 
                    key={header.id}
                    className="text-gray-900"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow 
                  key={row.id}
                  className={cn(
                    "group transition-colors",
                    row.original.batch_number.startsWith('SHR-') && 
                    "bg-red-50/50 hover:bg-red-50/70"
                  )}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {/* Pagination Controls */}
      <div className="flex items-center justify-between space-x-2 py-4">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">Rows per page</p>
          <Select
            value={`${pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value));
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={pageSize} />
            </SelectTrigger>
            <SelectContent side="top" className="bg-white border rounded-md shadow-md p-1">
              {PAGE_SIZE_OPTIONS.map((size) => (
                <SelectItem key={size} value={`${size}`} className="cursor-pointer">
                  {size}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center space-x-2">
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to first page</span>
              <ChevronsLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRight className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to last page</span>
              <ChevronsRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>

      {/* View Batch Dialog */}
      {selectedBatch && (
        <ViewBatchDialog
          batch={selectedBatch}
          open={!!selectedBatch}
          onOpenChange={(open) => !open && setSelectedBatch(null)}
          onBatchUpdated={onBatchUpdated}
        />
      )}
    </div>
  );
}
