import { Database } from '@/types/supabase';

export interface Notification {
  batchNumber: string;
  sku: string;
  itemName: string;
  expiryDate: Date;
  warehouse: string;
  quantity?: number;
  weightKilos?: number;
  purchaseCost: number;
  totalValue: number;
  createdAt: Date;
  daysUntilExpiry: number;
}

export interface NotificationSection {
  items: Notification[];
  expanded: boolean;
  readStatus: Record<string, boolean>;
}

export interface NotificationState {
  expiring: NotificationSection;
  newStock: NotificationSection;
}

// Default state
export const DEFAULT_NOTIFICATION_STATE: NotificationState = {
  expiring: {
    items: [],
    expanded: false,
    readStatus: {}
  },
  newStock: {
    items: [],
    expanded: false,
    readStatus: {}
  }
};

// Batch types
export interface Inventory {
  item_name: string;
  is_weight_based: boolean;
}

export interface InvoiceItem {
  quantity?: number;
  weight_kilos?: number;
}

export interface Batch {
  batch_number: string;
  base_id: string;
  sku: string;
  expiry_date: string;
  warehouse: string;
  purchase_cost: number;
  created_at: string;
  date_received: string;
}

export interface BatchWithInventory extends Batch {
  inventory: Inventory;
  invoice_items: InvoiceItem[];
}
