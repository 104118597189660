import { z } from "zod";

export interface InvoiceItem {
    id?: number;
    invoice_id?: number;
    sku: string;
    item_name?: string;
    quantity?: number;
    weight_kilos?: number;
    batch_number: string;
    warehouse: string;
    measurement_type?: string;
    unit_price?: number;
    created_at?: string;
    is_weight_based?: boolean;
    selected_batches?: Array<{
        batch_number: string;
        quantity?: number;
        weight_kilos?: number;
        warehouse: string;
        available_quantity?: number;
        available_weight?: number;
    }>;
}

export interface Invoice {
    id?: number;
    invoice_number: string;
    date: string;
    company: string;
    user_id: string;
    notes?: string;
    total_amount?: number;
    created_at?: string;
    updated_at?: string;
    items?: InvoiceItem[];
    status: string; // Added status field
}

// Zod schema for form validation
const selectedBatchSchema = z.object({
    batch_number: z.string(),
    quantity: z.number().optional(),
    weight_kilos: z.number().optional(),
    warehouse: z.string(),
    available_quantity: z.number().optional(),
    available_weight: z.number().optional()
}).refine(
    data => (data.quantity !== undefined) !== (data.weight_kilos !== undefined),
    "Either quantity or weight_kilos must be specified, but not both"
);

export const invoiceItemSchema = z.object({
    sku: z.string().min(1, "SKU is required"),
    item_name: z.string().optional(),
    quantity: z.number().optional(),
    weight_kilos: z.number().optional(),
    batch_number: z.string().min(1, "Batch number is required"),
    warehouse: z.string().min(1, "Warehouse is required"),
    measurement_type: z.string().optional(),
    unit_price: z.number().min(0).optional(),
    is_weight_based: z.boolean().optional(),
    selected_batches: z.array(selectedBatchSchema).optional()
}).refine(
    data => {
        if (data.is_weight_based) {
            return data.weight_kilos !== undefined && data.weight_kilos > 0;
        } else {
            return data.quantity !== undefined && data.quantity > 0;
        }
    },
    {
        message: "Either quantity or weight must be specified based on is_weight_based flag",
        path: ["quantity"]
    }
);

export const invoiceFormSchema = z.object({
    invoice_number: z.string().min(1, "Invoice number is required"),
    date: z.string().min(1, "Date is required"),
    company: z.string().min(1, "Company is required"),
    notes: z.string().optional(),
    status: z.string(),  // Required for all invoices
    items: z.array(invoiceItemSchema)
});

// Base schema for common fields between create and edit
const baseInvoiceSchema = z.object({
    date: z.string().min(1, "Date is required"),
    company: z.string().min(1, "Company is required"),
    notes: z.string().optional(),
    items: z.array(invoiceItemSchema)
});

// Schema for editing invoices - removes invoice number validation
export const editInvoiceFormSchema = baseInvoiceSchema.extend({
    invoice_number: z.string(),
    id: z.number(),
    status: z.string() // Added status field
});

export type InvoiceFormValues = z.infer<typeof invoiceFormSchema>;
export type EditInvoiceFormValues = z.infer<typeof editInvoiceFormSchema>;
