export enum ImportStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  ERROR = 'error'
}

export interface BatchMatch {
  matchedBatch: string;
}

export interface ValidatedPosition {
  name: string;
  number: string;
  catalog_type: string;
  price: string;
  skipped?: boolean;
  batchMatch?: BatchMatch;
}

export interface DeliveryNote {
  id: number;
  number: string;
  date: string;
  total: string;
  status: string;
  contact?: {
    name: string;
  };
  positions: Array<ValidatedPosition>;
}

export interface ValidatedDeliveryNote extends DeliveryNote {
  exists: boolean;
  importStatus: ImportStatus;
  validatedPositions: ValidatedPosition[];
}

export interface UnimportedDeliveryNote {
  id: string;
  date: Date;
  company: string;
  amount: number;
  noteNumber: string;
  isImported: boolean;
}

export type DeliveryNoteStatus = 'imported' | 'unimported';

export interface DeliveryNoteFilters {
  startDate?: Date;
  endDate?: Date;
  status?: DeliveryNoteStatus;
  company?: string;
}
