import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { formSchema, VATRate } from "@/types/inventory";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useAuth } from '@/contexts/AuthContext';
import { isValidSku, sanitizeSku } from "@/utils/validation";
import { getAvailableQuantity } from "@/utils/batchUtils";

interface CreateProductFromDNDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onProductCreated: () => void;
  initialSku?: string;
  initialName?: string;
  initialVat?: number;
}

interface BatchInfo {
  batch_number: string;
  quantity: number | null;
  weight_kilos: number | null;
  date_received: string;
  available: number;
  is_weight_based?: boolean;
  base_id?: string;
  shrinkage_reason?: string;
  invoice_items?: Array<{
    quantity: number;
    weight_kilos: number;
    invoice: {
      status: string;
    };
  }>;
}

export function CreateProductFromDNDialog({
  open, 
  onOpenChange, 
  onProductCreated,
  initialSku = "",
  initialName = "",
  initialVat = 8.1
}: CreateProductFromDNDialogProps) {
  const { toast } = useToast();
  const [currentQuantity, setCurrentQuantity] = useState<number>(0);
  const [recentBatches, setRecentBatches] = useState<BatchInfo[]>([]);
  const [originalSku, setOriginalSku] = useState(initialSku);
  const [sanitizedSku, setSanitizedSku] = useState(() => sanitizeSku(initialSku));
  const { canEdit } = useAuth();

  if (!canEdit) return null;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      sku: initialSku,
      item_name: initialName,
      description: "",
      vat_rate: initialVat as VATRate,
      is_weight_based: false
    },
  });

  // Handle SKU sanitization and form reset when dialog opens
  useEffect(() => {
    if (open) {
      // Update SKU states
      setOriginalSku(initialSku);
      const sanitized = sanitizeSku(initialSku);
      setSanitizedSku(sanitized);

      // Reset form with sanitized SKU
      form.reset({
        sku: sanitized,
        item_name: initialName,
        description: "",
        vat_rate: initialVat as VATRate,
        is_weight_based: false
      });

      // Show transformation notification if SKU was modified
      if (sanitized !== initialSku) {
        toast({
          title: "SKU Format Modified",
          description: "The SKU has been automatically adjusted to match the allowed format.",
        });
      }
    }
  }, [open, initialSku, initialName, initialVat, form, toast]);

  const fetchBatchInfo = async (sku: string) => {
    if (!sku) return;

      const { data: batches, error: batchError } = await supabase
        .from('batches')
        .select(`
          *,
          invoice_items:invoice_items(
            quantity,
            weight_kilos,
            invoice:invoices(status)
          ),
          inventory:inventory(is_weight_based),
          base_id,
          shrinkage_reason
        `)
      .eq('sku', sku)
      .order('date_received', { ascending: false });

    if (!batchError && batches) {
      const batchesWithCalculations = batches.map(batch => ({
        ...batch,
        available: getAvailableQuantity(batch, batches),
        is_weight_based: batch.inventory?.is_weight_based
      }));

      const total = batchesWithCalculations.reduce((sum, batch) => sum + batch.available, 0);
      setCurrentQuantity(total);
      setRecentBatches(batchesWithCalculations.slice(0, 5));
    }
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const { error } = await supabase
        .from('inventory')
        .upsert([values], { 
          onConflict: 'sku',
          ignoreDuplicates: false 
        });

      if (error) throw error;

      toast({
        title: "Product created successfully",
        description: `${values.item_name} has been added to inventory.`
      });

      form.reset();
      onOpenChange(false);
      onProductCreated();
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error creating product",
        description: error.message
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Create New Product</DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <FormField
                  control={form.control}
                  name="sku"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>SKU</FormLabel>
                      <FormControl>
                       <div className="space-y-2">
                         <Input
                           placeholder="Enter SKU"
                           {...field}
                           disabled
                           className="bg-gray-100"
                         />
                         {sanitizedSku !== originalSku && (
                           <div className="text-sm p-2 bg-blue-50 border border-blue-200 rounded">
                             <p className="text-blue-700">
                               <span className="font-medium">Original SKU: </span>
                               <span className="font-mono">{originalSku}</span>
                             </p>
                           </div>
                         )}
                       </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="item_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter product name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter description" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="vat_rate"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>VAT Rate</FormLabel>
                      <Select
                        value={field.value.toString()}
                        onValueChange={(value) => {
                          const rate = parseFloat(value) as VATRate;
                          if (rate === 8.1 || rate === 2.6) {
                            field.onChange(rate);
                          }
                        }}
                      >
                        <SelectTrigger className="bg-white text-gray-900 border-gray-200">
                          <SelectValue placeholder="Select VAT rate" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="8.1">8.1%</SelectItem>
                          <SelectItem value="2.6">2.6%</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="is_weight_based"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Tracking Type</FormLabel>
                      <FormControl>
                        <div className="flex items-center gap-2">
                          <span className={`text-sm ${!field.value ? 'font-bold text-blue-600' : ''}`}>QTY</span>
                          <button
                            type="button"
                            role="switch"
                            aria-checked={field.value}
                            className={`
                              relative inline-flex h-6 w-11 items-center rounded-full
                              ${field.value ? 'bg-red-600' : 'bg-blue-600'}
                              transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                            `}
                            onClick={() => field.onChange(!field.value)}
                          >
                            <span
                              className={`
                                ${field.value ? 'translate-x-6' : 'translate-x-1'}
                                inline-block h-4 w-4 transform rounded-full bg-white transition-transform
                              `}
                            />
                          </button>
                          <span className={`text-sm ${field.value ? 'font-bold text-red-600' : ''}`}>Weight</span>
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />
                <Button type="submit" className="w-full">
                  Create Product
                </Button>
              </form>
            </Form>
          </div>
          
          <div className="space-y-4">
            <div className="p-4 bg-gray-50 rounded-lg">
              <h3 className="font-semibold mb-2">Current Stock</h3>
              <p className="text-2xl font-bold">{currentQuantity}</p>
            </div>

            {recentBatches.length > 0 && (
              <div>
                <h3 className="font-semibold mb-2">Recent Batches</h3>
                <div className="space-y-2">
                  {recentBatches.map((batch) => (
                    <div 
                      key={batch.batch_number}
                      className="p-3 bg-gray-50 rounded-lg"
                    >
                      <div className="flex justify-between items-center">
                        <span className="font-medium">{batch.batch_number}</span>
                        <span className="text-sm text-gray-500">
                          {new Date(batch.date_received).toLocaleDateString()}
                        </span>
                      </div>
                      <div className="text-sm text-gray-600 mt-1">
                        Available: {batch.available} / {batch.is_weight_based ? batch.weight_kilos : batch.quantity}
                        {batch.shrinkage_reason && (
                          <span className="ml-2 text-yellow-600 text-xs">
                            ({batch.shrinkage_reason})
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
