import { DeliveryNotePosition, ValidatedPosition, BatchConfidence } from '../types/delivery-note';
import { supabase } from '../lib/supabase';
import Logger from './logger';

export const isValidSku = (sku: string) => {
    return /^[A-Za-z0-9-]+$/.test(sku);
};

export const sanitizeSku = (sku: string) => {
    // Replace any character that isn't alphanumeric or hyphen with a hyphen
    const sanitized = sku.replace(/[^A-Za-z0-9-]/g, '-');
    // Remove multiple consecutive hyphens
    const normalized = sanitized.replace(/-+/g, '-');
    // Remove leading/trailing hyphens
    return normalized.replace(/^-+|-+$/g, '');
};

export const sanitizeDeliveryNote = (number: string) => {
    return sanitizeSku(number);
};

export interface TransformationResult {
    original: string;
    sanitized: string;
    wasTransformed: boolean;
}

export const transformDeliveryNote = (number: string): TransformationResult => {
    const sanitized = sanitizeDeliveryNote(number);
    return {
        original: number,
        sanitized,
        wasTransformed: number !== sanitized
    };
};

export function extractLottoNumber(description: string): { 
    original: string;
    prefix: string | null;
    number: string;
} {
    // Log the attempt
    Logger.info('Extracting lotto number', {
        description
    });

    // Split by line breaks first, then clean each line
    const lines = description.split(/[\n\r<br>]+/);
    // Take first line that contains "Lotto:"
    const lottoLine = lines.find(line => line.toLowerCase().includes('lotto:')) || '';
    // Clean the lotto line
    const cleanDescription = lottoLine
        .replace(/<[^>]*>/g, '')   // Remove HTML tags
        .replace(/\s+/g, ' ')      // Normalize whitespace
        .trim();

    // Extract lotto number from cleaned description field
    // Extract just the first token after "Lotto:"
    const lottoMatch = cleanDescription.match(/Lotto:?\s*([A-Za-z0-9-]+)(?:\s|$)/i);
    if (!lottoMatch) return { original: '', prefix: null, number: '' };

    const original = lottoMatch[1];
    const parts = original.split('-');
    
    if (parts.length > 1) {
        return {
            original,
            prefix: parts[0],
            number: parts[1]
        };
    }

    return {
        original,
        prefix: null,
        number: original
    };
}

export async function validatePosition(
    position: DeliveryNotePosition,
    previousState?: Partial<ValidatedPosition>
): Promise<ValidatedPosition> {
    try {
        // Skip validation if no SKU
        if (!position.number) {
            return {
                position,
                skuExists: false,
                batchMatch: {
                    lottoNumber: '',
                    normalizedLotto: '',
                    matchedBatch: null,
                    confidence: 'none'
                },
                validAmount: false,
                measurementType: 'quantity',
                errors: ['Missing SKU'],
                warnings: [],
                skipped: previousState?.skipped || false
            };
        }

        // Validate and sanitize SKU
        const originalSku = position.number;
        const sanitizedSku = originalSku ? sanitizeSku(originalSku) : '';
        
        // Track if SKU was modified during sanitization
        const skuWasModified = originalSku !== sanitizedSku;
        
        // Check SKU exists in inventory
        const { data: skuData, error: skuError } = await supabase
            .from('inventory')
            .select('is_weight_based, item_name')
            .eq('sku', sanitizedSku)
            .maybeSingle();

        if (skuError) throw skuError;

        // Check SKU exists and get measurement type
        const skuExists = !!skuData;
        const measurementType = skuData?.is_weight_based ? 'weight' : 'quantity';

        // Update position with sanitized SKU
        const updatedPosition = {
            ...position,
            number: sanitizedSku,
            originalSku: originalSku // Keep track of original SKU
        };

        // Extract lotto number from description
        const lotto = extractLottoNumber(position.description);

        // Set initial batch match with confidence based on lotto number
        const batchMatch = previousState?.batchMatch || {
            lottoNumber: lotto.original,
            normalizedLotto: lotto.number,
            matchedBatch: null,
            // Set confidence based on lotto number presence
            confidence: lotto.number ? 'medium' as BatchConfidence : 'none' as BatchConfidence
        };

        // Log batch match assessment
        Logger.info('Batch match assessment', {
            sku: sanitizedSku,
            lottoNumber: lotto.original,
            normalizedLotto: lotto.number,
            confidence: batchMatch.confidence
        });

        // Prepare validation result
        const result: ValidatedPosition = {
            position: updatedPosition,
            skuExists,
            skuDetails: skuData || undefined,
            batchMatch,
            validAmount: position.amount > 0,
            measurementType,
            errors: [],
            warnings: [],
            skipped: previousState?.skipped || false,
            skuTransformation: skuWasModified ? {
                original: originalSku,
                sanitized: sanitizedSku,
                message: `SKU was modified to match allowed format`
            } : undefined
        };

        // Add warning if SKU was modified
        if (skuWasModified) {
            result.warnings.push(
                `SKU format was automatically adjusted (${originalSku} → ${sanitizedSku})`
            );
            Logger.info('SKU sanitization applied', {
                original: originalSku,
                sanitized: sanitizedSku,
                position: position.number
            });
        }

        // Validate SKU exists
        if (!skuExists) {
            result.errors.push(`SKU ${position.number} not found in inventory`);
            return result;
        }

        // Validate amount
        if (!result.validAmount) {
            result.errors.push('Invalid amount');
        }

        // Check if batch is matched
        if (result.batchMatch.matchedBatch) {
            Logger.info('Checking batch match', {
                lotto: lotto.number,
                batch: result.batchMatch.matchedBatch,
                confidence: result.batchMatch.confidence
            });
        }

        return result;

    } catch (error) {
        Logger.error('Error validating position:', error);
        return {
            position,
            skuExists: false,
            batchMatch: {
                lottoNumber: '',
                normalizedLotto: '',
                matchedBatch: null,
                confidence: 'none'
            },
            validAmount: false,
            measurementType: 'quantity',
            errors: ['Validation error'],
            warnings: [],
            skipped: previousState?.skipped || false
        };
    }
}
