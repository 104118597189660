import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Plus, Boxes, Scale, Pencil, CheckCircle2 } from "lucide-react";
import { formSchema, VATRate } from "@/types/inventory";
import type { InventoryItem } from "@/types/inventory";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import { ToastProps } from "@/components/ui/toast";
import { 
  Select,
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from "@/components/ui/select";
import { useAuth } from '@/contexts/AuthContext';
import { isValidSku, sanitizeSku } from "@/utils/validation";
import { cn } from "@/lib/utils";

interface AddProductDialogProps {
  onProductAdded: () => void;
  variant?: 'default' | 'icon';
  mode?: 'create' | 'edit';
  initialItem?: InventoryItem;
  trigger?: React.ReactNode;
}

interface BatchInfo {
  batch_number: string;
  quantity: number | null;
  weight_kilos: number | null;
  date_received: string;
  available: number;
  is_weight_based?: boolean;
  invoice_items?: Array<{
    quantity: number;
    weight_kilos: number;
    invoice: {
      status: string;
    };
  }>;
}

export function AddProductDialog({ 
  onProductAdded, 
  variant = 'default',
  mode = 'create',
  initialItem,
  trigger 
}: AddProductDialogProps) {
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();
  const { canEdit } = useAuth();
  const [currentQuantity, setCurrentQuantity] = useState<number>(0);
  const [recentBatches, setRecentBatches] = useState<BatchInfo[]>([]);

  if (!canEdit) return null;

  const defaultValues = {
    sku: initialItem?.sku || "",
    item_name: initialItem?.item_name || "",
    description: initialItem?.description || "",
    vat_rate: (initialItem?.vat_rate || 2.6) as VATRate,
    is_weight_based: initialItem?.is_weight_based || false
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues
  });

  const fetchBatchInfo = async (sku: string) => {
    if (!sku) return;

    const { data: batches, error: batchError } = await supabase
      .from('batches')
      .select(`
        *,
        invoice_items:invoice_items(
          quantity,
          weight_kilos,
          invoice:invoices(status)
        ),
        inventory:inventory(is_weight_based)
      `)
      .eq('sku', sku)
      .order('date_received', { ascending: false });

    if (!batchError && batches) {
      const batchesWithCalculations = batches.map(batch => {
        const isWeightBased = batch.inventory?.is_weight_based;
        
        const original = isWeightBased ? 
          (batch.weight_kilos || 0) : 
          (batch.quantity || 0);
        
        const used = batch.invoice_items
          ?.filter(item => item.invoice?.status === 'final')
          .reduce((sum, item) => {
            const usedAmount = isWeightBased ? 
              (item.weight_kilos || 0) : 
              (item.quantity || 0);
            return sum + usedAmount;
          }, 0) || 0;
          
        const available = Math.max(0, original - used);
        
        return {
          ...batch,
          available,
          is_weight_based: isWeightBased
        };
      });

      const total = batchesWithCalculations.reduce((sum, batch) => sum + batch.available, 0);
      setCurrentQuantity(total);
      setRecentBatches(batchesWithCalculations.slice(0, 5));
    }
  };

  const lookupSku = async (sku: string) => {
    if (!sku || mode === 'edit') return;

    const sanitizedSku = sanitizeSku(sku);
    if (sanitizedSku !== sku) {
      toast({
        title: "SKU Format Modified",
        description: "The SKU has been automatically adjusted to match the allowed format.",
        duration: 10000,
      });
      form.setValue('sku', sanitizedSku);
    }

    const { data, error } = await supabase
      .from('inventory')
      .select('*')
      .eq('sku', sku)
      .single();

    if (error) {
      console.error('Error looking up SKU:', error);
      return;
    }

    if (data) {
      toast({
        title: "",
        description: (
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <div className="bg-emerald-700 text-white rounded-full px-2 py-0.5 text-sm font-bold flex items-center gap-1">
                <CheckCircle2 className="h-3 w-3" />
                SKU Found
              </div>
              <span className="font-semibold">: {sanitizedSku}</span>
            </div>
            <span className="font-medium">
              {data.item_name}
            </span>
          </div>
        ),
        duration: 10000,
        className: "border-l-4 border-emerald-500"
      });
      
      form.setValue('item_name', data.item_name);
      form.setValue('description', data.description || '');
      form.setValue('vat_rate', data.vat_rate);
      await fetchBatchInfo(sku);
    }
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const { error } = await supabase
        .from('inventory')
        .upsert([values], { 
          onConflict: 'sku',
          ignoreDuplicates: false 
        });

      if (error) throw error;

      toast({
        title: "Success",
        description: (
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <div className="bg-emerald-100 text-emerald-700 rounded-full px-2 py-0.5 text-xs font-medium flex items-center gap-1">
                <CheckCircle2 className="h-3 w-3" />
                {values.sku}
              </div>
            </div>
            <span className="font-medium">
              {values.item_name} has been {mode === 'edit' ? 'updated' : 'added'} to inventory
            </span>
          </div>
        ),
        duration: 10000,
        className: "border-l-4 border-emerald-500",
      });

      form.reset(defaultValues);
      setOpen(false);
      onProductAdded();
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: `Error ${mode === 'edit' ? 'updating' : 'saving'} product`,
        description: error.message,
        duration: 10000,
        className: "border-l-4 border-destructive"
      });
    }
  };

  return (
    <Dialog 
      open={open} 
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          form.reset(defaultValues);
          setCurrentQuantity(0);
          setRecentBatches([]);
          const { dismiss } = toast({}); // Get dismiss function without showing a toast
          dismiss(); // Dismiss the toast immediately
        } else if (mode === 'edit' && initialItem?.sku) {
          fetchBatchInfo(initialItem.sku);
        }
        setOpen(isOpen);
      }}
    >
      <DialogTrigger asChild>
        {trigger || (
          variant === 'icon' ? (
            <Button 
              size="icon"
              className={cn(
                "h-9 w-9",
                mode === 'edit' 
                  ? "text-blue-600 hover:text-blue-800 p-1 rounded-md hover:bg-blue-50" 
                  : "bg-accent hover:bg-accent/90"
              )}
            >
              {mode === 'edit' ? (
                <Pencil className="h-4 w-4" />
              ) : (
                <Plus className="h-4 w-4" />
              )}
            </Button>
          ) : (
            <Button>
              <Plus className="mr-2 h-4 w-4" /> Add Product
            </Button>
          )
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold tracking-tight">
            {mode === 'edit' ? (
              <>Edit Product - <span className="text-accent">{initialItem?.sku}</span></>
            ) : (
              'Add New Product'
            )}
          </DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            {/* Main Content Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Left Column - Product Info */}
              <div className="space-y-6">
                <div className="rounded-lg border border-accent/20 bg-accent/5 p-4 space-y-4">
                  <div className="flex flex-col space-y-2">
                    {/* SKU Field */}
                    <FormField
                      control={form.control}
                      name="sku"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>SKU</FormLabel>
                          <FormControl>
                            <Input 
                              placeholder="Enter SKU" 
                              {...field} 
                              className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200"
                              onBlur={(e) => {
                                field.onBlur();
                                lookupSku(e.target.value);
                              }}
                              disabled={mode === 'edit'}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    {/* Product Name Field */}
                    <FormField
                      control={form.control}
                      name="item_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Product Name</FormLabel>
                          <FormControl>
                            <Input 
                              placeholder="Enter product name" 
                              {...field}
                              className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    {/* Description Field */}
                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Category</FormLabel>
                          <FormControl>
                            <Input 
                              placeholder="Enter description" 
                              {...field}
                              className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </div>

              {/* Right Column - Current Stock Info */}
              {(currentQuantity > 0 || recentBatches.length > 0) && (
                <div className="space-y-4">
                  <div className="rounded-lg border border-accent/20 bg-accent/5 p-4">
                    <div className="space-y-4">
                      <div>
                        <h3 className="font-semibold mb-2">Current Stock</h3>
                        <p className="text-2xl font-bold">{currentQuantity}</p>
                      </div>

                      {recentBatches.length > 0 && (
                        <div>
                          <h3 className="font-semibold mb-2">Recent Batches</h3>
                          <div className="space-y-2">
                            {recentBatches.map((batch) => (
                              <div 
                                key={batch.batch_number}
                                className="p-3 bg-white rounded-lg shadow-sm"
                              >
                                <div className="flex justify-between items-center">
                                  <span className="font-medium">{batch.batch_number}</span>
                                  <span className="text-sm text-muted-foreground">
                                    {new Date(batch.date_received).toLocaleDateString()}
                                  </span>
                                </div>
                                <div className="text-sm text-muted-foreground mt-1">
                                  Available: {batch.available} / {batch.is_weight_based ? batch.weight_kilos : batch.quantity}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Settings Section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {/* VAT Rate */}
              <div className="rounded-lg border bg-secondary/5 p-4">
                <FormField
                  control={form.control}
                  name="vat_rate"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>VAT Rate</FormLabel>
                      <Select
                        value={field.value.toString()}
                        onValueChange={(value) => {
                          const rate = parseFloat(value) as VATRate;
                          if (rate === 8.1 || rate === 2.6) {
                            field.onChange(rate);
                          }
                        }}
                      >
                        <FormControl>
                          <SelectTrigger className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200">
                            <SelectValue placeholder="Select VAT rate" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="8.1">8.1%</SelectItem>
                          <SelectItem value="2.6">2.6%</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* Tracking Type */}
              <div className="rounded-lg border bg-secondary/5 p-4">
                <FormField
                  control={form.control}
                  name="is_weight_based"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Tracking Type</FormLabel>
                      <div className="grid grid-cols-2 gap-2 mt-2">
                        <button
                          type="button"
                          onClick={() => field.onChange(false)}
                          className={cn(
                            "flex items-center justify-center gap-2 px-4 py-2 rounded-md transition-all duration-200",
                            !field.value
                              ? "bg-accent text-white shadow-md"
                              : "bg-white text-muted-foreground hover:bg-accent/10"
                          )}
                        >
                          <Boxes className="h-4 w-4" />
                          <span className="text-sm font-medium">Quantity</span>
                        </button>
                        <button
                          type="button"
                          onClick={() => field.onChange(true)}
                          className={cn(
                            "flex items-center justify-center gap-2 px-4 py-2 rounded-md transition-all duration-200",
                            field.value
                              ? "bg-accent text-white shadow-md"
                              : "bg-white text-muted-foreground hover:bg-accent/10"
                          )}
                        >
                          <Scale className="h-4 w-4" />
                          <span className="text-sm font-medium">Weight</span>
                        </button>
                      </div>
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <Button 
              type="submit" 
              className={cn(
                "w-full",
                "bg-accent hover:bg-accent/90",
                "transition-all duration-200"
              )}
            >
              {(mode === 'edit' || form.getValues('sku')) ? 'Update' : 'Add'} Product
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
