import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ContactForm } from '@/components/ContactForm';

export default function About() {
  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <div className="min-h-screen bg-[#304661] flex flex-col overflow-x-hidden">
      {/* Navigation */}
      <nav className="container mx-auto px-3 sm:px-4 py-4 sm:py-6 flex justify-between items-center">
        <Link
          to="/home"
          className="px-6 py-2 text-[#C3D48A] border-2 border-[#C3D48A] rounded-lg hover:bg-[#C3D48A] hover:text-[#304661] transition-colors duration-200"
        >
          ← Back to Home
        </Link>
        <img src="/ims_logoc.png" alt="Chefland IMS Logo" className="h-12" />
      </nav>

      {/* Main Content */}
      <main className="container mx-auto px-3 sm:px-4 py-8 sm:py-16 text-white">
        <div className="max-w-3xl mx-auto space-y-8 sm:space-y-12">
          {/* Intro Section */}
          <div className="text-center space-y-6">
            <h1 className="text-4xl sm:text-5xl font-bold">
              Chefland
              <span className="text-[#C3D48A] block">Inventory Management</span>
            </h1>
            <p className="text-xl text-gray-200 max-w-2xl mx-auto">
            Chefland IMS is a modern, custom built inventory management system designed specifically for food service operations.
            </p>
          </div>

          {/* Overview */}
          <div className="max-w-3xl mx-auto space-y-8">
            <p className="text-xl sm:text-2xl font-semibold leading-relaxed text-gray-200">
              Chefland IMS provides real-time batch tracking for precise control of food with expiry dates. The system is fully integrated with invoice and delivery note capabilites to help you maintain accurate stock levels at all times.
            </p>

            <p className="text-xl sm:text-2xl font-semibold leading-relaxed text-gray-200">
              With direct Small Invoice integration and mobile-friendly design, Chefland IMS adapts to your workflow while ensuring accuracy and reliability in your inventory management.
            </p>
          </div>

          {/* CTA Section */}
          <div className="text-center pt-6 sm:pt-8">
            <button
              onClick={() => setShowContactForm(true)}
              className="inline-block px-6 sm:px-8 py-3 bg-[#C3D48A] text-[#304661] text-lg font-semibold rounded-lg hover:bg-[#99AE4C] transition-colors duration-200"
            >
              Contact Us
            </button>
            <p className="text-sm text-gray-400 mt-2">
              Please contact us for more information
            </p>
          </div>

          {/* Contact Form Modal */}
          {showContactForm && (
            <ContactForm onClose={() => setShowContactForm(false)} />
          )}
        </div>
      </main>

      {/* Footer */}
      <footer className="container mx-auto px-4 py-8 mt-auto">
        <div className="flex flex-col items-center gap-4">
          <img src="/chefland2.png" alt="Chefland" className="h-6" />
          <a
            href="https://www.chefland.ch"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#C3D48A] hover:text-[#99AE4C] transition-colors duration-200 text-sm"
          >
            Visit Chefland Website →
          </a>
        </div>
      </footer>
    </div>
  );
}