import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Pencil } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import { 
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import * as z from "zod";
import { Batch } from "@/types/batch";
import { cn } from "@/lib/utils";
import { Textarea } from "@/components/ui/textarea";

const editBatchSchema = z.object({
  batch_number: z.string(),
  sku: z.string(),
  item_name: z.string().optional(),
  quantity: z.number().optional(),
  weight_kilos: z.number().optional(),
  expiry_date: z.string().optional(),
  date_received: z.string(),
  purchase_cost: z.number().min(0, "Purchase cost must be positive").optional(),
  warehouse: z.enum(["(+3°C)", "(+25°C)", "(-25°C)", "Paradiso", "Consignment"]),
  notes: z.string().optional(),
  shrinkage_reason: z.string().optional()
}).refine(
  (data) => data.quantity !== undefined || data.weight_kilos !== undefined,
  {
    message: "Either quantity or weight must be specified",
    path: ["quantity"]
  }
);

type EditBatchFormValues = z.infer<typeof editBatchSchema>;

interface EditBatchDialogProps {
  batch: Batch;
  onBatchUpdated: () => void;
}

export function EditBatchDialog({ batch, onBatchUpdated }: EditBatchDialogProps) {
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();
  const [loading, setLoading] = React.useState(false);
  
  const form = useForm<EditBatchFormValues>({
    resolver: zodResolver(editBatchSchema),
    defaultValues: {
      batch_number: batch.batch_number,
      sku: batch.sku,
      item_name: batch.inventory?.item_name ?? "",
      quantity: batch.quantity ?? undefined,
      weight_kilos: batch.weight_kilos ?? undefined,
      expiry_date: batch.expiry_date,
      date_received: batch.date_received,
      purchase_cost: batch.purchase_cost ?? undefined,
      warehouse: batch.warehouse as "(+3°C)" | "(+25°C)" | "(-25°C)" | "Paradiso" | "Consignment",
      notes: batch.notes ?? "",
      shrinkage_reason: batch.shrinkage_reason ?? ""
    },
    mode: "onSubmit",
    shouldFocusError: false
  });

  const onSubmit = async (values: EditBatchFormValues) => {
    try {
      setLoading(true);
      const { error: batchError } = await supabase
        .from('batches')
        .update({
          quantity: values.quantity,
          weight_kilos: values.weight_kilos,
          expiry_date: values.expiry_date,
          date_received: values.date_received,
          purchase_cost: values.purchase_cost,
          warehouse: values.warehouse,
          notes: values.notes,
          shrinkage_reason: values.shrinkage_reason || null
        })
        .eq('batch_number', batch.batch_number);

      if (batchError) throw batchError;

      if (values.item_name && values.item_name !== batch.inventory?.item_name) {
        const { error: inventoryError } = await supabase
          .from('inventory')
          .update({ item_name: values.item_name })
          .eq('sku', batch.sku);

        if (inventoryError) throw inventoryError;
      }

      toast({
        title: "Success",
        description: `Batch ${batch.batch_number} has been updated.`
      });

      setOpen(false);
      onBatchUpdated();
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message
      });
    } finally {
      setLoading(false);
    }
  };

  const isWeightBased = batch.inventory?.is_weight_based ?? false;
  const isShrinkage = (batch.quantity !== undefined && batch.quantity < 0) || (batch.weight_kilos !== undefined && batch.weight_kilos < 0);
  const SHRINKAGE_REASONS = ["Damaged", "Expired", "Lost", "Quality Control", "Theft", "Other"];

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon" className="h-8 w-8">
          <Pencil className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      
      <DialogContent
        className="sm:max-w-[600px] w-[95vw] max-h-[90vh] overflow-y-auto"
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <DialogHeader className="flex items-center justify-between">
          <div className="flex items-center">
            <DialogTitle className="text-xl font-semibold tracking-tight">Edit Batch Details</DialogTitle>
            {isShrinkage && (
              <span className="ml-2 rounded-full bg-red-500 text-white px-2 py-1 text-xs font-bold">
                SHRINKAGE
              </span>
            )}
          </div>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-6"
            autoComplete="off"
            noValidate
          >
            {/* Batch Info Section */}
            <div className="rounded-lg border border-accent/20 bg-accent/5 p-4">
              <div className="flex items-start justify-between gap-4">
                <div className="flex items-center gap-4">
                  <div>
                    <div className="text-sm text-muted-foreground mb-1">Batch Number</div>
                    <div className="text-lg font-semibold text-accent">{batch.batch_number}</div>
                  </div>
                  <div className="h-8 w-[1px] bg-accent/20" />
                  <div>
                    <div className="text-sm text-muted-foreground mb-1">SKU</div>
                    <div className="text-lg font-semibold text-accent">{batch.sku}</div>
                  </div>
                </div>
              </div>
            </div>

            {/* Product Name and Warehouse */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="item_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">Product Name</FormLabel>
                    <FormControl>
                      <Input 
                        {...field}
                        placeholder="Enter product name"
                        className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="warehouse"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">Warehouse</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200">
                          <SelectValue placeholder="Select warehouse" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="(+3°C)">(+3°C)</SelectItem>
                        <SelectItem value="(+25°C)">(+25°C)</SelectItem>
                        <SelectItem value="(-25°C)">(-25°C)</SelectItem>
                        <SelectItem value="Paradiso">Paradiso</SelectItem>
                        <SelectItem value="Consignment">Consignment</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {/* Quantity and Weight */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="quantity"
                render={({ field }) => (
                  <FormItem className={cn(!isWeightBased ? "md:col-span-1" : "md:col-span-2")}>
                    <FormLabel className="text-sm font-medium">
                      {isWeightBased ? "Quantity (Optional)" : "Quantity"}
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value ? Number(e.target.value) : undefined)}
                        placeholder="Enter quantity"
                        className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="weight_kilos"
                render={({ field }) => (
                  <FormItem className={cn(isWeightBased ? "md:col-span-1" : "md:col-span-2")}>
                    <FormLabel className="text-sm font-medium">
                      {isWeightBased ? "Weight (kg)" : "Weight (kg) (Optional)"}
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        step="0.01"
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value ? Number(e.target.value) : undefined)}
                        placeholder="Enter weight in kg"
                        className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {/* Dates and Cost */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <FormField
                control={form.control}
                name="date_received"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">Date Received</FormLabel>
                    <FormControl>
                      <Input
                        type="date"
                        {...field}
                        className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="expiry_date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">Expiry Date</FormLabel>
                    <FormControl>
                      <Input
                        type="date"
                        {...field}
                        value={field.value || ""}
                        className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="purchase_cost"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">Purchase Cost (CHF)</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        step="0.01"
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => field.onChange(e.target.value ? Number(e.target.value) : undefined)}
                        placeholder="Enter cost"
                        className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {/* Notes and conditional Shrinkage Reason */}
            <div className="grid grid-cols-1 gap-4">
              <FormField
                control={form.control}
                name="notes"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">Notes</FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        placeholder="Add any additional notes"
                        className="bg-white/50 hover:bg-white focus:bg-white transition-colors duration-200 min-h-[100px]"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isShrinkage && (
                <FormField
                  control={form.control}
                  name="shrinkage_reason"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium">Shrinkage Reason</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger className="bg-white/50 hover:bg-white focus:bg-white">
                            <SelectValue placeholder="Select reason for shrinkage" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {SHRINKAGE_REASONS.map((reason) => (
                            <SelectItem key={reason} value={reason}>
                              {reason}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>

            <div className="flex justify-end gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => setOpen(false)}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={loading}>
                {loading ? "Saving..." : "Save Changes"}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
