import React, { useState } from 'react';
import { ArrowDownLeft } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { DateRange } from 'react-day-picker';
import { format, addDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { cn } from '@/lib/utils';

const DeliveryNotesModal = () => {
  const [range, setRange] = useState<DateRange>({
    from: addDays(new Date(), -30),
    to: new Date()
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleNext = () => {
    if (!range.from || !range.to) {
      setError('Please select a valid date range');
      return;
    }
    
    if (range.from > range.to) {
      setError('End date cannot be before start date');
      return;
    }

    const formattedStart = format(range.from, 'yyyy-MM-dd');
    const formattedEnd = format(range.to, 'yyyy-MM-dd');
    navigate(`/delivery-notes?startDate=${formattedStart}&endDate=${formattedEnd}`);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="flex-1 h-9 bg-blue-600 hover:bg-blue-700 text-white">
          <ArrowDownLeft className="w-4 h-4 mr-1.5" />
          Get DNs
        </Button>
      </DialogTrigger>
      <DialogContent className="w-[90vw] max-w-[360px] bg-white p-4 sm:p-6 rounded-lg shadow-lg overflow-y-auto max-h-[90vh]">
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold text-gray-900">
            Select Date Range
          </DialogTitle>
        </DialogHeader>
        <div className="mt-4 space-y-4">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={range.from}
            selected={range}
            onSelect={(newRange) => {
              if (newRange?.from && newRange?.to) {
                setRange(newRange);
                setError('');
              }
            }}
            numberOfMonths={1}
            className="rounded-md border bg-white [&_.rdp-month]:w-[300px] [&_.rdp]:w-[300px] [&_.rdp-day]:w-[36px]"
          />
          
          {error && (
            <div className="mt-4 text-sm text-red-600">{error}</div>
          )}

          <div className="mt-6 flex flex-col sm:flex-row items-center sm:justify-between gap-4">
            <div className="text-sm font-semibold text-blue-600 break-words">
              {range.from ? format(range.from, 'd MMM yyyy') : ''} - {' '}
              {range.to ? format(range.to, 'd MMM yyyy') : ''}
            </div>
            <Button 
              onClick={handleNext}
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              Next
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeliveryNotesModal;
