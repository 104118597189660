import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { calculateAvailable } from "@/utils/batchUtils";
import { BatchesTable } from "@/components/batches/BatchesTable";
import { MobileBatchesView } from "@/components/batches/MobileBatchesView";
import type { Batch } from "@/types/batch";
import { Badge } from "@/components/ui/badge";

const Batches = () => {
  const [filterText, setFilterText] = useState("");
  const [expiredOnly, setExpiredOnly] = useState(false);
  const [hideEmpty, setHideEmpty] = useState(true); // Default to hiding empty batches

  // Event listeners for checkbox changes from BatchesTable
  useEffect(() => {
    const handleExpiredOnlyChange = (e: Event) => {
      const customEvent = e as CustomEvent;
      setExpiredOnly(customEvent.detail);
    };

    const handleHideEmptyChange = (e: Event) => {
      const customEvent = e as CustomEvent;
      setHideEmpty(customEvent.detail);
    };

    window.addEventListener('expiredOnlyChange', handleExpiredOnlyChange);
    window.addEventListener('hideEmptyChange', handleHideEmptyChange);

    return () => {
      window.removeEventListener('expiredOnlyChange', handleExpiredOnlyChange);
      window.removeEventListener('hideEmptyChange', handleHideEmptyChange);
    };
  }, []);

  // Helper to check if a batch is expired
  const isBatchExpired = (batch: Batch) => {
    const today = new Date();
    return new Date(batch.expiry_date) < today;
  };
  
  const { data: batches, refetch } = useQuery({
    queryKey: ['batches'],
    queryFn: async () => {
      // Get batches with inventory data and invoice items
      const { data: batchData, error: batchError } = await supabase
        .from('batches')
        .select(`
          *,
          inventory:inventory (
            item_name,
            is_weight_based
          ),
          invoice_items (
            weight_kilos,
            quantity,
            invoice:invoices (
              status
            )
          )
        `)
        .order('date_received', { ascending: false });
      
      if (batchError) throw batchError;

      // Add item_name at root level for backwards compatibility
      const enrichedBatches = batchData.map(batch => ({
        ...batch,
        item_name: batch.inventory?.item_name || 'Unknown Item',
        inventory: {
          ...batch.inventory,
          item_name: batch.inventory?.item_name || 'Unknown Item'
        }
      }));

      return enrichedBatches as Batch[];
    },
  });

  return (
    <div>
      {/* Mobile View */}
      <MobileBatchesView
        batches={batches || []}
        filterText={filterText}
        onFilterChange={setFilterText}
        onBatchUpdated={refetch}
        expiredOnly={expiredOnly}
        onExpiredChange={setExpiredOnly}
        hideEmpty={hideEmpty}
        onHideEmptyChange={setHideEmpty}
      />

      {/* Desktop View */}
      <div className="hidden lg:block min-h-screen">
        <div className="mb-8 flex items-center gap-2">
          <h1 className="text-2xl font-bold">Batch Management</h1>
          <div className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium">
            {batches?.filter(batch => calculateAvailable(batch, batches) > 0).length || 0} active batches
          </div>
        </div>

        <BatchesTable
          batches={batches || []}
          onBatchUpdated={refetch}
          filterText={filterText}
          expiredOnly={expiredOnly}
          hideEmpty={hideEmpty}
        />
      </div>
    </div>
  );
};

export default Batches;
