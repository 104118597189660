import React, { useState } from 'react';
import { Eye, Info, TrendingUp, TrendingDown, Trash2, Minus } from 'lucide-react';
import { Search } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { AddBatchDialog } from './AddBatchDialog';
import { AddShrinkageDialog } from './AddShrinkageDialog';
import { EditBatchDialog } from './EditBatchDialog';
import { ViewBatchDialog } from './ViewBatchDialog';
import { MobileHeader } from '../layout/MobileHeader';
import { Batch } from '@/types/batch';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { calculateAvailable } from '@/utils/batchUtils';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { useAuth } from '@/contexts/AuthContext';
import { useToast } from '@/components/ui/use-toast';
import Logger from '@/utils/logger';

interface InvoiceItem {
  invoice: {
    invoice_number: string;
    company: string;
    date: string;
  };
}

interface MobileBatchesViewProps {
  batches: Batch[];
  filterText: string;
  onFilterChange: (text: string) => void;
  onBatchUpdated: () => void;
  expiredOnly: boolean;
  onExpiredChange: (checked: boolean) => void;
  hideEmpty: boolean;
  onHideEmptyChange: (checked: boolean) => void;
}

function PriceHistory({ sku, currentBatchNumber }: { sku: string; currentBatchNumber: string }) {
  const { data: recentBatches } = useQuery({
    queryKey: ['batch-history', sku],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('batches')
        .select('batch_number, date_received, purchase_cost')
        .eq('sku', sku)
        .order('date_received', { ascending: false })
        .limit(10);

      if (error) throw error;
      return data || [];
    },
  });

  if (!recentBatches?.length) return null;

  // Calculate percentage changes
  const batchesWithChanges = recentBatches.map((batch, index) => {
    let percentChange = null;
    if (index < recentBatches.length - 1) {
      const prevCost = recentBatches[index + 1].purchase_cost;
      const currentCost = batch.purchase_cost;
      percentChange = ((currentCost - prevCost) / prevCost) * 100;
    }
    return { ...batch, percentChange };
  });

  return (
    <div className="divide-y divide-gray-100">
      {batchesWithChanges.map((batch) => (
        <div 
          key={batch.batch_number}
          className={cn(
            "flex items-center justify-between px-3 py-2",
            batch.batch_number === currentBatchNumber ? "bg-blue-50" : "hover:bg-gray-50"
          )}
        >
          <div className="flex-1">
            <div className="font-medium">
              {new Date(batch.date_received).toLocaleDateString('en-GB', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
              })}
            </div>
            <div className="text-sm text-gray-500 flex items-center gap-1">
              {batch.batch_number}
              {batch.batch_number === currentBatchNumber && (
                <span className="text-xs bg-blue-100 text-blue-800 px-1.5 py-0.5 rounded-sm">Current</span>
              )}
            </div>
          </div>
          <div className="text-right flex items-center gap-2">
            <div>
              <div className="font-medium">CHF {(batch.purchase_cost ?? 0).toFixed(2)}</div>
              {batch.percentChange !== null && (
                <div className={cn(
                  "text-sm flex items-center justify-end gap-0.5",
                  batch.percentChange > 0 ? "text-red-600" : batch.percentChange < 0 ? "text-green-600" : "text-gray-500"
                )}>
                  {batch.percentChange > 0 ? (
                    <TrendingUp className="h-3 w-3" />
                  ) : batch.percentChange < 0 ? (
                    <TrendingDown className="h-3 w-3" />
                  ) : null}
                  {batch.percentChange > 0 ? "+" : ""}
                  {batch.percentChange.toFixed(1)}%
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export function MobileBatchesView({
  batches,
  filterText,
  onFilterChange,
  onBatchUpdated,
  expiredOnly,
  onExpiredChange,
  hideEmpty,
  onHideEmptyChange
}: MobileBatchesViewProps) {
  const [selectedBatch, setSelectedBatch] = useState<Batch | null>(null);
  const { canEdit } = useAuth();
  const { toast } = useToast();

  // Component-level cache for availability calculations
  const availabilityCache = React.useMemo(() => new Map<string, number>(), []);
  
  // Wrapper for availability calculation with caching
  const getAvailability = React.useCallback((batch: Batch) => {
    if (availabilityCache.has(batch.batch_number)) {
      return availabilityCache.get(batch.batch_number)!;
    }
    const amount = calculateAvailable(batch, batches);
    availabilityCache.set(batch.batch_number, amount);
    return amount;
  }, [batches, availabilityCache]);

  // Clear cache when batches change
  React.useEffect(() => {
    availabilityCache.clear();
  }, [batches, availabilityCache]);

  const handleDelete = async (batch: Batch) => {
    try {
      Logger.info('Checking batch usage', { batch_number: batch.batch_number });

      // Get all invoices using this batch
      const { data: items, error: checkError } = await supabase
        .from('invoice_items')
        .select(`
          invoice:invoices!inner (
            invoice_number,
            company,
            date
          )
        `)
        .eq('batch_number', batch.batch_number);

      if (checkError) {
        Logger.error('Error checking batch usage', checkError);
        throw checkError;
      }

      if (items && items.length > 0) {
        const invoiceList = (items as unknown as InvoiceItem[])
          .map(item => {
            const date = new Date(item.invoice.date).toLocaleDateString();
            return `${item.invoice.invoice_number} (${item.invoice.company}, ${date})`;
          })
          .join('\n');

        toast({
          variant: "destructive",
          title: "Cannot delete batch",
          description: `This batch is used in the following invoices:\n${invoiceList}`
        });
        return;
      }

      Logger.info('Deleting batch', { batch_number: batch.batch_number });

      const { error: batchError } = await supabase
        .from('batches')
        .delete()
        .eq('batch_number', batch.batch_number);

      if (batchError) {
        Logger.error('Error deleting batch', batchError);
        throw batchError;
      }

      Logger.info('Batch deleted successfully', { batch_number: batch.batch_number });
      toast({
        title: "Success",
        description: "Batch deleted successfully"
      });

      onBatchUpdated();
    } catch (error) {
      Logger.error('Error in handleDelete', error);
      toast({
        variant: "destructive",
        title: "Error deleting batch",
        description: error.message
      });
    }
  };

  return (
    <div className="lg:hidden">
      <MobileHeader />
      <div className="fixed top-14 left-0 right-0 bottom-0 bg-white z-10 overflow-y-auto scrollbar-none">
        <div className="px-4 py-3 bg-white border-b">
          <div className="flex items-center gap-2 mb-2">
            <h1 className="text-xl font-bold">Inbound</h1>
            <div className="bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full text-sm font-medium">
              {batches.filter(batch => getAvailability(batch) > 0).length} active batches
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
              <Input
                placeholder="Search by SKU, batch number or name..."
                value={filterText}
                onChange={(e) => onFilterChange(e.target.value)}
                className="pl-9 pr-3 w-full"
                autoFocus={false}
              />
            </div>
            <div className="flex items-center gap-2">
              <label className="flex items-center gap-2 whitespace-nowrap text-sm text-gray-600">
                <input
                  type="checkbox"
                  checked={expiredOnly}
                  onChange={(e) => onExpiredChange(e.target.checked)}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                Exp only
              </label>
              <label className="flex items-center gap-2 whitespace-nowrap text-sm text-gray-600">
                <input
                  type="checkbox"
                  checked={hideEmpty}
                  onChange={(e) => onHideEmptyChange(e.target.checked)}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                Hide inactive
              </label>
            </div>
            <div className="flex-none flex gap-1">
              <AddBatchDialog 
                onBatchAdded={onBatchUpdated}
                variant="icon"
              />
              <AddShrinkageDialog 
                onBatchAdded={onBatchUpdated}
                variant="icon"
              />
            </div>
          </div>
        </div>

        {batches
          .filter(batch => {
            // Text filter
            const matchesText = batch.batch_number.toLowerCase().includes(filterText.toLowerCase()) ||
              (batch.item_name || '').toLowerCase().includes(filterText.toLowerCase()) ||
              batch.sku.toLowerCase().includes(filterText.toLowerCase());

            // Calculate availability once using cache
            const available = getAvailability(batch);
            const isShrinkage = batch.batch_number.startsWith('SHR-');

            // Expired filter (only show expired items with non-zero quantities)
            if (expiredOnly) {
              const isExpired = new Date(batch.expiry_date) < new Date();
              return matchesText && isExpired && available > 0;
            }

            // Always show SHR transactions regardless of hideEmpty setting
            if (isShrinkage) {
              return matchesText;
            }

            // Only check available amount for non-SHR batches
            if (hideEmpty && available === 0) {
              return false;
            }

            return matchesText;
          })
          .map((batch) => (
          <div 
            key={batch.batch_number} 
            className={cn(
              "mx-4 my-3 rounded-lg shadow border-b border-gray-200",
              batch.batch_number.startsWith('SHR-') ? "bg-red-50" : "bg-blue-50"
            )}
          >
            <div className="p-3">
              <div className="flex justify-between items-start">
                <h3
                  className="text-base font-medium text-gray-900 leading-tight max-w-[75%] cursor-pointer hover:text-blue-600"
                  onClick={() => setSelectedBatch(batch)}
                >
                  {batch.item_name}
                </h3>
                <div className="flex items-center gap-1.5">
                  {canEdit && (
                    <>
                      <EditBatchDialog
                        batch={batch}
                        onBatchUpdated={onBatchUpdated}
                      />
                      <button
                        onClick={() => {
                          if (window.confirm('Are you sure you want to delete this batch?')) {
                            handleDelete(batch);
                          }
                        }}
                        className="text-gray-400 hover:text-red-600 p-1 rounded-full hover:bg-red-50"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </>
                  )}
                </div>
              </div>

              {/* Identifiers */}
              <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
                <div className="bg-gray-50 p-1.5 rounded">
                  <span className="text-gray-500">Batch: </span>
                  <span className="font-medium text-gray-900">{batch.batch_number}</span>
                </div>
                <div className="bg-gray-50 p-1.5 rounded">
                  <span className="text-gray-500">SKU: </span>
                  <span className="font-medium text-gray-900">{batch.sku}</span>
                </div>
              </div>

              {/* Quantities and Cost */}
              <div className="mt-3 p-2 border rounded-md bg-gray-50">
                <div className="grid grid-cols-3 gap-3">
                  <div>
                    <div className="text-xs text-gray-500">Original QTY</div>
                    <div className={cn(
                      "font-medium",
                      batch.batch_number.startsWith('SHR-') ? "text-red-600" : "text-gray-900"
                    )}>
                      {batch.weight_kilos !== null ? `${Number(batch.weight_kilos).toFixed(2)} kg` : batch.quantity}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-gray-500">Available QTY</div>
                    <div className="font-medium text-gray-900">
                      {(() => {
                        const isWeightBased = batch.inventory?.is_weight_based;
                        const available = getAvailability(batch);
                        return isWeightBased ? `${Number(available).toFixed(2)} kg` : available;
                      })()}
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="text-xs text-gray-500">Unit Cost</div>
                    <div className="font-medium text-blue-700">
                      CHF {(batch.purchase_cost ?? 0).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>

              {/* Expiry */}
              <div className="mt-2 text-sm">
                <span className="text-gray-500">Expires: </span>
                <span className="font-medium text-gray-900">
                  {new Date(batch.expiry_date).toLocaleDateString('en-GB', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                  })}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedBatch && (
        <ViewBatchDialog
          batch={selectedBatch}
          open={!!selectedBatch}
          onOpenChange={(open) => !open && setSelectedBatch(null)}
          onBatchUpdated={onBatchUpdated}
        />
      )}
    </div>
  );
}
