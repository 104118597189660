import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Checkbox } from '@/components/ui/checkbox';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import { ValidatedPosition } from '@/types/delivery-note';
import Logger from '@/utils/logger';
import { CreateBatchDialog } from './CreateBatchDialog';
import { Loader2, CheckCircle2, XCircle } from 'lucide-react';

interface BatchMatchPanelProps {
    position: ValidatedPosition;
    availableBatches: Array<{
        batch_number: string;
        available_quantity?: number;
        expiry_date?: string;
        inventory?: {
            is_weight_based: boolean;
        };
    }>;
    onBatchSelect: (batchNumber: string | null) => void;
    onSkipItem?: (skip: boolean) => void;
    onNoteChange?: (note: string) => void;
    disabled?: boolean;
    loading?: boolean;
    allowSkip?: boolean;
}

const CONFIDENCE_CONFIG = {
    high: {
        color: 'bg-green-100 text-green-800',
        label: 'Strong Match',
        description: 'Exact batch number match'
    },
    medium: {
        color: 'bg-yellow-100 text-yellow-800',
        label: 'Possible Match',
        description: 'Similar batch number found'
    },
    low: {
        color: 'bg-orange-100 text-orange-800',
        label: 'Weak Match',
        description: 'Partial match found'
    },
    none: {
        color: 'bg-red-100 text-red-800',
        label: 'No Match',
        description: 'No matching batch found'
    }
} as const;

export function BatchMatchPanel({
    position,
    availableBatches,
    onBatchSelect,
    onSkipItem,
    onNoteChange,
    disabled,
    loading,
    allowSkip = true
}: BatchMatchPanelProps) {
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [isSkipped, setIsSkipped] = useState(position.skipped || false);
    const [selectedBatch, setSelectedBatch] = useState<string | undefined>(
        position.batchMatch?.matchedBatch || undefined
    );
    const [isManuallySelected, setIsManuallySelected] = useState(false);
    const wasManuallySelected = React.useRef(false);

    const handleBatchSelect = (value: string) => {
        if (isSkipped) {
            setIsSkipped(false);
            if (onSkipItem) onSkipItem(false);
        }
        setSelectedBatch(value);
        setIsManuallySelected(true);
        wasManuallySelected.current = true;
        onBatchSelect(value);
    };

    const handleBatchCreated = async (batchNumber: string) => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        setSelectedBatch(batchNumber);
        onBatchSelect(batchNumber);
    };

    const handleSkipChange = (checked: boolean) => {
        setIsSkipped(checked);
        if (checked) {
            onBatchSelect(null);
        } else if (position.batchMatch.matchedBatch) {
            setSelectedBatch(position.batchMatch.matchedBatch);
            onBatchSelect(position.batchMatch.matchedBatch);
        }
        if (onSkipItem) {
            onSkipItem(checked);
        }
    };

    const renderConfidenceBadge = () => {
        if (isManuallySelected) {
            return null;
        }
        
        const config = CONFIDENCE_CONFIG[position.batchMatch.confidence];
        return (
            <div className="flex items-center gap-2">
                <Badge variant="outline" className={config.color}>
                    {config.label}
                </Badge>
            </div>
        );
    };

    const renderBatchItem = (batch: typeof availableBatches[0]) => {
        const hasSufficientQty = batch.available_quantity !== undefined && 
            batch.available_quantity >= position.position.amount;
        const tooltipText = hasSufficientQty 
            ? `Sufficient ${batch.inventory?.is_weight_based ? 'weight' : 'quantity'}`
            : `Insufficient ${batch.inventory?.is_weight_based ? 'weight' : 'quantity'}: needs ${position.position.amount}${batch.inventory?.is_weight_based ? ' kg' : ''}`;

        return (
            <div className="flex items-center gap-2 w-full py-1">
                {/* Status and Quantity Badges - Left Side */}
                <div className="flex items-center gap-1.5">
                    <Badge 
                        variant="secondary" 
                        className={`flex-none ${hasSufficientQty ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'}`}
                        title={tooltipText}
                    >
                        {hasSufficientQty ? (
                            <CheckCircle2 className="h-3.5 w-3.5" />
                        ) : (
                            <XCircle className="h-3.5 w-3.5" />
                        )}
                    </Badge>
                    {batch.available_quantity !== undefined && (
                        <Badge variant="secondary" className="flex-none bg-blue-50 text-blue-700 px-2 py-0.5">
                            {batch.inventory?.is_weight_based
                                ? `${batch.available_quantity} kg`
                                : batch.available_quantity}
                        </Badge>
                    )}
                </div>
                {/* Batch Details - Right Side */}
                <div className="flex flex-col min-w-0 flex-1">
                    <span className="font-medium text-sm truncate">{batch.batch_number}</span>
                    {batch.expiry_date && (
                        <span className="text-xs text-gray-500">
                            Expires: {new Date(batch.expiry_date).toLocaleDateString()}
                        </span>
                    )}
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (availableBatches.length > 0 && !selectedBatch && !position.skipped) {
            if (position.batchMatch.matchedBatch) {
                const batchExists = availableBatches.some(b => b.batch_number === position.batchMatch.matchedBatch);
                if (batchExists) {
                    setSelectedBatch(position.batchMatch.matchedBatch);
                    setIsManuallySelected(false);
                    onBatchSelect(position.batchMatch.matchedBatch);
                    return;
                }
            }

            if (position.batchMatch.lottoNumber) {
                const exactMatch = availableBatches.find(b =>
                    b.batch_number.toLowerCase().includes(position.batchMatch.lottoNumber.toLowerCase()) ||
                    position.batchMatch.lottoNumber.toLowerCase().includes(b.batch_number.toLowerCase())
                );
                if (exactMatch) {
                    setSelectedBatch(exactMatch.batch_number);
                    setIsManuallySelected(false);
                    onBatchSelect(exactMatch.batch_number);
                    return;
                }
            }

            if (position.batchMatch.confidence !== 'none' && availableBatches.length === 1) {
                setSelectedBatch(availableBatches[0].batch_number);
                setIsManuallySelected(false);
                onBatchSelect(availableBatches[0].batch_number);
            }
        }
    }, [availableBatches, position, selectedBatch, onBatchSelect]);

    return (
        <div className="space-y-4 p-3 sm:p-4 border rounded-lg bg-white">
            <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
                <div className="flex items-center gap-2">
                    {loading ? (
                        <Loader2 className="h-4 w-4 animate-spin" />
                    ) : (
                        <>
                            {renderConfidenceBadge()}
                            {selectedBatch && !isSkipped && (
                                <Badge variant="outline" className="bg-green-100 text-green-800">
                                    <CheckCircle2 className="h-4 w-4 mr-1" />
                                    Ready to Import
                                </Badge>
                            )}
                        </>
                    )}
                </div>
                {position.batchMatch.lottoNumber && (
                    <span className="text-sm text-gray-500 break-words">
                        Batch: {position.batchMatch.lottoNumber.replace(/<[^>]*>/g, '').replace(/Lotto:\s*/g, '')}
                    </span>
                )}
            </div>

            {/* Batch Selection Controls */}
            {!isSkipped && (
                <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-2">
                    <Select
                        disabled={disabled || loading}
                        onValueChange={(value) => {
                            handleBatchSelect(value);
                        }}
                        value={selectedBatch}
                    >
                        <SelectTrigger className="w-full sm:w-[280px] h-[36px] px-3 py-1">
                            <SelectValue 
                                placeholder={availableBatches.length === 0 ? (
                                    <span className="flex items-center gap-2 text-yellow-600">
                                        ⚠️ No batches available
                                    </span>
                                ) : "Select batch"}
                            >
                                {selectedBatch && (() => {
                                    const batch = availableBatches.find(b => b.batch_number === selectedBatch);
                                    if (!batch) return selectedBatch;

                                    const hasSufficientQty = batch.available_quantity !== undefined && 
                                        batch.available_quantity >= position.position.amount;

                                    return (
                                        <div className="flex items-center gap-2">
                                            <div className="flex items-center gap-1.5 shrink-0 min-w-0">
                                                <span title={hasSufficientQty 
                                                    ? `Sufficient ${batch.inventory?.is_weight_based ? 'weight' : 'quantity'}`
                                                    : `Insufficient ${batch.inventory?.is_weight_based ? 'weight' : 'quantity'}: needs ${position.position.amount}${batch.inventory?.is_weight_based ? ' kg' : ''}`
                                                }>
                                                    {hasSufficientQty ? (
                                                        <CheckCircle2 className="h-3.5 w-3.5 text-green-600 flex-none" />
                                                    ) : (
                                                        <XCircle className="h-3.5 w-3.5 text-red-600 flex-none" />
                                                    )}
                                                </span>
                                                <Badge variant="secondary" className="bg-blue-50 text-blue-700 flex-none">
                                                    {batch.available_quantity !== undefined && (
                                                        batch.inventory?.is_weight_based
                                                            ? `${batch.available_quantity}kg`
                                                            : batch.available_quantity
                                                    )}
                                                </Badge>
                                            </div>
                                            <span className="truncate">{selectedBatch}</span>
                                        </div>
                                    );
                                })()}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent className="p-1 max-h-[240px] overflow-y-auto">
                            {availableBatches.map((batch) => (
                                <SelectItem
                                    key={batch.batch_number}
                                    value={batch.batch_number}
                                    className={`break-words data-[state=checked]:bg-blue-50/80 [&>[data-radix-select-item-indicator]]:hidden ${
                                        batch.available_quantity !== undefined &&
                                        batch.available_quantity < position.position.amount
                                            ? 'opacity-50' : ''
                                    }`}
                                    disabled={
                                        batch.available_quantity !== undefined &&
                                        batch.available_quantity < position.position.amount
                                    }
                                >
                                    <div className="px-1">
                                        {renderBatchItem(batch)}
                                    </div>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>

                    <Button
                        variant="default"
                        onClick={() => setShowCreateDialog(true)}
                        disabled={disabled || loading}
                        className="w-full sm:w-auto whitespace-nowrap bg-blue-600 hover:bg-blue-700 h-[36px]"
                    >
                        Create New Batch
                    </Button>
                </div>
            )}

            {/* Skip Option */}
            {allowSkip && (
                <div className="flex items-center space-x-2">
                    <Checkbox
                        id={`skip-${position.position.number}`}
                        checked={isSkipped}
                        onCheckedChange={handleSkipChange}
                        disabled={disabled || loading}
                    />
                    <label
                        htmlFor={`skip-${position.position.number}`}
                        className="text-sm text-gray-700 cursor-pointer select-none"
                    >
                        Skip this item (will not be imported)
                    </label>
                </div>
            )}

            {/* Notes Section */}
            <div className="space-y-2">
                <div className="text-sm text-gray-600">
                    <span>Notes</span>
                </div>
                <textarea
                    className="w-full min-h-[80px] px-3 py-2 text-sm bg-gray-50 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white placeholder:text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed font-mono"
                    defaultValue={position.position.description || ''}
                    placeholder="Add notes here..."
                    onChange={(e) => onNoteChange?.(e.target.value)}
                    disabled={disabled || loading}
                />
            </div>

            <CreateBatchDialog
                isOpen={showCreateDialog}
                onClose={() => setShowCreateDialog(false)}
                onCreated={handleBatchCreated}
                sku={position.position.number!}
                suggestedBatch={position.batchMatch.lottoNumber}
                suggestedAmount={position.position.amount}
                itemName={position.skuDetails?.item_name}
            />
        </div>
    );
}
