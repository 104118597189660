import { useState, useCallback } from 'react'
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameDay, parseISO } from 'date-fns'
import { useQuery } from '@tanstack/react-query'
import { supabase } from '@/lib/supabase'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { ChevronLeft, ChevronRight, Calendar, X } from 'lucide-react'
import { useIsMobile } from "@/hooks/use-mobile"
import { MobileHeader } from "@/components/layout/MobileHeader"

interface BatchItem {
  batch_number: string
  sku: string
  quantity: number
  weight_kilos: number | null
  expiry_date: string
  purchase_cost: number
  warehouse: string
  date_received: string
  inventory: {
    item_name: string,
    is_weight_based: boolean
  }
  invoice_items?: Array<{
    weight_kilos?: number;
    quantity?: number;
  }>;
  shrinkage_entries?: Array<{
    weight_kilos?: number;
    quantity?: number;
    batch_number: string;
    shrinkage_reason?: string;
  }>;
}

const calculateAvailableQuantity = (batch: BatchItem | null): number => {
  if (!batch) return 0;

  const isWeightBased = batch.inventory?.is_weight_based ?? false;
  const original = isWeightBased ? batch.weight_kilos : batch.quantity;
  if (original === null || original === undefined) return 0;

  // Sum up all used quantities from invoice items
  const used = (batch.invoice_items || []).reduce((sum, item) => {
    const itemQty = isWeightBased ? (item.weight_kilos ?? 0) : (item.quantity ?? 0);
    return sum + itemQty;
  }, 0);

  // Sum up any shrinkage entries (which are stored as negative values)
  const shrinkage = (batch.shrinkage_entries || []).reduce((sum, entry) => {
    const shrinkageQty = isWeightBased ? (entry.weight_kilos ?? 0) : (entry.quantity ?? 0);
    return sum + shrinkageQty; // Shrinkage values are already negative
  }, 0);

  // Calculate available: original + shrinkage (negative) - used
  const available = Math.max(0, original + shrinkage - used);

  return available;
};

const getExpiryText = (expiryDate: Date) => {
  const today = new Date()
  const timeLeft = expiryDate.getTime() - today.getTime()
  const daysLeft = Math.ceil(timeLeft / (1000 * 60 * 60 * 24))

  if (daysLeft < 0) {
    return 'EXPIRED'
  } else if (daysLeft === 0) {
    return '0 days until expiry'
  } else if (daysLeft < 7) {
    return `${daysLeft} days until expiry`
  } else if (daysLeft < 31) {
    const weeks = Math.floor(daysLeft / 7)
    return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} until expiry`
  } else {
    const months = Math.floor(daysLeft / 30.44) // average month length
    return `${months} ${months === 1 ? 'month' : 'months'} until expiry`
  }
}

const formatDate = (dateString: string | null | undefined) => {
  if (!dateString) return ''
  try {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) return ''
    return format(date, 'dd.MM.yyyy')
  } catch (e) {
    return ''
  }
}

export default function ExpiryCalendar() {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedBatch, setSelectedBatch] = useState<BatchItem | null>(null);
  const [remainingBatches, setRemainingBatches] = useState<{ date: string; batches: BatchItem[] } | null>(null);
  const isMobile = useIsMobile();

  const handleShowMore = useCallback((date: string, allBatches: BatchItem[]) => {
    setRemainingBatches({
      date,
      batches: allBatches.slice(3)
    });
  }, []);

  const { data: batches, isLoading, error } = useQuery<BatchItem[]>({
    queryKey: ['expiring-batches', format(currentDate, 'yyyy-MM')],
    queryFn: async () => {
      const startDate = startOfMonth(currentDate)
      const endDate = endOfMonth(currentDate)

      // First get all non-shrinkage batches expiring in the specified date range
      const { data, error } = await supabase
        .from('batches')
        .select(`
          *,
          inventory:inventory(item_name, is_weight_based),
          invoice_items:invoice_items(
            weight_kilos,
            quantity
          )
        `)
        .gte('expiry_date', format(startDate, 'yyyy-MM-dd'))
        .lte('expiry_date', format(endDate, 'yyyy-MM-dd'))
        .not('batch_number', 'ilike', 'SHR-%') // Exclude shrinkage entries from main results
        .order('expiry_date')

      if (error) throw error
      if (!data || data.length === 0) return []

      // For each batch, fetch related shrinkage entries
      const batchesWithShrinkage = await Promise.all(data.map(async (batch) => {
        // Get all shrinkage entries related to this batch 
        const { data: shrinkageEntries } = await supabase
          .from('batches')
          .select('*')
          .eq('base_id', batch.batch_number)
          .ilike('batch_number', 'SHR-%')
          
        // Add shrinkage entries to the batch object
        return {
          ...batch,
          shrinkage_entries: shrinkageEntries || []
        }
      }))

      return batchesWithShrinkage
    },
  })

  // Group batches by expiry date, excluding those with no available quantity
  const batchesByDate = batches?.reduce((acc: Record<string, BatchItem[]>, batch) => {
    // Skip items with no available quantity
    const availableQty = calculateAvailableQuantity(batch);
    if (availableQty <= 0) {
      return acc;
    }

    const date = batch.expiry_date.split('T')[0];
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(batch);
    return acc;
  }, {});

  // Remove any empty dates (where all items had 0 quantity)
  const filteredBatchesByDate = Object.entries(batchesByDate || {})
    .filter(([_, batches]) => batches.length > 0)
    .reduce((acc, [date, batches]) => {
      acc[date] = batches;
      return acc;
    }, {} as Record<string, BatchItem[]>);

  console.log('Grouped batches:', filteredBatchesByDate)

  // Calculate total value for each date
  const dateValues = Object.entries(filteredBatchesByDate).reduce(
    (acc: Record<string, number>, [date, items]) => {
      acc[date] = items.reduce((sum, item) => {
         const availableQty = calculateAvailableQuantity(item);
         const cost = item.purchase_cost || 0;
         return sum + (availableQty * cost);
       }, 0)
      return acc
    },
    {}
  )

  const nextMonth = () => {
    setCurrentDate(date => new Date(date.getFullYear(), date.getMonth() + 1))
  }

  const prevMonth = () => {
    setCurrentDate(date => new Date(date.getFullYear(), date.getMonth() - 1))
  }

  // Mobile list view component with timeline grouping
  const MobileView = () => {
    // Group batches by time period
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const nextWeek = new Date(today);
    nextWeek.setDate(nextWeek.getDate() + 7);

    const groupBatches = () => {
      const grouped = {
        expired: [] as BatchItem[],
        today: [] as BatchItem[],
        tomorrow: [] as BatchItem[],
        thisWeek: [] as BatchItem[],
        later: [] as BatchItem[],
      };

      Object.values(filteredBatchesByDate).flat().forEach(batch => {
        const expiryDate = new Date(batch.expiry_date);
        
        if (expiryDate < today) {
          grouped.expired.push(batch);
        } else if (isSameDay(expiryDate, today)) {
          grouped.today.push(batch);
        } else if (isSameDay(expiryDate, tomorrow)) {
          grouped.tomorrow.push(batch);
        } else if (expiryDate <= nextWeek) {
          grouped.thisWeek.push(batch);
        } else {
          grouped.later.push(batch);
        }
      });

      return grouped;
    };

    const groupedBatches = groupBatches();

    // Calculate total value for a group
    const calculateGroupValue = (batches: BatchItem[]) => {
      return batches.reduce((sum, batch) => {
        const availableQty = calculateAvailableQuantity(batch);
        return sum + (availableQty * batch.purchase_cost);
      }, 0);
    };

    const GroupHeader = ({ title, count, value }: { title: string, count: number, value: number }) => {
      return (
        <div className="bg-blue-50 mx-4 px-4 py-3 rounded-t-lg border-b border-blue-100">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <span className="text-lg font-semibold text-blue-900">{title}</span>
              <div className="bg-white px-2 py-0.5 rounded-full text-sm font-medium text-blue-700">
                {count} items
              </div>
            </div>
            {value > 0 && (
              <div className="text-sm font-medium bg-white px-3 py-1 rounded-full text-blue-700 shadow-sm">
                CHF {Math.round(value).toLocaleString('en-CH')}
              </div>
            )}
          </div>
        </div>
      );
    };

    const getStatusColor = (expiryDate: Date): { bg: string; text: string; border: string } => {
      const today = new Date();
      const daysUntil = Math.ceil((expiryDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

      if (daysUntil < 0) return { bg: 'bg-red-500', text: 'text-red-500', border: 'border-red-500' };
      if (daysUntil === 0) return { bg: 'bg-red-500', text: 'text-red-500', border: 'border-red-500' };
      if (daysUntil <= 7) return { bg: 'bg-orange-500', text: 'text-orange-500', border: 'border-orange-500' };
      if (daysUntil <= 30) return { bg: 'bg-yellow-500', text: 'text-yellow-500', border: 'border-yellow-500' };
      return { bg: 'bg-green-500', text: 'text-green-500', border: 'border-green-500' };
    };

    const formatExpiryBadge = (expiryDate: Date): string => {
      const today = new Date();
      const daysUntil = Math.ceil((expiryDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

      if (daysUntil < 0) return 'EXP';
      if (daysUntil === 0) return 'TODAY';
      if (daysUntil === 1) return '1d';
      return `${daysUntil}d`;
    };

    const BatchCard = ({ batch }: { batch: BatchItem }) => {
      const availableQty = calculateAvailableQuantity(batch);
      const expiryDate = new Date(batch.expiry_date);
      const status = getStatusColor(expiryDate);

      return (
        <div
          className={`relative mx-4 my-3 bg-white rounded-lg shadow-sm border-l-4 ${status.border}`}
          onClick={() => setSelectedBatch(batch)}
        >
          <div className="p-4">
            {/* Expiry Badge */}
            <div className={`absolute top-3 right-3 ${status.bg} text-white px-3 py-1 rounded-full font-bold text-sm`}>
              {formatExpiryBadge(expiryDate)}
            </div>

            {/* Item Name */}
            <h3 className="text-lg font-medium text-gray-900 pr-20 leading-tight">
              {batch.inventory.item_name}
            </h3>

            {/* Key Info - Side by Side */}
            <div className="mt-3 flex justify-between items-center">
              <div className="text-sm">
                <span className="text-gray-600">Avail: </span>
                <span className="font-medium">
                  {batch.inventory.is_weight_based
                    ? `${Number(availableQty).toFixed(2)}kg`
                    : Math.round(availableQty)}
                </span>
              </div>
              <div className="text-sm">
                <span className="text-gray-600">Exp: </span>
                <span className="font-medium">
                  {format(expiryDate, 'dd.MM.yy')}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="lg:hidden">
        <MobileHeader title="Expiry Calendar" />
        
        <div className="fixed top-14 left-0 right-0 bottom-0 bg-white z-10 overflow-y-auto scrollbar-none">
          <div className="px-4 py-3 bg-white border-b">
            <div className="flex items-center gap-2 mb-2">
              <h1 className="text-xl font-bold">Expiry Calendar</h1>
              <div className="bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full text-sm font-medium">
                {Object.values(filteredBatchesByDate).flat().length} items
              </div>
            </div>
            <div className="flex items-center justify-between">
              <button onClick={prevMonth} className="p-2 hover:bg-gray-100 rounded-full">
                <ChevronLeft className="w-5 h-5 text-gray-600" />
              </button>
              <h2 className="text-lg font-medium text-gray-900">
                {format(currentDate, 'MMMM yyyy')}
              </h2>
              <button onClick={nextMonth} className="p-2 hover:bg-gray-100 rounded-full">
                <ChevronRight className="w-5 h-5 text-gray-600" />
              </button>
            </div>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center h-40">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            </div>
          ) : error ? (
            <div className="text-center text-red-500 p-4">
              Error loading data. Please try again.
            </div>
          ) : (
            <div className="space-y-6">
              {/* Expired Items */}
              {groupedBatches.expired.length > 0 && (
                <div className="mb-8">
                  <GroupHeader
                    title="Expired"
                    count={groupedBatches.expired.length}
                    value={calculateGroupValue(groupedBatches.expired)}
                  />
                  <div>
                    {groupedBatches.expired.map(batch => (
                      <BatchCard key={batch.batch_number} batch={batch} />
                    ))}
                  </div>
                </div>
              )}

              {/* Today's Items */}
              {groupedBatches.today.length > 0 && (
                <div className="mb-8">
                  <GroupHeader
                    title="Expiring Today"
                    count={groupedBatches.today.length}
                    value={calculateGroupValue(groupedBatches.today)}
                  />
                  <div>
                    {groupedBatches.today.map(batch => (
                      <BatchCard key={batch.batch_number} batch={batch} />
                    ))}
                  </div>
                </div>
              )}

              {/* Tomorrow's Items */}
              {groupedBatches.tomorrow.length > 0 && (
                <div className="mb-8">
                  <GroupHeader
                    title="Expiring Tomorrow"
                    count={groupedBatches.tomorrow.length}
                    value={calculateGroupValue(groupedBatches.tomorrow)}
                  />
                  <div>
                    {groupedBatches.tomorrow.map(batch => (
                      <BatchCard key={batch.batch_number} batch={batch} />
                    ))}
                  </div>
                </div>
              )}

              {/* This Week's Items */}
              {groupedBatches.thisWeek.length > 0 && (
                <div className="mb-8">
                  <GroupHeader
                    title="Expiring This Week"
                    count={groupedBatches.thisWeek.length}
                    value={calculateGroupValue(groupedBatches.thisWeek)}
                  />
                  <div>
                    {groupedBatches.thisWeek.map(batch => (
                      <BatchCard key={batch.batch_number} batch={batch} />
                    ))}
                  </div>
                </div>
              )}

              {/* Later Items */}
              {groupedBatches.later.length > 0 && (
                <div className="mb-8 last:mb-0">
                  <GroupHeader
                    title="Later"
                    count={groupedBatches.later.length}
                    value={calculateGroupValue(groupedBatches.later)}
                  />
                  <div>
                    {groupedBatches.later.map(batch => (
                      <BatchCard key={batch.batch_number} batch={batch} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  // Desktop view component
  const DesktopView = () => (
    <div className="hidden lg:block min-h-screen">
      <div className="mb-8 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-2xl font-bold">Expiring Batches Calendar</h1>
          {batches && (
            <div className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium">
              {Object.values(filteredBatchesByDate).flat().length} items
            </div>
          )}
        </div>
        <div className="flex items-center gap-4">
          <button onClick={prevMonth} className="p-2 hover:bg-gray-100 rounded-full">
            <ChevronLeft className="w-5 h-5 text-gray-600" />
          </button>
          <h2 className="text-lg font-medium">
            {format(currentDate, 'MMMM yyyy')}
          </h2>
          <button onClick={nextMonth} className="p-2 hover:bg-gray-100 rounded-full">
            <ChevronRight className="w-5 h-5 text-gray-600" />
          </button>
        </div>
      </div>
      
      <div className="bg-white rounded-lg shadow p-6">
        <div className="grid grid-cols-7 mb-4">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div key={day} className="text-sm text-gray-600">
              {day}
            </div>
          ))}
        </div>
        
        <div className="grid grid-cols-7 gap-1">
          {eachDayOfInterval({
            start: startOfMonth(currentDate),
            end: endOfMonth(currentDate),
          }).map(day => {
            const formattedDate = format(day, 'yyyy-MM-dd')
            const dayBatches = filteredBatchesByDate[formattedDate] || []
            const totalValue = dateValues[formattedDate]

            return (
              <div
                key={formattedDate}
                className={`
                  relative p-2 min-h-[120px] bg-gray-50 border rounded-sm
                  ${dayBatches.length > 0 ? 'ring-1 ring-yellow-200 bg-yellow-50' : ''}
                  ${isSameDay(day, new Date()) ? 'border-blue-500 border-2' : 'border-gray-100'}
                `}
              >
                <div className="flex justify-between items-start">
                  <div className="text-sm font-medium">{format(day, 'd')}</div>
                  {totalValue > 0 && (
                    <div className="text-xs px-1.5 py-0.5 bg-red-500 text-white rounded-sm min-w-[2rem] text-center">
                      {Math.round(totalValue)}
                    </div>
                  )}
                </div>
                
                <div className="mt-2">
                  {dayBatches.slice(0, 3).map((batch) => (
                    <div
                      key={batch.batch_number}
                      className="mt-1 cursor-pointer hover:bg-yellow-100 rounded p-1 transition-colors"
                      onClick={() => setSelectedBatch(batch)}
                    >
                      <div className="text-sm truncate font-medium">{batch.inventory.item_name}</div>
                      <div className="flex items-center justify-between text-xs">
                        <span className="text-gray-600">
                          {(() => {
                            const availableQty = calculateAvailableQuantity(batch);
                            return batch.inventory.is_weight_based
                              ? `${availableQty.toFixed(3)} kg`
                              : `Avail: ${Math.round(availableQty)}`;
                          })()}
                        </span>
                        <span className="text-red-600 font-medium">
                          {(() => {
                            const availableQty = calculateAvailableQuantity(batch);
                            const totalValue = availableQty * batch.purchase_cost;
                            return `CHF ${Math.round(totalValue)}`;
                          })()}
                        </span>
                      </div>
                    </div>
                  ))}
                  {dayBatches.length > 3 && (
                    <div
                      className="mt-1 text-xs text-blue-600 hover:text-blue-800 italic p-1 cursor-pointer"
                      onClick={() => handleShowMore(formattedDate, dayBatches)}
                    >
                      + {dayBatches.length - 3} more items...
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );

  const RemainingBatchesList = useCallback((batch: BatchItem) => (
    <div
      key={batch.batch_number}
      className="mb-3 bg-blue-50 rounded-lg border border-blue-100 overflow-hidden hover:bg-blue-100 cursor-pointer transition-colors"
      onClick={() => {
        setSelectedBatch(batch);
        setRemainingBatches(null);
      }}
    >
      <div className="px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="font-medium text-gray-900">{batch.inventory.item_name}</div>
          <div className="text-sm px-2 py-0.5 bg-red-500 text-white rounded-sm">
            CHF {batch.purchase_cost.toFixed(2)}
          </div>
        </div>
        <div className="mt-2 grid grid-cols-2 gap-4 text-sm">
          <div className="bg-white/50 rounded p-2">
            <span className="text-gray-600">Batch: </span>
            <span className="text-gray-900">{batch.batch_number}</span>
          </div>
          <div className="bg-white/50 rounded p-2">
            <span className="text-gray-600">Available: </span>
            <span className="text-gray-900">
              {(() => {
                const availableQty = calculateAvailableQuantity(batch);
                return batch.inventory.is_weight_based
                  ? `${availableQty.toFixed(3)} kg`
                  : `${Math.round(availableQty)} units`;
              })()}
            </span>
          </div>
        </div>
        <div className="mt-2 text-xs text-gray-600">
          Expires: {format(new Date(batch.expiry_date), 'dd.MM.yyyy')}
        </div>
      </div>
    </div>
  ), []);

  return (
    <>
      {isMobile ? <MobileView /> : <DesktopView />}

      {/* Remaining Batches Dialog */}
      <Dialog open={!!remainingBatches} onOpenChange={(open) => !open && setRemainingBatches(null)}>
        <DialogContent className="max-w-lg">
          <div className="bg-blue-50 -m-6 p-6 border-b mb-6">
            <DialogTitle className="text-lg font-bold text-gray-900">
              Additional Batches
            </DialogTitle>
            <div className="mt-1 text-sm text-gray-600">
              {remainingBatches && format(parseISO(remainingBatches.date), 'MMMM d, yyyy')}
            </div>
            <div className="mt-2 text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full inline-block">
              {remainingBatches?.batches.length} items
            </div>
          </div>
          <button
            onClick={() => setRemainingBatches(null)}
            className="absolute right-4 top-4 rounded-sm opacity-70 hover:opacity-100"
          >
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </button>
          <div className="space-y-2 max-h-[60vh] overflow-y-auto pr-2">
            {remainingBatches?.batches.map(RemainingBatchesList)}
          </div>
        </DialogContent>
      </Dialog>

      {/* Individual Batch Details Dialog */}
      <Dialog open={!!selectedBatch} onOpenChange={() => setSelectedBatch(null)}>
        <DialogContent className="max-w-md">
          {/* Header with Product Info */}
          <div className="mb-6">
            <DialogTitle className="text-lg font-medium text-gray-900 mb-2">
              {selectedBatch?.inventory.item_name}
            </DialogTitle>
            <div className="flex items-center gap-2 text-sm text-gray-600 mb-3">
              <span>Batch: {selectedBatch?.batch_number}</span>
              <span>•</span>
              <span>SKU: {selectedBatch?.sku}</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-sm px-2 py-1 bg-red-500 text-white rounded-sm whitespace-nowrap">
                CHF {(() => {
                  if (!selectedBatch) return '0';
                  const availableQty = calculateAvailableQuantity(selectedBatch);
                  const value = availableQty * (selectedBatch.purchase_cost || 0);
                  return Math.round(value).toLocaleString('en-CH');
                })()}
              </div>
              <div className="text-xs text-gray-500">
                @ CHF {selectedBatch?.purchase_cost}{selectedBatch?.inventory.is_weight_based ? '/kilo' : '/unit'}
              </div>
            </div>
          </div>

          <button
            onClick={() => setSelectedBatch(null)}
            className="absolute right-4 top-4 rounded-sm opacity-70 hover:opacity-100"
          >
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </button>

          <div className="grid grid-cols-2 gap-4">
            {/* Quantities */}
            <div>
              <div className="text-sm text-gray-600">Original Quantity</div>
              <div>
                {selectedBatch?.inventory.is_weight_based
                  ? `${selectedBatch.weight_kilos?.toFixed(3)} kg`
                  : `${selectedBatch?.quantity} units`}
              </div>
            </div>
            <div className="font-medium text-blue-600">
              <div className="text-sm text-gray-600">Available Quantity</div>
              <div>
                {(() => {
                  if (!selectedBatch) return '-';
                  const availableQty = calculateAvailableQuantity(selectedBatch);
                  return selectedBatch.inventory.is_weight_based
                    ? `${availableQty.toFixed(3)} kg`
                    : `${Math.round(availableQty)} units`;
                })()}
              </div>
            </div>

            {/* Dates */}
            <div className="flex flex-col">
              <div className="text-sm text-gray-600">Date Received</div>
              <div>{formatDate(selectedBatch?.date_received)}</div>
            </div>
            <div className="flex flex-col">
              <div className="text-sm text-gray-600">Expiry Date</div>
              <div className="font-medium text-blue-600">{formatDate(selectedBatch?.expiry_date)}</div>
            </div>

            {/* Warehouse at bottom */}
            <div className="col-span-2">
              <div className="text-sm text-gray-600">Warehouse</div>
              <div>{selectedBatch?.warehouse}</div>
            </div>
          </div>

          {/* Expiry Status */}
          <div className="mt-4">
            {selectedBatch && (
              <div className="text-sm font-medium bg-blue-100 text-blue-800 px-3 py-2 rounded-md text-center">
                {getExpiryText(new Date(selectedBatch.expiry_date))}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
