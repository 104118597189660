import { format, parseISO, subMonths, startOfMonth } from 'date-fns';
import type { BatchData } from '@/types/batch';

export interface MonthlyCosts {
  month: string;
  totalCost: number;
  totalInvoiced: number;
}

export interface DeliveryNoteData {
  date: string;
  total_amount: number;
}

function formatMonth(date: Date): string {
  const now = new Date();
  const currentYear = now.getFullYear();
  const dateYear = date.getFullYear();
  
  // For past years, show year
  if (dateYear < currentYear) {
    return format(date, "MMM yy");  // "Sep 24"
  }
  // For current year
  return format(date, "MMM yy");    // "Jan 25"
}

export function calculateMonthlyCosts(
  batches: BatchData[],
  deliveryNotes: DeliveryNoteData[]
): MonthlyCosts[] {
  const today = new Date();
  const monthlyData = new Map<string, { cost: number; invoiced: number }>();

  // Initialize previous 11 months plus current month
  for (let i = 11; i >= 0; i--) {
    const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
    const monthStr = formatMonth(date);
    monthlyData.set(monthStr, { cost: 0, invoiced: 0 });
  }

  // Get the start of 12 months ago for filtering
  const startDate = startOfMonth(subMonths(today, 11));

  batches.forEach(batch => {
    if (!batch.date_received || !batch.purchase_cost) return;

    const receiveDate = parseISO(batch.date_received);
    // Skip batches received before our window
    if (receiveDate < startDate) return;

    const monthStr = formatMonth(receiveDate);
    const currentData = monthlyData.get(monthStr) || { cost: 0, invoiced: 0 };
    
    // Calculate total value using original quantity/weight
    const quantity = batch.inventory?.is_weight_based ? batch.weight_kilos : batch.quantity;
    if (typeof quantity !== 'number') return;
    
    const value = batch.purchase_cost * quantity;

    monthlyData.set(monthStr, {
      ...currentData,
      cost: currentData.cost + value
    });
  });

  // Process delivery notes
  deliveryNotes.forEach(note => {
    if (!note.date) return;

    const noteDate = parseISO(note.date);
    // Skip notes before our window
    if (noteDate < startDate) return;

    const monthStr = formatMonth(noteDate);
    const currentData = monthlyData.get(monthStr) || { cost: 0, invoiced: 0 };

    monthlyData.set(monthStr, {
      ...currentData,
      invoiced: currentData.invoiced + (note.total_amount || 0)
    });
  });

  // Convert map to array and sort chronologically
  const months = Array.from(monthlyData.keys()).sort((a, b) => {
    // Parse month strings back to dates for proper sorting
    const aDate = new Date(today.getFullYear(), today.getMonth() - 11);
    const bDate = new Date(today.getFullYear(), today.getMonth() - 11);
    
    // Try to parse the month and year
    const [aMonth, aYear] = a.split(" ");
    const [bMonth, bYear] = b.split(" ");
    
    if (aMonth && aYear && bMonth && bYear) {
      aDate.setFullYear(2000 + parseInt(aYear));
      bDate.setFullYear(2000 + parseInt(bYear));
      aDate.setMonth(new Date(Date.parse(`${aMonth} 1, 2000`)).getMonth());
      bDate.setMonth(new Date(Date.parse(`${bMonth} 1, 2000`)).getMonth());
    }
    
    return aDate.getTime() - bDate.getTime();
  });

  return months.map(month => {
    const data = monthlyData.get(month) || { cost: 0, invoiced: 0 };
    return {
      month,
      totalCost: data.cost,
      totalInvoiced: data.invoiced
    };
  });
}