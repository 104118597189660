import React from 'react';
import { format, parseISO, differenceInDays } from 'date-fns';
import { cn } from "@/lib/utils";
import { Tag, Thermometer, Store, Package } from 'lucide-react';
import { Badge } from "@/components/ui/badge";
import { useIsMobile } from "@/hooks/use-mobile";

interface ExpiringBatchCardProps {
  batchNumber: string;
  itemName: string;
  expiryDate: string;
  location: string;
  cost: number;
  quantity: number;
  isWeightBased?: boolean;
  className?: string;
  sku: string;
}

const formatQuantity = (qty: number, isWeightBased?: boolean): string => {
  return isWeightBased 
    ? `${qty.toFixed(3)} kg`
    : `${Math.round(qty)} unit${qty === 1 ? '' : 's'}`;
};

const getWarehouseIcon = (location: string) => {
  if (location.includes('°C')) return Thermometer;
  if (location === 'Paradiso') return Store;
  return Package;
};

const getDaysUntilExpiry = (date: Date): number => {
  const today = new Date();
  return differenceInDays(date, today);
};

const getBackgroundColor = (daysUntilExpiry: number): string => {
  if (daysUntilExpiry <= 30) {
    return "bg-amber-50/70";
  }
  return "bg-emerald-50/50";
};

export function ExpiringBatchCard({
  batchNumber,
  itemName,
  expiryDate,
  location,
  cost,
  quantity,
  isWeightBased,
  className = '',
  sku
}: ExpiringBatchCardProps) {
  const parsedExpiryDate = parseISO(expiryDate);
  const daysUntilExpiry = getDaysUntilExpiry(parsedExpiryDate);
  const WarehouseIcon = getWarehouseIcon(location);
  const isMobile = useIsMobile();
  const totalValue = cost * quantity;
  
  return (
    <div className={cn(
      "relative rounded-lg border p-3 transition-all",
      getBackgroundColor(daysUntilExpiry),
      "hover:bg-opacity-75",
      className
    )}>
      {/* Product name with truncation */}
      <div 
        className="text-sm font-medium text-gray-900 truncate mb-2"
        title={itemName}
      >
        {itemName}
      </div>

      {/* Middle row with expiry badge and warehouse */}
      <div className="flex items-center gap-4 mb-2 flex-wrap">
        <div className="flex items-center gap-2 shrink-0">
          <span className="text-sm text-gray-600">Days til Expiry:</span>
          <Badge 
            className={cn(
              "h-6 px-2.5 font-bold shrink-0",
              daysUntilExpiry <= 30
                ? "bg-amber-500 hover:bg-amber-500"
                : "bg-green-500 hover:bg-green-500"
            )}
          >
            {daysUntilExpiry}
          </Badge>
        </div>

        <div className="flex items-center gap-1.5 text-sm text-muted-foreground ml-auto">
          <WarehouseIcon className="h-4 w-4 shrink-0" />
          <span>{location}</span>
        </div>
      </div>

      {/* Bottom row */}
      <div className="flex items-center gap-4 flex-wrap">
        <div className="flex items-center gap-1.5 min-w-0">
          <Tag className="h-3.5 w-3.5 text-muted-foreground shrink-0" />
          <span className="text-sm text-gray-900 truncate">{batchNumber}</span>
          <span className="text-sm text-gray-500 truncate">(SKU: {sku})</span>
        </div>
        
        <div className="flex items-center gap-2 ml-auto text-sm shrink-0">
          <div className="font-medium text-blue-600">
            CHF {Math.round(totalValue).toLocaleString()}
            <span className="text-gray-500 text-xs ml-1">
              ({formatQuantity(quantity, isWeightBased)})
            </span>
          </div>
          <div className="text-xs text-muted-foreground">
            Exp: {format(parsedExpiryDate, 'dd.MM.yyyy')}
          </div>
        </div>
      </div>
    </div>
  );
}
