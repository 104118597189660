import React from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { addMonths, format } from 'date-fns';
import type { BatchData } from '@/types/batch';
import { calculateAvailable } from '@/utils/batchUtils';

interface ExpiryTimelineChartProps {
  batches: BatchData[];
  className?: string;
}

interface DataPoint {
  month: string;
  value: number;
  x: number;
  y: number;
}

interface GridLine {
  x1: number;
  x2: number;
  y: number;
}

function calculateBatchValue(batch: BatchData): number {
  // Skip test batches
  if (batch.batch_number.toLowerCase().includes('test')) {
    return 0;
  }

  if (!batch.purchase_cost) {
    return 0;
  }

  const available = calculateAvailable(batch);
  if (available <= 0) {
    return 0;
  }

  return available * batch.purchase_cost;
}

export function ExpiryTimelineChart({ batches, className = '' }: ExpiryTimelineChartProps) {
  // Generate next 6 months
  const months = Array.from({ length: 6 }, (_, i) => {
    const date = addMonths(new Date(), i);
    return format(date, 'MMM yyyy');
  });

  // Calculate expiring value per month
  const monthlyData = months.map(month => {
    const monthBatches = batches.filter(batch => 
      format(new Date(batch.expiry_date), 'MMM yyyy') === month
    );

    const value = monthBatches.reduce((sum, batch) => {
      const batchValue = calculateBatchValue(batch);
      if (batchValue > 0) {
        console.debug(`${month} - ${batch.batch_number}: CHF ${Math.round(batchValue)}`);
      }
      return sum + batchValue;
    }, 0);

    console.debug(`Total for ${month}: CHF ${Math.round(value)}`);
    
    return {
      month,
      value: Math.round(value),
      x: 0,
      y: 0,
    };
  }).filter(data => data.value > 0); // Only include months with values

  const [points, setPoints] = React.useState<DataPoint[]>([]);
  const [gridLines, setGridLines] = React.useState<GridLine[]>([]);
  const svgRef = React.useRef<SVGSVGElement>(null);

  React.useEffect(() => {
    if (!svgRef.current || monthlyData.length === 0) return;

    const svg = svgRef.current;
    const width = svg.clientWidth;
    const height = svg.clientHeight;
    const padding = { left: 50, right: 50, top: 30, bottom: 30 };

    // Calculate grid lines
    const lines: GridLine[] = Array.from({ length: 5 }).map((_, i) => ({
      x1: padding.left,
      x2: width - padding.right,
      y: padding.top + i * 50
    }));
    setGridLines(lines);

    const maxValue = Math.max(...monthlyData.map(d => d.value));
    const xStep = (width - padding.left - padding.right) / (monthlyData.length - 1);

    const updatedPoints = monthlyData.map((point, i) => ({
      ...point,
      x: padding.left + i * xStep,
      y: height - padding.bottom - ((point.value / maxValue) * (height - padding.top - padding.bottom))
    }));

    setPoints(updatedPoints);
  }, [monthlyData, svgRef.current?.clientWidth, svgRef.current?.clientHeight]);

  if (monthlyData.length === 0) {
    return (
      <Card className={className}>
        <CardContent className="pt-6">
          <div className="text-sm font-semibold mb-4">Value of Expiring Items (6 months)</div>
          <div className="h-[250px] flex items-center justify-center text-sm text-muted-foreground">
            No expiring items found in the next 6 months
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={className}>
      <CardContent className="p-6">
        <div className="text-sm font-semibold mb-4">Value of Expiring Items (6 months)</div>
        <div className="relative h-[250px] w-full">
          <svg
            ref={svgRef}
            className="w-full h-full"
            style={{ overflow: 'visible' }}
          >
            {/* Grid lines - using calculated positions */}
            {gridLines.map((line, i) => (
              <line
                key={i}
                x1={line.x1}
                y1={line.y}
                x2={line.x2}
                y2={line.y}
                stroke="#f3f4f6"
                strokeWidth="1"
              />
            ))}

            {/* Line */}
            {points.length > 1 && (
              <path
                d={`M ${points.map(p => `${p.x},${p.y}`).join(' L ')}`}
                fill="none"
                stroke="rgb(59, 130, 246)"
                strokeWidth="2"
                className="transition-all duration-300"
              />
            )}

            {/* Data points and values */}
            {points.map((point, i) => (
              <g key={i}>
                <circle
                  cx={point.x}
                  cy={point.y}
                  r="4"
                  fill="white"
                  stroke="rgb(59, 130, 246)"
                  strokeWidth="2"
                  className="transition-all duration-300"
                />
                
                {/* Value label */}
                <text
                  x={point.x}
                  y={point.y - 15}
                  textAnchor="middle"
                  className="text-xs font-medium fill-gray-700"
                >
                  CHF {point.value.toLocaleString()}
                </text>
                
                {/* X-axis labels */}
                <text
                  x={point.x}
                  y={220}
                  textAnchor="middle"
                  className="text-xs fill-gray-500"
                >
                  {format(new Date(point.month), 'MMM')}
                </text>
              </g>
            ))}
          </svg>
        </div>
      </CardContent>
    </Card>
  );
}