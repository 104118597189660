import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { RainbowButton } from '@/components/ui/rainbow-button';
import Login from './Login';

export default function Home() {
  const [showSignIn, setShowSignIn] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleGetStarted = () => {
    if (user) {
      navigate('/inventory');
    } else {
      setShowSignIn(true);
    }
  };

  return (
    <div className="min-h-screen bg-[#304661] text-white overflow-x-hidden">
      {/* Header */}
      <header className="container mx-auto px-4 py-6 flex justify-center items-center max-w-full">
        <img src="/ims_logoc.png" alt="Chefland Logo" className="h-16 sm:h-20" />
      </header>

      {/* Hero Section */}
      <main className="container mx-auto px-3 py-6 sm:px-4 sm:py-12 text-center max-w-4xl">
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
          Chefland Inventory
          <span className="block text-[#99AE4C]">Management</span>
        </h1>
        <p className="text-lg sm:text-xl mb-8 sm:mb-12 max-w-3xl mx-auto">
          A modern IMS designed especially for food service operations.
        </p>
        <div className="flex gap-3 sm:gap-4 justify-center">
          <RainbowButton
            onClick={handleGetStarted}
            className="h-10 sm:h-12 text-base sm:text-lg min-w-[160px]"
          >
            Get Started
          </RainbowButton>
          <RainbowButton
            onClick={() => navigate('/about')}
            className="h-10 sm:h-12 text-base sm:text-lg min-w-[160px] !text-[#C3D48A]"
          >
            More Info
          </RainbowButton>
        </div>
      </main>

      {/* Welcome Image Section */}
      <section className="container mx-auto px-4 py-12">
        <div className="rounded-lg overflow-hidden mb-8 shadow-xl max-w-3xl mx-auto">
          <img src="/welcome2.jpg" alt="Welcome" className="w-full object-cover" />
        </div>
        <div className="flex flex-col items-center gap-4">
          <br></br>
          <img src="/chefland2.png" alt="Chefland" className="h-8 sm:h-7" />
        </div>
      </section>

      {/* Sign In Modal */}
      {showSignIn && (
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50 p-4">
          <div className="relative">
            <Login />
          </div>
        </div>
      )}
    </div>
  );
}
