import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";
import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/lib/supabase";
import JSZip from "jszip";

/**
 * Creates a CSV string with proper UTF-8 encoding and BOM
 */
function createCsvString(headers: string[], rows: string[][]): string {
  // UTF-8 BOM
  const BOM = '\ufeff';
  
  // Convert rows to CSV strings with proper escaping
  const csvRows = rows.map(row => 
    row.map(field => {
      if (field === null || field === undefined) return '';
      const stringField = String(field);
      if (stringField.includes('"') || stringField.includes(',') || stringField.includes('\n')) {
        return `"${stringField.replace(/"/g, '""')}"`;
      }
      return stringField;
    }).join(',')
  );

  // Combine headers and rows
  return BOM + [headers.join(','), ...csvRows].join('\n');
}

export function ExportTablesButton() {
  const { toast } = useToast();
  const [showBackupModal, setShowBackupModal] = useState(false);
  
  const handleExportIndividual = async () => {
    try {
      // Fetch data from all tables
      const [inventoryResult, batchesResult, invoicesResult, inventoryItemsResult, invoiceItemsResult, usersResult] = await Promise.all([
        supabase.from('inventory').select('*').order('item_name'),
        supabase.from('batches').select(`
          *,
          inventory:inventory(is_weight_based),
          invoice_items:invoice_items(
            weight_kilos,
            quantity,
            invoice:invoices(status)
          )
        `).order('batch_number'),
        supabase.from('invoices').select(`
          *,
          invoice_items (
            id,
            quantity,
            weight_kilos,
            measurement_type,
            batch_number,
            unit_price
          )
        `).order('date', { ascending: false }),
        supabase.from('inventory').select('sku, item_name'),
        supabase.from('invoice_items').select('*').order('invoice_id'),
        supabase.from('authorized_users').select('email, role, is_active, display_name').order('email')
      ]);
  
      if (inventoryResult.error) throw inventoryResult.error;
      if (batchesResult.error) throw batchesResult.error;
      if (invoicesResult.error) throw invoicesResult.error;
      if (inventoryItemsResult.error) throw inventoryItemsResult.error;
      if (invoiceItemsResult.error) throw invoiceItemsResult.error;
      if (usersResult.error) throw usersResult.error;
  
      const inventory = inventoryResult.data;
      const batches = batchesResult.data;
      const invoices = invoicesResult.data;
      
      // Create a map of SKUs to item names
      const skuToItemName = new Map(
        inventoryItemsResult.data.map(item => [item.sku, item.item_name])
      );
  
      // Convert inventory to CSV
      const inventoryHeaders = ['sku', 'item_name', 'description', 'vat_rate', 'is_weight_based'];
      const inventoryRows = inventory.map(item => [
        item.sku,
        item.item_name,
        item.description || '',
        item.vat_rate,
        item.is_weight_based
      ]);
      const inventoryCsv = createCsvString(inventoryHeaders, inventoryRows);
  
      // Convert batches to CSV
      const batchesHeaders = ['batch_number', 'sku', 'original_quantity', 'original_weight_kilos', 'available_amount', 'expiry_date', 'date_received', 'purchase_cost', 'warehouse', 'base_id', 'shrinkage_reason'];
      const batchesRows = batches.map(batch => {
        // Calculate real-time availability
        const isWeightBased = batch.inventory?.is_weight_based;
        const original = isWeightBased ? batch.weight_kilos : batch.quantity;
        const used = (batch.invoice_items || [])
          .filter(item => item.invoice?.status === 'final')
          .reduce((sum, item) =>
            sum + (isWeightBased ? (item.weight_kilos || 0) : (item.quantity || 0)), 0);
        const available = original - used;
  
        return [
          batch.batch_number,
          batch.sku,
          !isWeightBased ? (batch.quantity || '') : '',
          isWeightBased ? (batch.weight_kilos || '') : '',
          isWeightBased ? `${available} kg` : available,
          batch.expiry_date,
          batch.date_received,
          batch.purchase_cost,
          batch.warehouse || 'Consignment',
          batch.base_id || '',
          batch.shrinkage_reason || ''
        ];
      });
      const batchesCsv = createCsvString(batchesHeaders, batchesRows);
  
      // Convert invoices to CSV
      const invoicesHeaders = ['invoice_number', 'date', 'company', 'notes', 'status', 'total_amount', 'items'];
      const invoicesRows = invoices.map(invoice => {
        // Get unique item names for this invoice
        const itemNames = new Set<string>();
        const quantities: string[] = [];
  
        invoice.invoice_items?.forEach(item => {
          const itemName = skuToItemName.get(
            batches.find(b => b.batch_number === item.batch_number)?.sku || ''
          );
          if (itemName) {
            itemNames.add(itemName);
          }
  
          if (item.measurement_type === 'weight' && item.weight_kilos) {
            quantities.push(`${item.weight_kilos}kg`);
          } else if (item.quantity) {
            quantities.push(`${item.quantity}`);
          }
        });
  
        const itemsList = Array.from(itemNames).join(', ');
        const totalAmount = quantities.join(', ');
  
        return [
          invoice.invoice_number,
          invoice.date,
          invoice.company,
          invoice.notes || '',
          invoice.status,
          totalAmount,
          itemsList
        ];
      });
      const invoicesCsv = createCsvString(invoicesHeaders, invoicesRows);
  
      // Convert invoice_items to CSV
      const invoiceItemsHeaders = ['id', 'invoice_id', 'batch_number', 'sku', 'weight_kilos', 'quantity', 'unit_price', 'warehouse'];
      const invoiceItemsRows = invoiceItemsResult.data.map(item => [
        item.id,
        item.invoice_id,
        item.batch_number,
        item.sku,
        item.weight_kilos || '',
        item.quantity || '',
        item.unit_price || '',
        item.warehouse || ''
      ]);
      const invoiceItemsCsv = createCsvString(invoiceItemsHeaders, invoiceItemsRows);
  
      // Convert authorized_users to CSV
      const usersHeaders = ['email', 'role', 'is_active', 'display_name'];
      const usersRows = usersResult.data.map(user => [
        user.email,
        user.role,
        user.is_active,
        user.display_name || ''
      ]);
      const usersCsv = createCsvString(usersHeaders, usersRows);
  
      // Create ZIP file with proper encoding
      const zip = new JSZip();
      const currentDate = new Date().toISOString().split('T')[0];
      
      zip.file(`inventory_${currentDate}.csv`, inventoryCsv);
      zip.file(`batches_${currentDate}.csv`, batchesCsv);
      zip.file(`invoices_${currentDate}.csv`, invoicesCsv);
      zip.file(`invoice_items_${currentDate}.csv`, invoiceItemsCsv);
      zip.file(`users_${currentDate}.csv`, usersCsv);
      
      const zipBlob = await zip.generateAsync({ type: "blob" });
      
      // Trigger download
      const url = window.URL.createObjectURL(zipBlob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `ims_export_${currentDate}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast({
        title: "Export successful",
        description: `Exported all tables to ZIP file`
      });
    } catch (error: any) {
      console.error('Export failed:', error);
      toast({
        title: "Export failed",
        description: error.message,
        variant: "destructive"
      });
    }
  };
  
  const handleExportTransaction = async () => {
    try {
      // Query batches and invoices for transaction backup with full row count
      const [batchesResult, invoicesResult] = await Promise.all([
        supabase.from('batches').select('*', { head: false, count: "exact" }),
        supabase.from('invoices').select(`
          *,
          invoice_items (
            id,
            quantity,
            weight_kilos,
            measurement_type,
            batch_number,
            unit_price
          )
        `, { head: false, count: "exact" }).range(0, 1000)
      ]);
      if (batchesResult.error) throw batchesResult.error;
      if (invoicesResult.error) throw invoicesResult.error;
  
      const batches = batchesResult.data;
      const invoices = invoicesResult.data;
      
      // Build consolidated transactions array with union of both types
      const transactions = [];
  
      // Process batches as inbound/shrinkage transactions
      batches.forEach(batch => {
        const transactionType = batch.shrinkage_reason ? "shrinkage" : "inbound";
        transactions.push({
          transaction_type: transactionType,
          transaction_id: batch.batch_number,
          date: batch.date_received,
          sku: batch.sku,
          quantity: batch.quantity || '',
          weight_kilos: batch.weight_kilos || '',
          expiry_date: batch.expiry_date,
          purchase_cost: batch.purchase_cost,
          warehouse: batch.warehouse || '',
          base_id: batch.base_id || '',
          shrinkage_reason: batch.shrinkage_reason || '',
          company: '',
          notes: '',
          status: '',
          total_amount: '',
          items: ''
        });
      });
  
      // Create a mapping from batch_number to sku from batches for lookup in invoices
      const batchMap = new Map();
      batches.forEach(batch => {
        if (batch.batch_number && batch.sku) {
          batchMap.set(batch.batch_number, batch.sku);
        }
      });
      
      // Process invoices as outbound transactions - using float conversion for accuracy
      invoices.forEach(invoice => {
        let itemsCount = 0;
        let totalAmt = 0;
        let itemsList = '';
        if (invoice.invoice_items && Array.isArray(invoice.invoice_items)) {
          itemsCount = invoice.invoice_items.length;
          itemsList = invoice.invoice_items
            .map(i => batchMap.get(i.batch_number) || '')
            .filter(x => x !== '')
            .join(', ');
          totalAmt = invoice.invoice_items.reduce((acc, curr) => {
            const qty = curr.measurement_type === 'weight'
              ? (parseFloat(curr.weight_kilos) || 0)
              : (parseFloat(curr.quantity) || 1);
            const price = parseFloat(curr.unit_price) || 0;
            return acc + (price * qty);
          }, 0);
        }
        transactions.push({
          transaction_type: "outbound",
          transaction_id: invoice.invoice_number,
          date: invoice.date,
          sku: '',
          quantity: '',
          weight_kilos: '',
          expiry_date: '',
          purchase_cost: '',
          warehouse: '',
          base_id: '',
          shrinkage_reason: '',
          company: invoice.company,
          notes: invoice.notes || '',
          status: invoice.status || 'N/A',
          total_amount: totalAmt.toFixed(2),
          items: `${itemsCount} items`
        });
      });
  
      // Define CSV headers and generate CSV string
      const csvHeaders = ["transaction_type", "transaction_id", "date", "sku", "quantity", "weight_kilos", "expiry_date", "purchase_cost", "warehouse", "base_id", "shrinkage_reason", "company", "notes", "status", "total_amount", "items"];
      const csvRows = transactions.map(tx => [
        tx.transaction_type,
        tx.transaction_id,
        tx.date,
        tx.sku,
        tx.quantity,
        tx.weight_kilos,
        tx.expiry_date,
        tx.purchase_cost,
        tx.warehouse,
        tx.base_id,
        tx.shrinkage_reason,
        tx.company,
        tx.notes,
        tx.status,
        tx.total_amount,
        tx.items
      ]);
      const csvString = createCsvString(csvHeaders, csvRows);
  
      // Trigger CSV download
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      const currentDate = new Date().toISOString().split('T')[0];
      link.setAttribute('download', `transaction_backup_${currentDate}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      toast({
        title: "Transaction Backup Successful",
        description: "Transaction backup CSV exported."
      });
  
      setShowBackupModal(false);
    } catch (error: any) {
      console.error('Transaction backup export failed:', error);
      toast({
        title: "Export failed",
        description: error.message,
        variant: "destructive"
      });
    }
  };
  
  return (
    <>
      {!showBackupModal && (
        <Button
          variant="outline"
          size="sm"
          className="gap-2"
          onClick={() => setShowBackupModal(true)}
        >
          <Download className="h-4 w-4" />
          Backup Tables
        </Button>
      )}
      {showBackupModal && (
        <div className="modal-overlay" style={{
            position: 'fixed', top: 0, left: 0, right: 0, bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex', alignItems: 'center', justifyContent: 'center',
            zIndex: 1000
          }}>
          <div className="modal-content" style={{
            backgroundColor: '#fff', padding: '20px', borderRadius: '8px',
            minWidth: '300px'
          }}>
            <h2 style={{ marginBottom: '10px' }}>Backup Options</h2>
            <p style={{ marginBottom: '20px' }}>Please choose your backup method:</p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '20px' }}>
              <Button onClick={handleExportIndividual} style={{ padding: '10px' }}>
                Individual Tables Backup
              </Button>
              <Button onClick={handleExportTransaction} style={{ padding: '10px' }}>
                Transaction Backup
              </Button>
              <Button onClick={() => setShowBackupModal(false)} style={{ padding: '10px' }}>
                Cancel
              </Button>
            </div>
            <p style={{ fontSize: '12px', color: '#666', textAlign: 'right', marginTop: '20px' }}>
              Last updated: {new Date().toLocaleString()}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
