import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ExpiringBatchCard } from './ExpiringBatchCard';
import { startOfDay, isAfter, differenceInDays } from 'date-fns';
import type { BatchData } from '@/types/batch';
import { useIsMobile } from "@/hooks/use-mobile";
import { cn } from "@/lib/utils";
import { calculateAvailable } from '@/utils/batchUtils';

interface BatchListProps {
  batches: BatchData[];
  className?: string;
}

export function ExpiringBatchList({ batches, className = '' }: BatchListProps) {
  const today = startOfDay(new Date());
  const isMobile = useIsMobile();

  // Filter and sort batches - show items expiring within 60 days
  const upcomingBatches = batches
    .filter(batch => {
      const expiryDate = new Date(batch.expiry_date);
      const daysUntil = differenceInDays(expiryDate, today);
      const available = calculateAvailable(batch);
      return isAfter(expiryDate, today) && daysUntil <= 90 && available > 0;
    })
    .sort((a, b) => {
      // Sort by expiry date
      return new Date(a.expiry_date).getTime() - new Date(b.expiry_date).getTime();
    });

  return (
    <Card className={cn(
      className,
      isMobile && "rounded-none border-x-0 first:border-t-0"
    )}>
      <CardHeader className="py-3 px-4">
        <CardTitle className="text-sm font-semibold flex items-baseline gap-2">
          <span>Next Expiring Batches</span>
          <span className="text-xs font-normal text-muted-foreground">
            {upcomingBatches.length} items
          </span>
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        <div className="px-4 pb-3 h-[515px] overflow-y-auto">
          <div className="space-y-2 pr-1">
            {upcomingBatches.map((batch) => (
              <ExpiringBatchCard
                key={batch.batch_number}
                batchNumber={batch.batch_number}
                itemName={batch.inventory.item_name}
                expiryDate={batch.expiry_date}
                location={batch.warehouse}
                cost={batch.purchase_cost}
                quantity={calculateAvailable(batch)}
                isWeightBased={batch.inventory.is_weight_based}
                sku={batch.sku}
              />
            ))}
            {upcomingBatches.length === 0 && (
              <div className="text-center py-8 text-muted-foreground">
                No batches expiring within 90 days
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}