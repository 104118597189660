import React from 'react';
import { useIsMobile } from "@/hooks/use-mobile";
import { ExpiryCard } from "./ExpiryCard";
import { cn } from "@/lib/utils";

export interface ExpiryMetric {
  range: string;
  value: number;
  count: number;
}

interface ExpiryMetricsGridProps {
  metrics: ExpiryMetric[];
  className?: string;
}

const getVariant = (range: string) => {
  switch (range) {
    case 'expired':
      return { variant: 'expired' as const, title: 'Expired' };
    case '30 days':
      return { variant: '30-days' as const, title: 'Expiring < 30 Days' };
    case '60 days':
      return { variant: '60-days' as const, title: 'Expiring 31-60 Days' };
    case '61+ days':
      return { variant: '61-plus' as const, title: 'Expiring 61+ Days' };
    default:
      return { variant: '61-plus' as const, title: range };
  }
};

export function ExpiryMetricsGrid({ metrics, className }: ExpiryMetricsGridProps) {
  const isMobile = useIsMobile();

  // Sort in order: expired, 30, 60, 61+
  const sortOrder = ['expired', '30 days', '60 days', '61+ days'];
  const sortedMetrics = [...metrics].sort((a, b) => 
    sortOrder.indexOf(a.range) - sortOrder.indexOf(b.range)
  );

  return (
    <div className={cn(
      "grid gap-3",
      isMobile ? "grid-cols-2" : "grid-cols-4",
      className
    )}>
      {sortedMetrics.map(metric => {
        const { variant, title } = getVariant(metric.range);
        return (
          <ExpiryCard
            key={metric.range}
            title={title}
            value={metric.value}
            count={metric.count}
            variant={variant}
          />
        );
      })}
    </div>
  );
}