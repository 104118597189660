import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { subDays, format } from 'date-fns';
import { ImportService } from '@/services/importService';
import type { DeliveryNote } from '@/types/delivery-note';
import { fetchDeliveryNotes } from '@/services/apiService';
import { ImportStatus } from '@/types/delivery-note';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { AlertTriangle } from 'lucide-react';
import type { UnimportedDeliveryNote } from '@/types/delivery-note';

const fetchUnimportedNotes = async (startDate: string): Promise<UnimportedDeliveryNote[]> => {
  try {
    const response = await fetchDeliveryNotes(
      startDate,
      format(new Date(), 'yyyy-MM-dd')
    );
    
    // Filter and transform the notes
    if (!response || !response.items) {
      throw new Error('Invalid response from API');
    }

    const notes = (response.items as DeliveryNote[]).filter((note) => {
      const noteDate = new Date(note.date);
      return noteDate >= new Date(startDate) && noteDate <= new Date();
    });

    // Check which notes are already imported
    const existingStatus = await Promise.all(
      notes.map((note: DeliveryNote) => ImportService.checkExistingInvoice(note.number))
    );

    // Return only unimported notes
    return notes
      .filter((_: DeliveryNote, index: number) => !existingStatus[index])
      .map((note: DeliveryNote) => ({
        id: note.id.toString(),
        date: new Date(note.date),
        company: note.contact?.name || 'Unknown',
        amount: parseFloat(note.total),
        noteNumber: note.number,
        isImported: false
      }));
  } catch (error) {
    console.error('Error fetching unimported notes:', error);
    throw error;
  }
};

export const UnimportedBadge = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleNoteClick = (note: UnimportedDeliveryNote) => {
    toast({
      title: "Opening Import Interface",
      description: `Preparing to import note #${note.noteNumber}`,
      duration: 2000
    });
    setIsOpen(false);
    navigate(`/delivery-notes?noteNumber=${note.noteNumber}`);
  };

  const { data: unimportedNotes, isLoading, error, refetch } = useQuery({
    queryKey: ['unimported-notes'],
    queryFn: async () => {
      const thirtyDaysAgo = subDays(new Date(), 30);
      const formattedDate = format(thirtyDaysAgo, 'yyyy-MM-dd');
      return fetchUnimportedNotes(formattedDate);
    },
    refetchInterval: 5 * 60 * 1000, // Refetch every 5 minutes
  });

  const hasUnimported = (unimportedNotes?.length ?? 0) > 0;

  React.useEffect(() => {
    if (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch unimported delivery notes",
      });
    }
  }, [error, toast]);

  const NotesGrid = () => {
    if (isLoading) {
      return (
        <div className="space-y-4">
          {[1, 2, 3].map((i) => (
            <div key={i} className="p-4 border rounded-lg">
              <Skeleton className="h-4 w-1/3 mb-2" />
              <Skeleton className="h-4 w-1/2" />
            </div>
          ))}
        </div>
      );
    }

    if (error) {
      return (
        <Alert variant="destructive">
          <AlertTriangle className="h-4 w-4" />
          <AlertDescription>
            Failed to load unimported delivery notes.
            <Button 
              variant="outline" 
              size="sm" 
              className="ml-2"
              onClick={() => refetch()}
            >
              Retry
            </Button>
          </AlertDescription>
        </Alert>
      );
    }

    if (!unimportedNotes?.length) {
      return (
        <div className="text-center py-6 text-gray-500">
          No unimported delivery notes found
        </div>
      );
    }

    return (
      <div className="grid gap-1.5 mb-2">
        {unimportedNotes.map((note) => (
          <div
            key={note.id}
            onClick={() => handleNoteClick(note)}
            className="group bg-white rounded-lg shadow-sm p-2.5 border border-gray-100
                     hover:border-blue-200 hover:shadow transition-all duration-200
                     cursor-pointer relative"
          >
            <div className="absolute right-2 top-2 bg-blue-100 text-blue-700 px-2 py-0.5 rounded-full text-xs font-medium opacity-0 group-hover:opacity-100 transition-opacity">
              Click to import
            </div>
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-semibold text-gray-900">
                  {note.company}
                </h3>
                <p className="text-sm text-gray-500 flex items-center gap-1.5">
                  <span className="inline-block w-2 h-2 rounded-full bg-blue-200"></span>
                  {format(note.date, 'PPP')}
                </p>
              </div>
              <div className="text-right">
                <p className="font-semibold text-blue-900">
                  CHF {note.amount.toFixed(2)}
                </p>
                <p className="text-sm text-gray-500 font-medium">
                  #{note.noteNumber}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {hasUnimported && (
        <div 
          onClick={() => setIsOpen(true)}
          className={`
            relative overflow-hidden
            bg-blue-50 text-blue-700 rounded-full px-4 py-1.5
            ring-1 ring-blue-100/50
            flex items-center gap-1.5 cursor-pointer shadow-sm
            hover:bg-blue-100 transition-all duration-300
            before:absolute before:inset-0
            before:bg-gradient-to-r before:from-transparent
            before:via-white/10 before:to-transparent
            before:translate-x-[-100%]
            hover:before:animate-shimmer
            ${isLoading ? 'opacity-50' : 'animate-subtle-pulse'}
          `}
        >
          <span className="text-lg font-bold text-blue-700">
            {isLoading ? '...' : unimportedNotes.length}
          </span>
          <span className="text-xs font-medium">
            unimported
          </span>
        </div>
      )}

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-2xl bg-gradient-to-b from-gray-50 to-gray-50/95 border border-gray-200/50">
          <DialogHeader className="pb-3 border-b border-gray-200/75">
            <DialogTitle className="text-lg text-blue-900 font-semibold">
              Unimported Delivery Notes - Small Invoice
            </DialogTitle>
          </DialogHeader>
          <ScrollArea className="max-h-[70vh] px-3 py-1.5">
            <NotesGrid />
          </ScrollArea>
          <div className="px-4 py-3 border-t border-gray-200/75">
            <div className="text-sm text-blue-700 bg-blue-50/50 rounded-lg p-3 flex items-center gap-2">
              <div className="flex-shrink-0 w-2 h-2 bg-blue-400 rounded-full animate-pulse" />
              <span>Click any note to start importing</span>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};