import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from '@/contexts/AuthContext';
import { NotificationsProvider } from '@/contexts/NotificationsContext';
import { Toaster } from '@/components/ui/toaster';
import Routes from './Routes';
import { GoogleOAuthProvider } from '@react-oauth/google';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      gcTime: 10 * 60 * 1000,
      refetchOnMount: "always",
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      retry: 3
    }
  }
});

export default function App() {
  return (
    <Router>
      <GoogleOAuthProvider 
        clientId="401566290452-dmiui1tph39v4gqe2uuvq1avd498khmd.apps.googleusercontent.com"
      >
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <NotificationsProvider>
              <Routes />
              <Toaster />
            </NotificationsProvider>
          </AuthProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </Router>
  );
}