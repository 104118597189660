import React, { useState, useEffect, useCallback } from 'react';
import { Button } from './ui/button';
import { useToast } from './ui/use-toast';
import Logger from '../utils/logger';
import { CreateProductFromDNDialog } from './inventory/CreateProductFromDNDialog';
import { BatchMatchPanel } from './batches/BatchMatchPanel';
import { DeliveryNote, ValidatedPosition, ImportStatus, ValidatedDeliveryNote, BatchConfidence } from '../types/delivery-note';
import { ImportService } from '../services/importService';
import { validatePosition, transformDeliveryNote } from '../utils/validation';
import { Loader2 } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { ProgressIndicator } from './ui/progress-indicator';
import { getAvailableQuantity } from '../utils/batchUtils';

interface DeliveryNoteDetailsProps {
    note: ValidatedDeliveryNote;
    onNext: () => void;
    isLast: boolean;
    onImport: () => void;
    onValidationChange?: (positions: ValidatedPosition[]) => void;
}

export default function DeliveryNoteDetails({ 
    note, 
    onNext, 
    isLast, 
    onImport, 
    onValidationChange
}: DeliveryNoteDetailsProps) {
    const [validatedPositions, setValidatedPositions] = useState<ValidatedPosition[]>(note.validatedPositions || []);
    const [availableBatches, setAvailableBatches] = useState<Record<string, any[]>>({});
    const [loading, setLoading] = useState(false);
    const [currentItem, setCurrentItem] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const { toast } = useToast();
    const [isCreateProductOpen, setIsCreateProductOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<{sku: string, name: string, vat: number} | null>(null);

    const updatePositions = useCallback((positions: ValidatedPosition[]) => {
        setValidatedPositions(positions);
        if (onValidationChange) {
            onValidationChange(positions);
        }
    }, [onValidationChange]);

    const validateNotePositions = useCallback(async (preserveState = false) => {
        setLoading(true);
        try {
            // Filter valid positions first
            const positions = note.positions.filter(pos => pos.number && pos.catalog_type !== 'S');
            setTotalItems(positions.length);
            setCurrentItem(0);

            // Get current states if preserving
            const currentStates = preserveState ?
                // Create state map using position index
                validatedPositions.reduce((acc, pos, idx) => {
                    acc[idx] = {
                        skipped: pos.skipped || false,
                        batchMatch: pos.batchMatch, // Store complete batch match object
                        skuExists: pos.skuExists
                    };
                    // Debug state storage
                    console.log('Storing state:', {
                        sku: pos.position.number,
                        idx: idx,
                        match: pos.batchMatch.matchedBatch
                    });
                    return acc;
                }, {} as Record<string, any>)
                : {};

            // Check for existing invoice
            const exists = await ImportService.checkExistingInvoice(note.number);
            if (exists) {
                toast({
                    title: "⚠️ Note Already Imported",
                    description: "This delivery note has already been imported. Reimporting will update the existing invoice.",
                    variant: "default"
                });
            }

            // Process all positions at once
            const validations = await Promise.all(positions.map(async (position, index) => {
                setCurrentItem(index + 1);
                const validated = await validatePosition(position);
                // Get current state using index since _idx might not be set yet
                const currentState = currentStates[index];
                
                const preservedState = {
                    ...validated,
                    _idx: index, // Always use sequential index
                    skipped: currentState?.skipped || false,
                    skuExists: currentState?.skuExists || validated.skuExists,
                    batchMatch: currentState?.batchMatch || validated.batchMatch // Preserve entire batch match state
                };

                // Debug state preservation
                console.log('State preservation:', {
                    sku: validated.position.number,
                    idx: index,
                    hadState: !!currentState,
                    previousMatch: currentState?.batchMatch?.matchedBatch,
                    newMatch: preservedState.batchMatch.matchedBatch
                });

                return preservedState;
            }));

            // Add delay after all validations
            await new Promise(resolve => setTimeout(resolve, 50));

            const skus = validations
                .filter(v => v.skuExists)
                .map(v => v.position.number)
                .filter((sku): sku is string => sku !== null);

            if (skus.length > 0) {
                // Continue progress for batch matching phase
                setCurrentItem(positions.length);
                
                // First, get all main (non-shrinkage) batches for these SKUs
                const { data: mainBatches, error: batchError } = await supabase
                    .from('batches')
                    .select(`
                        *,
                        inventory:inventory(is_weight_based),
                        invoice_items:invoice_items(
                            weight_kilos,
                            quantity
                        )
                    `)
                    .in('sku', skus)
                    .not('batch_number', 'ilike', 'SHR-%');

                if (batchError) throw batchError;

                if (mainBatches && mainBatches.length > 0) {
                    // Get all batch numbers to fetch related shrinkage entries
                    const batchNumbers = mainBatches.map(b => b.batch_number);
                    
                    // In a separate query, get all shrinkage entries related to these batches
                    const { data: shrinkageEntries } = await supabase
                        .from('batches')
                        .select('*')
                        .in('base_id', batchNumbers)
                        .ilike('batch_number', 'SHR-%');

                    // Process each batch and add available_quantity
                    const processedBatches = mainBatches.map(batch => {
                        const relatedShrinkage = (shrinkageEntries || [])
                            .filter(s => s.base_id === batch.batch_number);
                        
                        const isWeightBased = batch.inventory?.is_weight_based;
                        
                        // Calculate available quantity
                        const originalAmount = isWeightBased ? batch.weight_kilos : batch.quantity;
                        
                        // Sum up invoice usage
                        const invoiceUsage = (batch.invoice_items || [])
                            .reduce((sum, item) => {
                                const amount = isWeightBased ? (item.weight_kilos || 0) : (item.quantity || 0);
                                return sum + amount;
                            }, 0);
                        
                        // Sum up shrinkage (which are negative values)
                        const shrinkageAmount = relatedShrinkage.reduce((sum, entry) => {
                            const amount = isWeightBased ? (entry.weight_kilos || 0) : (entry.quantity || 0);
                            return sum + amount; // Already negative
                        }, 0);
                        
                        // Calculate available: original + shrinkage - used
                        const availableQuantity = Math.max(0, originalAmount + shrinkageAmount - invoiceUsage);
                        
                        return {
                            ...batch,
                            available_quantity: availableQuantity
                        };
                    });

                    // Group batches by SKU
                    const batchesBySku = processedBatches.reduce((acc, batch) => {
                        if (!acc[batch.sku]) acc[batch.sku] = [];
                        acc[batch.sku].push(batch);
                        return acc;
                    }, {} as Record<string, any[]>);

                    setAvailableBatches(batchesBySku);

                    // Auto-match positions with available batches
                    const matchedValidations = validations.map(position => {
                        const availableBatchesForSku = batchesBySku?.[position.position.number!] || [];
                        
                        console.log('Matching batches for position:', {
                            sku: position.position.number,
                            currentMatch: position.batchMatch.matchedBatch,
                            availableBatches: availableBatchesForSku.map(b => b.batch_number),
                            hasLottoNumber: !!position.batchMatch.lottoNumber,
                            lottoNumber: position.batchMatch.lottoNumber
                        });
                        
                        // If there's only one batch available and no current match, use it
                        const hasSingleBatch = availableBatchesForSku.length === 1;
                        const hasMatchedBatch = !!position.batchMatch.matchedBatch;
                        const hasLottoNumber = !!position.batchMatch.lottoNumber;

                        // Try single batch match
                        if (hasSingleBatch && !hasMatchedBatch) {
                            const matchedPosition = {
                                ...position,
                                batchMatch: {
                                    ...position.batchMatch,
                                    matchedBatch: availableBatchesForSku[0].batch_number,
                                    confidence: 'high' as BatchConfidence
                                }
                            };
                            console.log('Single batch auto-match:', {
                                sku: position.position.number,
                                batch: matchedPosition.batchMatch.matchedBatch,
                                reason: 'single_batch_available'
                            });
                            return matchedPosition;
                        }

                        // Try lotto number match
                        if (hasLottoNumber && !hasMatchedBatch) {
                            const matchingBatch = availableBatchesForSku.find(batch =>
                                batch.batch_number.toLowerCase().includes(position.batchMatch.lottoNumber!.toLowerCase())
                            );
                            if (matchingBatch) {
                                const matchedPosition = {
                                    ...position,
                                    batchMatch: {
                                        ...position.batchMatch,
                                        matchedBatch: matchingBatch.batch_number,
                                        confidence: 'high' as BatchConfidence
                                    }
                                };
                                console.log('Lotto number auto-match:', {
                                    sku: position.position.number,
                                    batch: matchedPosition.batchMatch.matchedBatch,
                                    lottoNumber: position.batchMatch.lottoNumber,
                                    reason: 'lotto_number_match'
                                });
                                return matchedPosition;
                            }
                        }

                        console.log('No auto-match:', {
                            sku: position.position.number,
                            reason: hasMatchedBatch ? 'already_matched' :
                                !availableBatchesForSku.length ? 'no_batches' :
                                'multiple_batches_need_selection'
                        });
                        return position;
                    });

                    updatePositions(matchedValidations);
                } else {
                    setAvailableBatches({});
                }
            } else {
                updatePositions(validations);
            }

        } catch (error) {
            Logger.error('Validation error', error);
            toast({
                title: 'Validation Error',
                description: error instanceof Error ? error.message : 'Failed to validate note',
                variant: 'destructive'
            });
        } finally {
            setLoading(false);
        }
    }, [note, validatedPositions, toast, updatePositions]);

    // Run validation when note changes (component remounts due to key prop)
    useEffect(() => {
        Logger.info('Initializing note validation', {
            noteId: note.id,
            noteNumber: note.number,
            hasExistingPositions: validatedPositions.length > 0
        });
        validateNotePositions(true);
    }, [note.id]); // Run on note change
const handleBatchSelect = async (position: ValidatedPosition, batchNumber: string | null) => {
    try {
        const updated = validatedPositions.map(p =>
            p._idx === position._idx ? {
                ...p,
                batchMatch: {
                    ...p.batchMatch,
                    matchedBatch: batchNumber,
                    confidence: batchNumber ? 'high' : 'none' as BatchConfidence,
                    lottoNumber: p.batchMatch.lottoNumber // Preserve lotto number for UI
                },
                skipped: false
            } : p
        );

        // Debug validation state detail
        console.log('Validation Details:', updated.map(p => ({
            sku: p.position.number,
            idx: p._idx,
            hasMatch: !!p.batchMatch?.matchedBatch,
            matched: p.batchMatch?.matchedBatch,
            confidence: p.batchMatch?.confidence
        })));

        updatePositions(updated);
    } catch (error) {
        Logger.error('Error updating batch selection:', error);
        toast({
            variant: "destructive",
            title: "Error",
            description: "Failed to update batch selection. Please try again."
        });
    }
};

    const handleNoteChange = (position: ValidatedPosition, note: string) => {
        const updated = validatedPositions.map(p => {
            if (p._idx === position._idx) {  // Compare by unique index
                return {
                    ...p,
                    position: {
                        ...p.position,
                        description: note
                    }
                };
            }
            return p;
        });

        updatePositions(updated);
    };

    const handleSkipChange = (position: ValidatedPosition, skip: boolean) => {
        const updated = validatedPositions.map(p => {
            if (p._idx === position._idx) {  // Compare by unique index
                // NEVER modify batch match when skipping - they are independent
                const updatedPosition: ValidatedPosition = {
                    ...p,
                    skipped: skip,
                    batchMatch: {
                        ...p.batchMatch,  // Preserve entire batch match state
                        confidence: skip ? 'none' as BatchConfidence : p.batchMatch.confidence // Only confidence changes
                    }
                };

                // Debug skip state update
                console.log('Skip state change:', {
                    sku: p.position.number,
                    skip,
                    previousMatch: p.batchMatch.matchedBatch,
                    preserved: updatedPosition.batchMatch.matchedBatch,
                    isReadyToImport: updatedPosition.batchMatch.matchedBatch !== null || skip
                });
                return updatedPosition;
            }
            return p;
        });


        updatePositions(updated);
    };

    interface ValidationSummary {
        total: number;
        matched: number;
        errors: number;
        warnings: number;
        skipped: number;
        readyToImport: number;
        unhandled: number;
        isProcessable: boolean;
    }

    const getValidationSummary = (): ValidationSummary => {
        // Debug validation summary calculation with stable indices
        console.log('Full validation state:', validatedPositions.map(p => ({
            sku: p.position.number,
            idx: p._idx,
            arrayIndex: validatedPositions.indexOf(p),
            batchMatch: p.batchMatch,
            hasMatch: p.batchMatch.matchedBatch !== null,
            skipped: p.skipped,
            isReadyToImport: p.batchMatch.matchedBatch !== null || p.skipped
        })));
        const summary = validatedPositions.reduce((acc, position) => ({
            total: acc.total + 1,
            matched: acc.matched + (position.batchMatch.matchedBatch !== null ? 1 : 0),
            errors: acc.errors + position.errors.length,
            warnings: acc.warnings + position.warnings.length,
            skipped: acc.skipped + (position.skipped ? 1 : 0),
            // If item has a batch match and isn't skipped, it's ready
            // Ready if: Has ANY batch match (not counting skipped items here)
            readyToImport: acc.readyToImport + (
                position.batchMatch.matchedBatch !== null ? 1 : 0
            ),
            // Only unhandled if no batch AND not skipped
            unhandled: acc.unhandled + (
                position.batchMatch.matchedBatch === null && !position.skipped ? 1 : 0
            ),
            isProcessable: false // Will be set after reduction
        }), {
            total: 0,
            matched: 0,
            errors: 0,
            warnings: 0,
            skipped: 0,
            readyToImport: 0,
            unhandled: 0,
            isProcessable: false
        });

        // Allow import if at least one item is ready
        summary.isProcessable = summary.readyToImport > 0;
        
        return summary;
    };

    const handleCreateProductClick = (position: ValidatedPosition) => {
        setSelectedProduct({
            sku: position.position.number || '',
            name: position.position.name || '',
            vat: position.position.vat
        });
        setIsCreateProductOpen(true);
    };

    const handleProductCreated = useCallback(async () => {
        setIsCreateProductOpen(false);
        setSelectedProduct(null);

        // Allow a moment for DB to sync
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Revalidate to see new SKU
        validateNotePositions(true);
    }, [validateNotePositions]);

    if (loading) {
        const message = validatedPositions.length === 0
            ? "Validating delivery note items..."
            : "Matching batches for items...";
            
        return (
            <ProgressIndicator
                current={currentItem}
                total={totalItems}
                message={message}
            />
        );
    }

    const summary = getValidationSummary();

    return (
        <div className="container mx-auto p-2 sm:p-4 max-w-4xl">
            <div className="bg-white p-3 sm:p-6 rounded-lg shadow">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-3 sm:gap-4 mb-6">
                    <div className="space-y-1 p-3 bg-gray-50 rounded-lg border border-gray-100">
                        <h3 className="text-sm font-medium text-gray-500">Company</h3>
                        <p className="text-lg font-semibold text-gray-900">
                            {note.contact?.name || 'N/A'}
                        </p>
                    </div>
                    <div className="space-y-1 p-3 bg-gray-50 rounded-lg border border-gray-100">
                        <h3 className="text-sm font-medium text-gray-500">Date</h3>
                        <p className="text-lg font-semibold text-gray-900">{note.date}</p>
                    </div>
                    <div className="space-y-1 p-3 bg-gray-50 rounded-lg border border-gray-100">
                        <h3 className="text-sm font-medium text-gray-500">Note #</h3>
                        <p className="text-lg font-semibold text-gray-900">{note.number}</p>
                        {/* Show transformation preview */}
                        {transformDeliveryNote(note.number).wasTransformed && (
                            <div className="mt-1 text-xs text-blue-600 font-mono">
                                Will be imported as: {transformDeliveryNote(note.number).sanitized}
                            </div>
                        )}
                    </div>
                    <div className="space-y-1 p-3 bg-gray-50 rounded-lg border border-gray-100">
                        <h3 className="text-sm font-medium text-gray-500">Status</h3>
                        <div className="flex items-center gap-2">
                            <div className="space-y-1">
                                <p className="text-lg font-semibold text-gray-900">
                                    {summary.readyToImport - summary.skipped} of {summary.total} Ready
                                </p>
                                {summary.skipped > 0 && (
                                    <p className="text-sm text-gray-500">
                                        ({summary.skipped} skipped)
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="space-y-1 p-3 bg-gray-50 rounded-lg border border-gray-100">
                        <h3 className="text-sm font-medium text-gray-500">Total Amount</h3>
                        <p className="text-lg font-semibold text-blue-800">
                            CHF {validatedPositions
                                .filter(p => !p.skipped)
                                .reduce((sum, p) => sum + (p.position.price * p.position.amount), 0)
                                .toFixed(2)}
                        </p>
                    </div>
                </div>

                <div className="space-y-6">
                    {validatedPositions.map((position) => (
                        <div 
                            key={position.position.number}
                            className={`p-3 sm:p-4 rounded-lg border ${
                                position.errors.length ? 'border-red-300 bg-red-50' :
                                position.warnings.length ? 'border-yellow-300 bg-yellow-50' :
                                position.skipped ? 'border-gray-300 bg-gray-50' :
                                'border-gray-200'
                            }`}
                        >
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 mb-4">
                                <div>
                                    <h4 className="font-medium">{position.position.name}</h4>
                                    <p className="text-sm text-gray-600">
                                        SKU: {position.position.number || 'N/A'}
                                    </p>
                                </div>
                                <div className="text-right sm:text-right text-left">
                                    <p className="font-medium">
                                        {position.position.amount} 
                                        {position.skuDetails?.is_weight_based ? ' kg' : ' units'}
                                    </p>
                                    <div className="space-y-1">
                                        <p className="text-xs text-gray-500">
                                            @ CHF {position.position.price} each
                                        </p>
                                        <p className="text-sm font-medium text-gray-900">
                                            Total: CHF {(position.position.price * position.position.amount).toFixed(2)}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {position.skuExists && !position.errors.length && (
                                <div className={position.skipped ? 'opacity-50' : ''}>
                                    <div className="flex justify-between items-start mb-2">
                                        <div className="flex items-center gap-2">
                                            {position.skipped ? (
                                                <span className="inline-flex items-center px-2 py-1 rounded text-sm bg-gray-100 text-gray-600">
                                                    ⏭️ Skipped
                                                </span>
                                            ) : position.batchMatch.matchedBatch ? (
                                                <span className="sr-only">Ready to Import</span>
                                            ) : (
                                                <span className="sr-only">Needs batch assignment</span>
                                            )}
                                        </div>
                                    </div>
                                    
                                    <BatchMatchPanel
                                        position={position}
                                        availableBatches={availableBatches[position.position.number!] || []}
                                        onBatchSelect={(batch) => handleBatchSelect(position, batch)}
                                        onSkipItem={(skip) => handleSkipChange(position, skip)}
                                        disabled={loading}
                                        loading={loading}
                                        onNoteChange={(note) => handleNoteChange(position, note)}
                                        allowSkip={true}
                                    />
                                </div>
                            )}

                            {/* Show SKU transformation info if SKU was modified */}
                            {position.skuTransformation && (
                                <div className="mt-2 p-2 bg-blue-50 border border-blue-200 rounded">
                                    <p className="text-sm text-blue-700">
                                        <span className="font-medium">SKU Modified: </span>
                                        <span className="font-mono">"{position.skuTransformation.original}"</span>
                                        <span className="mx-2">→</span>
                                        <span className="font-mono">"{position.skuTransformation.sanitized}"</span>
                                    </p>
                                </div>
                            )}
                            {position.errors.map((error, i) => (
                                <p key={i} className="text-sm text-red-600 mt-2">{error}</p>
                            ))}
                            {position.warnings.map((warning, i) => (
                                <p key={i} className="text-sm text-yellow-600 mt-2">{warning}</p>
                            ))}
                            {/* "Create Inventory Item" Button - Conditionally rendered */}
                            {!position.skuExists && (
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => handleCreateProductClick(position)}
                                    className="mt-2"
                                >
                                    Create Inventory Item
                                </Button>
                            )}
                        </div>
                    ))}
                </div>

                <div className="mt-6 border-t pt-4 sm:pt-6">
                    <div className="flex flex-col gap-3 sm:gap-4">
                        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between text-sm text-gray-600 gap-2 sm:gap-0">
                            <span>
                                {summary.readyToImport - summary.skipped} items ready to import
                                {summary.skipped > 0 && ` (${summary.skipped} skipped)`}
                            </span>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => validateNotePositions(true)}
                                disabled={loading}
                            >
                                Refresh Items
                            </Button>
                        </div>
                        
                        <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-3">
                            <Button
                                variant="outline"
                                onClick={onNext}
                                disabled={loading}
                                className="flex-1 h-auto py-2 px-4"
                            >
                                {isLast ? (
                                    'Skip Note & Finish'
                                ) : (
                                    <>
                                        Skip to Next Note
                                        <span className="ml-2 text-xs text-gray-500">
                                            ({summary.total} items)
                                        </span>
                                    </>
                                )}
                            </Button>
                            
                            <Button
                                onClick={onImport}
                                disabled={loading ||
                                         summary.errors > 0 ||
                                         !summary.isProcessable}
                                className="flex-1 bg-blue-600 hover:bg-blue-700 h-auto py-2 px-4"
                            >
                                {loading ? (
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                ) : summary.skipped === summary.total ? (
                                    "Skip All Items & Continue"
                                ) : summary.readyToImport > 0 ? (
                                    `Import ${summary.readyToImport - summary.skipped} Item${(summary.readyToImport - summary.skipped) !== 1 ? 's' : ''} & Continue`
                                ) : (
                                    "No Items to Import"
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Render CreateProductFromDNDialog */}
            <CreateProductFromDNDialog
                open={isCreateProductOpen}
                onOpenChange={setIsCreateProductOpen}
                onProductCreated={handleProductCreated}
                initialSku={selectedProduct?.sku}
                initialName={selectedProduct?.name}
                initialVat={selectedProduct?.vat}
            />
        </div>
    );
}
