import React from 'react';

interface FormRadioProps {
  name: string;
  value: string;
  label: string;
  checked: boolean;
  onChange: (value: string) => void;
}

export const FormRadio: React.FC<FormRadioProps> = ({
  name,
  value,
  label,
  checked,
  onChange,
}) => {
  return (
    <div className="flex items-center">
      <input
        type="radio"
        id={`${name}-${value}`}
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => onChange(e.target.value)}
        className="h-4 w-4 text-[#C3D48A] border-gray-300 focus:ring-[#C3D48A]"
      />
      <label
        htmlFor={`${name}-${value}`}
        className="ml-2 block text-sm font-medium text-white"
      >
        {label}
      </label>
    </div>
  );
};