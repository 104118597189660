import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Pencil } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import InvoiceFormFields from "./InvoiceFormFields";
import { editInvoiceFormSchema, type EditInvoiceFormValues, type Invoice } from "@/types/invoice";
import Logger from "@/utils/logger";

interface EditInvoiceDialogProps {
  invoice: Invoice;
  onInvoiceUpdated: (invoiceId?: number) => void;
}

export function EditInvoiceDialog({ invoice, onInvoiceUpdated }: EditInvoiceDialogProps) {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const defaultValues: EditInvoiceFormValues = {
    id: invoice.id!,
    invoice_number: invoice.invoice_number,
    date: invoice.date,
    company: invoice.company,
    notes: invoice.notes || "",
    status: invoice.status,
    items: invoice.items?.map(item => ({
      sku: item.sku,
      item_name: item.item_name || "",
      batch_number: item.batch_number,
      warehouse: item.warehouse,
      quantity: item.measurement_type === "quantity" ? item.quantity : undefined,
      weight_kilos: item.measurement_type === "weight" ? item.weight_kilos : undefined,
      measurement_type: item.measurement_type || "quantity",
      is_weight_based: item.measurement_type === "weight",
      unit_price: item.unit_price || 0,  // Include unit_price in form data
      selected_batches: [{
        batch_number: item.batch_number,
        quantity: item.measurement_type === "quantity" ? item.quantity : undefined,
        weight_kilos: item.measurement_type === "weight" ? item.weight_kilos : undefined,
        warehouse: item.warehouse,
        available_quantity: (item.measurement_type === "quantity" ? item.quantity : undefined),
        available_weight: (item.measurement_type === "weight" ? item.weight_kilos : undefined)
      }]
    })) || []
  };

  const form = useForm<EditInvoiceFormValues>({
    resolver: zodResolver(editInvoiceFormSchema),
    defaultValues,
    mode: "onChange"
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [open]);

  const onSubmit = async (data: EditInvoiceFormValues) => {
    try {
      Logger.info('Starting invoice update', {
        invoiceId: data.id,
        status: data.status,
        itemCount: data.items?.length || 0
      });

      setIsSubmitting(true);

      // First, restore quantities from original invoice if it wasn't manually created
      if (invoice.status !== 'IMS') {
        Logger.info('Restoring original quantities', { 
          originalItems: invoice.items 
        });

        for (const item of invoice.items || []) {
          const { data: batch, error: fetchError } = await supabase
            .from('batches')
            .select('quantity, available_quantity, weight_kilos')
            .eq('batch_number', item.batch_number)
            .eq('sku', item.sku)
            .single();

          if (fetchError) {
            Logger.error('Error fetching original batch', { 
              error: fetchError,
              batch: item.batch_number,
              sku: item.sku
            });
            throw new Error(`Failed to fetch batch ${item.batch_number}`);
          }

          const currentAvailable = batch.available_quantity ?? 
            (item.measurement_type === 'weight' ? batch.weight_kilos : batch.quantity) ?? 0;
          
          const restoreAmount = item.measurement_type === 'weight' 
            ? (item.weight_kilos ?? 0) 
            : (item.quantity ?? 0);

          Logger.info('Restoring batch quantity', {
            batch: item.batch_number,
            sku: item.sku,
            currentAvailable,
            restoreAmount,
            newAvailable: currentAvailable + restoreAmount
          });

          const { error: updateError } = await supabase
            .from('batches')
            .update({
              available_quantity: currentAvailable + restoreAmount
            })
            .eq('batch_number', item.batch_number)
            .eq('sku', item.sku);

          if (updateError) {
            Logger.error('Error restoring batch quantity', { 
              error: updateError,
              batch: item.batch_number,
              sku: item.sku
            });
            throw new Error(`Failed to restore quantity for batch ${item.batch_number}`);
          }
        }
      }

      // Start a Supabase transaction
      const transaction = async () => {
        // Update invoice details
        const { error: updateError } = await supabase
          .from('invoices')
          .update({
            date: data.date,
            company: data.company,
            notes: data.notes,
            status: data.status,
            updated_at: new Date().toISOString()
          })
          .eq('id', data.id);

        if (updateError) {
          throw new Error(`Failed to update invoice: ${updateError.message}`);
        }

        // Filter out skipped/invalid items and prepare new items
        const validItems = data.items.filter(item => {
          if (!item.selected_batches || item.selected_batches.length === 0) {
            return false; // Skip items without selected batches
          }
          if (item.measurement_type === "quantity") {
            return item.quantity && item.quantity > 0;
          }
          if (item.measurement_type === "weight") {
            return item.weight_kilos && Number(item.weight_kilos) > 0;
          }
          return false;
        });

        Logger.info('Filtered valid items for update', {
          originalCount: data.items.length,
          validCount: validItems.length
        });

        const newItems = validItems.map(item => ({
          invoice_id: data.id,
          sku: item.sku,
          quantity: item.measurement_type === "quantity" ? item.quantity : null,
          weight_kilos: item.measurement_type === "weight" ? item.weight_kilos : null,
          batch_number: item.batch_number,
          warehouse: item.warehouse,
          measurement_type: item.measurement_type,
          unit_price: item.unit_price || 0
        }));

        // Delete old items only after preparing new ones
        const { error: deleteError } = await supabase
          .from('invoice_items')
          .delete()
          .eq('invoice_id', data.id);

        if (deleteError) {
          throw new Error(`Failed to delete existing items: ${deleteError.message}`);
        }

        // Insert new items
        const { error: insertError } = await supabase
          .from('invoice_items')
          .insert(newItems);

        if (insertError) {
          throw new Error(`Failed to insert new items: ${insertError.message}`);
        }

        // Return the new items for batch quantity updates
        return newItems;
      };

      // Execute the transaction
      const newItems = await transaction();

      // If new status is not manual (IMS), update batch quantities
      if (data.status !== 'IMS') {
        Logger.info('Updating batch quantities for new items', { 
          newItems: data.items 
        });

        for (const item of data.items) {
          if (!item.selected_batches) continue;

          for (const batch of item.selected_batches) {
            const { data: currentBatch, error: fetchError } = await supabase
              .from('batches')
              .select('quantity, available_quantity, weight_kilos')
              .eq('batch_number', batch.batch_number)
              .eq('sku', item.sku)
              .single();

            if (fetchError) {
              Logger.error('Error fetching batch for update', { 
                error: fetchError,
                batch: batch.batch_number,
                sku: item.sku
              });
              throw new Error(`Failed to fetch batch ${batch.batch_number}`);
            }

            const currentAvailable = currentBatch.available_quantity ?? 
              (item.measurement_type === 'weight' ? currentBatch.weight_kilos : currentBatch.quantity) ?? 0;

            const deductAmount = item.measurement_type === 'weight'
              ? (batch.weight_kilos ?? 0)
              : (batch.quantity ?? 0);

            Logger.info('Updating batch quantity', {
              batch: batch.batch_number,
              sku: item.sku,
              currentAvailable,
              deductAmount,
              newAvailable: Math.max(0, currentAvailable - deductAmount)
            });

            const { error: updateError } = await supabase
              .from('batches')
              .update({
                available_quantity: Math.max(0, currentAvailable - deductAmount)
              })
              .eq('batch_number', batch.batch_number)
              .eq('sku', item.sku);

            if (updateError) {
              Logger.error('Error updating batch quantity', { 
                error: updateError,
                batch: batch.batch_number,
                sku: item.sku
              });
              throw new Error(`Failed to update quantity for batch ${batch.batch_number}`);
            }
          }
        }
      }

      Logger.info('Invoice update completed successfully', {
        invoiceId: data.id,
        itemCount: newItems.length
      });

      toast({
        title: "Success",
        description: "Invoice updated successfully"
      });

      setOpen(false);
      onInvoiceUpdated(data.id);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
      Logger.error('Invoice update failed', { error: errorMessage });
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button
        variant="ghost"
        size="icon"
        onClick={() => setOpen(true)}
      >
        <Pencil className="h-4 w-4" />
      </Button>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-3xl max-w-screen-lg w-screen max-h-[90vh] overflow-y-auto mx-auto" aria-describedby="edit-invoice-description">
          <DialogHeader>
            <DialogTitle>Edit Delivery Note #{invoice.invoice_number}</DialogTitle>
          </DialogHeader>
          <div id="edit-invoice-description" className="sr-only">
            Edit invoice details and items
          </div>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <InvoiceFormFields
              form={form}
              onDuplicateChange={() => {}}
              isEditMode={true}
            />
            <div className="flex justify-end space-x-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => setOpen(false)}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button 
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Updating...' : 'Update Invoice'}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
