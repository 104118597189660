import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Loader2, Minus } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import * as z from "zod";
import { supabase } from "@/lib/supabase";
import { generateBatchSuggestion, getAvailableQuantity } from "@/utils/batchUtils";

// Update the SHRINKAGE_REASONS to include both value and description
const SHRINKAGE_REASONS = [
  {
    value: "Expired",
    label: "Expired",
    description: "Goods past their shelf life or use-by date"
  },
  {
    value: "Spoilage",
    label: "Spoilage",
    description: "Perishable goods degraded before expiration"
  },
  {
    value: "Damaged",
    label: "Damaged",
    description: "Items broken, crushed, or rendered unsellable during handling, storage, or transit"
  },
  {
    value: "Lost",
    label: "Lost",
    description: "Items missing due to misplacement, miscounting, or unknown causes"
  },
  {
    value: "Theft",
    label: "Theft",
    description: "External theft (shoplifting, burglary) or internal theft (employee pilferage)"
  },
  {
    value: "Supplier Defects",
    label: "Supplier Defects",
    description: "Items received already defective or incorrect (e.g., wrong shipment, manufacturing flaws)"
  },
  {
    value: "Other",
    label: "Other",
    description: "Any other cause not covered by the categories above"
  }
];

// Define form schema for shrinkage workflow
const addShrinkageSchema = z.object({
  // In shrinkage mode, user will search & select an existing batch.
  // The field 'selectedBatch' will hold the chosen batch details (e.g., batch number)
  selectedBatch: z.string().min(1, "Please select a batch for shrinkage"),
  sku: z.string().min(1, "SKU is required"),
  // For shrinkage, user enters loss quantity as a positive number which will be stored as negative
  quantity: z.number().positive("Enter a positive number").optional(),
  weight_kilos: z.number().positive("Enter a positive number").optional(),
  expiry_date: z.string().optional(),
  date_received: z.string(),
  warehouse: z.enum(["(+3°C)", "(+25°C)", "(-25°C)", "Paradiso", "Consignment"]),
  purchase_cost: z.number().optional(),
  notes: z.string().optional(),
  shrinkage_reason: z.string().min(1, "Please select a shrinkage reason"),
  // Hidden fields for validation
  maxAvailable: z.number(),
  isWeightBased: z.boolean(),
}).refine((data) => {
  if (data.isWeightBased) {
    return typeof data.weight_kilos === "number" && data.weight_kilos <= data.maxAvailable;
  }
  return typeof data.quantity === "number" && data.quantity <= data.maxAvailable;
}, {
  message: "Loss amount cannot exceed available amount",
  path: ["quantity"],
});

type AddShrinkageFormValues = z.infer<typeof addShrinkageSchema>;

interface AddShrinkageDialogProps {
  onBatchAdded: () => void;
  variant?: 'default' | 'icon';
}

export function AddShrinkageDialog({ onBatchAdded, variant = 'default' }: AddShrinkageDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // Function to reset the form and search state
  const resetForm = () => {
    form.reset({
      selectedBatch: "",
      sku: "",
      quantity: undefined,
      weight_kilos: undefined,
      expiry_date: "",
      date_received: new Date().toISOString().split("T")[0],
      warehouse: "(+25°C)",
      purchase_cost: undefined,
      notes: "",
      shrinkage_reason: "",
      maxAvailable: 0,
      isWeightBased: false,
    });
    setSearchTerm("");
    setSearchResults([]);
  };

  // State for search functionality
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState<Array<{
    batch_number: string;
    sku: string;
    expiry_date: string;
    quantity?: number;
    weight_kilos?: number;
    warehouse: string;
  }>>([]);
  const [isSearching, setIsSearching] = React.useState(false);
  const { toast } = useToast();

  const form = useForm<AddShrinkageFormValues>({
    resolver: zodResolver(addShrinkageSchema),
    defaultValues: {
      selectedBatch: "",
      sku: "",
      quantity: undefined,
      weight_kilos: undefined,
      expiry_date: "",
      date_received: new Date().toISOString().split("T")[0],
      warehouse: "(+25°C)",
      purchase_cost: undefined,
      notes: "",
      shrinkage_reason: "",
      maxAvailable: 0,
      isWeightBased: false,
    },
  });

  // Function to search batches
  const searchBatches = async (term: string) => {
    setSearchTerm(term);
    if (!term) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      // Search for non-shrinkage batches only (not starting with SHR-)
      const { data, error } = await supabase
        .from('batches')
        .select('batch_number, sku, expiry_date, quantity, weight_kilos, warehouse')
        .not('batch_number', 'ilike', 'SHR-%')
        .or(`batch_number.ilike.%${term}%,sku.ilike.%${term}%`)
        .order('date_received', { ascending: false })
        .limit(8);

      if (error) {
        resetForm();
        toast({
          variant: "destructive",
          title: "Search Error",
          description: "Failed to search batches. Please try again.",
        });
        return;
      }

      setSearchResults(data || []);
    } catch (error) {
      console.error("Unexpected error during batch search:", error);
      resetForm();
      toast({
        variant: "destructive",
        title: "Error",
        description: "An unexpected error occurred while searching.",
      });
    } finally {
      setIsSearching(false);
    }
  };

  // Function to fetch batch details based on selected batch number
  const fetchBatchDetails = async (batchNumber: string) => {
    if (!batchNumber) return;

    try {
      // Ensure we have all required Batch properties
      type BatchWithInventory = {
        batch_number: string;
        base_id: string;
        sku: string;
        expiry_date: string;
        date_received: string;
        warehouse: string;
        purchase_cost: number;
        notes: string | null;
        quantity: number | null;
        weight_kilos: number | null;
        created_at: string;
        inventory: {
          item_name: string;
          is_weight_based: boolean;
        };
      };

      // First get the main batch with inventory data
      const { data, error } = await supabase
        .from('batches')
        .select(`
          *,
          inventory!inner (
            item_name,
            is_weight_based
          )
        `)
        .eq('batch_number', batchNumber)
        .single<BatchWithInventory>();

      if (error || !data) {
        resetForm();
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to fetch batch details. Please try again.",
        });
        return;
      }

      // Get all related batches (including shrinkage entries)
      // Get all shrinkage entries for this batch
      const { data: relatedBatches } = await supabase
        .from('batches')
        .select('*')
        .eq('base_id', batchNumber) // Get entries that reference this batch
        .ilike('batch_number', 'SHR-%'); // Only shrinkage entries

      type Warehouse = "(+3°C)" | "(+25°C)" | "(-25°C)" | "Paradiso" | "Consignment";

      // Auto-populate form fields with fetched batch details
      form.setValue("sku", data.sku);
      form.setValue("expiry_date", data.expiry_date);
      form.setValue("date_received", data.date_received);
      form.setValue("warehouse", data.warehouse as Warehouse);
      form.setValue("purchase_cost", data.purchase_cost);
      form.setValue("notes", data.notes ?? "");

      // Calculate available amount - start with original quantity
      const originalAmount = data.inventory.is_weight_based ? 
        (data.weight_kilos || 0) : 
        (data.quantity || 0);

      // Sum up shrinkage entries
      const shrinkageAmount = (relatedBatches || []).reduce((sum, b) => {
        const amount = data.inventory.is_weight_based ? 
          (b.weight_kilos || 0) : 
          (b.quantity || 0);
        return sum + amount; // Shrinkage entries are already negative
      }, 0);

      const availableAmount = originalAmount + shrinkageAmount;

      // Check if there's any quantity available
      if (availableAmount <= 0) {
        resetForm();
        toast({
          variant: "destructive",
          title: "No Quantity Available",
          description: "This batch has no available quantity for shrinkage. Please select a different batch."
        });
        return;
      }

      form.setValue("maxAvailable", availableAmount);
      form.setValue("isWeightBased", data.inventory.is_weight_based);

      toast({
        title: "Batch Selected",
        description: `Selected ${batchNumber} with ${data.inventory.is_weight_based ? 
          `${availableAmount} kg available` : 
          `${availableAmount} units available`}`,
      });

    } catch (error) {
      console.error("Unexpected error fetching batch details:", error);
      resetForm();
      toast({
        variant: "destructive",
        title: "Error",
        description: "An unexpected error occurred while fetching batch details.",
      });
    }
  };

  // Selected batch display component
  const SelectedBatchInfo = () => {
    const selectedBatch = form.watch("selectedBatch");
    const sku = form.watch("sku");
    const isWeightBased = form.watch("isWeightBased");
    const maxAvailable = form.watch("maxAvailable");

    if (!selectedBatch) return null;

    return (
      <div className="rounded-lg border border-accent p-4 bg-accent/5 mb-6">
        <div className="flex items-center justify-between mb-2">
          <span className="font-medium">Selected Batch</span>
          <span className="text-sm text-muted-foreground">
            {form.watch("warehouse")}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-4 text-sm">
          <div>
            <span className="text-muted-foreground">Batch Number:</span>
            <p className="font-medium">{selectedBatch}</p>
          </div>
          <div>
            <span className="text-muted-foreground">SKU:</span>
            <p className="font-medium">{sku}</p>
          </div>
          <div>
            <span className="text-muted-foreground">Available:</span>
            <p className="font-medium">
              {isWeightBased ? `${maxAvailable} kg` : maxAvailable}
            </p>
          </div>
          <div>
            <span className="text-muted-foreground">Expiry:</span>
            <p className="font-medium">
              {form.watch("expiry_date") ? 
                new Date(form.watch("expiry_date")).toLocaleDateString() : 
                "N/A"}
            </p>
          </div>
        </div>
      </div>
    );
  };

  // Extract watched value to remove complex expression in deps array
  const selectedBatch = form.watch("selectedBatch");

  // Use useEffect to call fetchBatchDetails when selectedBatch changes
  useEffect(() => {
    if (selectedBatch) {
      fetchBatchDetails(selectedBatch);
    }
  }, [selectedBatch, fetchBatchDetails, form]);

  // Reset form when dialog opens
  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open, resetForm]);

  const onSubmit = async (values: AddShrinkageFormValues) => {
    try {
      setLoading(true);

      // Generate unique batch number
      const baseShrinkageNumber = `SHR-${values.selectedBatch}`;
      const newBatchNumber = await generateBatchSuggestion(baseShrinkageNumber, supabase);

      // Process the loss quantity/weight: store as negative value
      const insertData = {
        batch_number: newBatchNumber,
        base_id: values.selectedBatch,    // Link back to original batch
        sku: values.sku,
        quantity: values.isWeightBased ? null : -Math.abs(values.quantity ?? 0),
        weight_kilos: values.isWeightBased ? -Math.abs(values.weight_kilos ?? 0) : null,
        expiry_date: values.expiry_date || null,
        date_received: values.date_received,
        warehouse: values.warehouse,
        purchase_cost: values.purchase_cost,
        notes: values.notes ? `${values.shrinkage_reason}: ${values.notes}` : values.shrinkage_reason,
        shrinkage_reason: values.shrinkage_reason,
      };

      // Insert only the shrinkage entry with negative quantity
      const { error } = await supabase
        .from('batches')
        .insert([insertData]);

      if (error) {
        resetForm();
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to create shrinkage entry. Please try again.",
        });
        console.error("Supabase error:", error);
        return;
      }

      toast({
        title: "Success",
        description: `Shrinkage entry created for batch ${values.selectedBatch}`,
      });

      resetForm();
      setOpen(false);
      onBatchAdded();
    } catch (error) {
      console.error("Error adding shrinkage batch:", error);
      resetForm();
      toast({
        variant: "destructive",
        title: "Error",
        description: "An unexpected error occurred. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={(isOpen) => {
      if (!isOpen) {
        resetForm();
      }
      setOpen(isOpen);
    }}>
      <DialogTrigger asChild>
        {variant === 'icon' ? (
          <Button 
            size="icon"
            variant="destructive"
            className="h-9 w-9"
          >
            <Minus className="h-4 w-4" />
          </Button>
        ) : (
          <Button variant="destructive" className="h-9">
            <Minus className="mr-2 h-4 w-4" />
            Shrinkage
          </Button>
        )}
      </DialogTrigger>

      <DialogContent className="sm:max-w-[600px] w-[95vw] max-h-[90vh] overflow-y-auto">
        <DialogHeader className="flex flex-col items-center">
          <DialogTitle className="text-xl font-semibold tracking-tight">
            <div className="flex items-center gap-2">
              <span className="bg-destructive text-destructive-foreground font-medium rounded-lg px-4 py-2 flex items-center gap-2">
                <Minus className="h-4 w-4" />
                Add Shrinkage Batch
              </span>
            </div>
          </DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            {/* Search Batch Section */}
            <div className="rounded-lg border border-accent/20 bg-accent/5 p-4">
              <FormField
                control={form.control}
                name="selectedBatch"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">Search Batch for Shrinkage</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          placeholder="Search by batch number or SKU..."
                          value={searchTerm}
                          onChange={(e) => searchBatches(e.target.value)}
                          className="pl-9" // Space for search icon
                        />
                        <span className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground">
                          🔍
                        </span>
                        {isSearching && (
                          <div className="absolute right-3 top-1/2 -translate-y-1/2">
                            <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
                          </div>
                        )}
                      </div>
                    </FormControl>
                    <FormMessage />

                    {/* Search results container with absolute positioning */}
                    <div className="relative">
                      {searchTerm && (
                        <div className="absolute top-1 left-0 right-0 z-10">
                          {searchResults.length > 0 ? (
                            <div className="mt-2 border rounded-md bg-white shadow-lg max-h-[300px] overflow-y-auto">
                              {searchResults.map((result) => (
                                <div
                                  key={result.batch_number}
                                  className="px-4 py-3 hover:bg-accent/5 cursor-pointer border-b last:border-b-0"
                                  onClick={() => {
                                    field.onChange(result.batch_number);
                                    setSearchTerm("");
                                    setSearchResults([]);
                                  }}
                                >
                                  <div className="flex justify-between items-start mb-1">
                                    <span className="font-medium">{result.batch_number}</span>
                                    <span className="text-sm text-muted-foreground">
                                      {result.warehouse}
                                    </span>
                                  </div>
                                  <div className="text-sm text-muted-foreground flex items-center justify-between">
                                    <span>SKU: {result.sku}</span>
                                    <span>
                                      Available: {result.weight_kilos 
                                        ? `${result.weight_kilos} kg` 
                                        : result.quantity}
                                    </span>
                                  </div>
                                  <div className="text-xs text-muted-foreground mt-1">
                                    Expires: {new Date(result.expiry_date).toLocaleDateString()}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : !isSearching && (
                            <div className="mt-2 text-sm text-muted-foreground text-center py-3 bg-secondary/5 rounded-md border">
                              No matching batches found
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    
                    {/* Add an invisible spacer to maintain consistent dialog height */}
                    {searchTerm && <div className="h-[40px]"></div>}
                  </FormItem>
                )}
              />
            </div>

            {/* Selected Batch Info */}
            <SelectedBatchInfo />

            {/* SKU Field */}
            <div className="rounded-lg border border-accent/20 bg-accent/5 p-4">
              <FormField
                control={form.control}
                name="sku"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">SKU</FormLabel>
                    <FormControl>
                      <Input 
                        placeholder="SKU will be populated from batch" 
                        {...field}
                        readOnly
                        className="bg-muted cursor-not-allowed"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {/* Quantity or Weight */}
            <div className="rounded-lg border border-accent/20 bg-accent/5 p-4">
              {form.watch("isWeightBased") ? (
                <FormField
                  control={form.control}
                  name="weight_kilos"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium">
                        Loss Weight (kg) <span className="text-muted-foreground">(Enter positive number)</span>
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          step="0.001"
                          min="0.001" // Prevent negative and zero values in the input
                          placeholder={`Enter loss amount (max: ${form.watch("maxAvailable")} kg)`}
                          {...field}
                          onChange={(e) => {
                            const value = e.target.value === '' ? undefined : Number(e.target.value);
                            // Check if the value is negative
                            if (value !== undefined && value <= 0) {
                              toast({
                                title: "Positive numbers only",
                                description: "Please enter a positive number greater than zero for the loss amount.",
                                variant: "destructive",
                              });
                              // Reset to empty or previous valid value
                              field.onChange('');
                            } else {
                              field.onChange(value);
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ) : (
                <FormField
                  control={form.control}
                  name="quantity"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium">
                        Loss Quantity <span className="text-muted-foreground">(Enter positive number)</span>
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          step="1"
                          min="1" // Prevent negative and zero values in the input
                          placeholder={`Enter loss amount (max: ${form.watch("maxAvailable")} units)`}
                          {...field}
                          onChange={(e) => {
                            const value = e.target.value === '' ? undefined : Number(e.target.value);
                            // Check if the value is negative or zero
                            if (value !== undefined && value <= 0) {
                              toast({
                                title: "Positive numbers only",
                                description: "Please enter a positive number greater than zero for the loss amount.",
                                variant: "destructive",
                              });
                              // Reset to empty or previous valid value
                              field.onChange('');
                            } else {
                              field.onChange(value);
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>

            {/* Batch Info Fields - read only */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="rounded-lg border bg-secondary/5 p-4">
                <FormField
                  control={form.control}
                  name="date_received"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium">Receive Date</FormLabel>
                      <FormControl>
                        <Input
                          type="date"
                          {...field}
                          readOnly
                          className="bg-muted cursor-not-allowed"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="rounded-lg border bg-secondary/5 p-4">
                <FormField
                  control={form.control}
                  name="expiry_date"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium">Expiry Date</FormLabel>
                      <FormControl>
                        <Input
                          type="date"
                          {...field}
                          readOnly
                          className="bg-muted cursor-not-allowed"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            {/* Warehouse and Purchase Cost */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="rounded-lg border bg-primary/5 p-4">
                <FormField
                  control={form.control}
                  name="warehouse"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium">Warehouse</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          readOnly
                          className="bg-muted cursor-not-allowed"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="rounded-lg border bg-primary/5 p-4">
                <FormField
                  control={form.control}
                  name="purchase_cost"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium">Purchase Cost (CHF)</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          step="0.01"
                          {...field}
                          readOnly
                          className="bg-muted cursor-not-allowed"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            {/* Notes */}
            <div className="rounded-lg border bg-secondary/5 p-4">
              <FormField
                control={form.control}
                name="notes"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">Notes</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Add notes regarding this shrinkage entry..."
                        className="resize-none bg-white/50 focus:bg-white"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {/* Update the Shrinkage Reason dropdown with enhanced styling and better selected value display */}
            <div className="rounded-lg border bg-secondary/5 p-4">
              <FormField
                control={form.control}
                name="shrinkage_reason"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm font-medium">Shrinkage Reason</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger className="bg-white/50 focus:bg-white">
                          <SelectValue>
                            {/* When selected, display only the label, not the description */}
                            {field.value ? SHRINKAGE_REASONS.find(r => r.value === field.value)?.label : "Select reason"}
                          </SelectValue>
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <div className="max-h-[300px] overflow-y-auto py-1">
                          {SHRINKAGE_REASONS.map((reason) => (
                            <SelectItem 
                              key={reason.value} 
                              value={reason.value}
                              className="focus:bg-accent/10 cursor-pointer"
                            >
                              <div className="flex flex-col py-1">
                                <div className="font-medium">{reason.label}</div>
                                <div className="text-xs text-muted-foreground mt-0.5">{reason.description}</div>
                              </div>
                            </SelectItem>
                          ))}
                        </div>
                      </SelectContent>
                    </Select>
                    
                    {/* Show the description of the selected reason as helper text below the field */}
                    {field.value && (
                      <FormDescription className="mt-1.5 text-xs text-muted-foreground">
                        {SHRINKAGE_REASONS.find(r => r.value === field.value)?.description}
                      </FormDescription>
                    )}
                    
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {/* Actions */}
            <div className="flex justify-end gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  resetForm();
                  setOpen(false);
                }}
                disabled={loading}
                className="min-w-[100px]"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={loading || !form.watch("selectedBatch") || !form.watch("shrinkage_reason") || 
                  (!form.watch("quantity") && !form.watch("weight_kilos"))}
                className="min-w-[100px] bg-destructive hover:bg-destructive/90 transition-all duration-200"
              >
                {loading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Creating...
                  </>
                ) : (
                  "Create Shrinkage Entry"
                )}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default AddShrinkageDialog;
