import React from 'react';
import { cn } from '@/lib/utils';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary';
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  className,
  ...props
}) => {
  return (
    <button
      className={cn(
        'px-4 py-2 rounded-lg font-semibold transition-colors duration-200',
        {
          'bg-[#304661] text-white hover:bg-[#253850]': variant === 'primary',
          'bg-[#C3D48A] text-[#304661] hover:bg-[#99AE4C]': variant === 'secondary',
        },
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};