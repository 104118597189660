import React from 'react';
import { useAuth } from "@/contexts/AuthContext";
import { useToast } from "@/components/ui/use-toast";
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { Loader2 } from 'lucide-react';
import { format, startOfMonth, subMonths } from 'date-fns';
import { useIsMobile } from "@/hooks/use-mobile";
import { MobileHeader } from "@/components/layout/MobileHeader";
import { ExportTablesButton } from "@/components/common/ExportTablesButton";
import { ExpiryMetricsGrid, type ExpiryMetric } from '@/components/dashboard/ExpiryMetrics/ExpiryMetricsGrid';
import { LocationGrid } from '@/components/dashboard/LocationInventory/LocationGrid';
import { ExpiringBatchList } from '@/components/dashboard/NextExpiring/ExpiringBatchList';
import { ExpiryTimelineChart } from '@/components/dashboard/ExpiryMetrics/ExpiryTimelineChart';
import { YearlyCostChart } from '@/components/dashboard/CostAnalysis/YearlyCostChart';
import { getAvailableQuantity, getAvailableValue, isExpired, getDaysUntilExpiry } from '@/utils/batchUtils';
import { calculateMonthlyCosts, type MonthlyCosts } from '@/utils/costAnalysis';
import type { BatchData } from '@/types/batch';

interface DashboardMetrics {
  totalValue: number;
  locationTotals: { location: string; value: number; }[];
  expiryValues: ExpiryMetric[];
  batches: BatchData[];
  monthlyCosts: MonthlyCosts[];
}

export default function Dashboard() {
  const { toast } = useToast();
  const isMobile = useIsMobile();
  const { canAccessDashboard } = useAuth();
  
  const { data: metrics, isLoading, error } = useQuery<DashboardMetrics>({
    queryKey: ['dashboard-metrics'],
    queryFn: async () => {
      // Get batches data
      const { data: batches, error: batchError } = await supabase
        .from('batches')
        .select(`
          *,
          inventory!inner(item_name, is_weight_based),
          invoice_items(
            quantity,
            weight_kilos,
            invoice:invoices (
              status
            )
          )
        `)
        // Don't filter out test batches - we need TEST-DO-NOT-DELETE to show up
        // .not('batch_number', 'ilike', '%TEST%')
        .returns<BatchData[]>();

      if (batchError) throw batchError;
      if (!batches) return {
        totalValue: 0,
        locationTotals: [],
        expiryValues: [],
        batches: [],
        monthlyCosts: []
      };

      const today = new Date();
      
      console.debug('Processing batches:', {
        today: today.toISOString(),
        totalBatches: batches.length
      });

      const locationTotals = new Map<string, number>();
      const expiryTotals = {
        'expired': { value: 0, count: 0 },
        '30 days': { value: 0, count: 0 },
        '60 days': { value: 0, count: 0 },
        '61+ days': { value: 0, count: 0 }
      };

      let totalValue = 0;

      batches.forEach(batch => {
        // Skip batches without required data
        // Basic validation
        if (!batch.expiry_date || !batch.purchase_cost) return;

        // Calculate core metrics for the batch
        const available = getAvailableQuantity(batch);
        if (available <= 0) {
          console.debug('Skipped - No quantity:', {
            batch: batch.batch_number,
            available
          });
          return;
        }

        const value = getAvailableValue(batch);
        const daysUntil = getDaysUntilExpiry(batch);
        const expired = isExpired(batch);

        console.debug('Processing batch:', {
          batch: batch.batch_number,
          available,
          value,
          daysUntil,
          expired,
          test: batch.batch_number.includes('TEST-DO-NOT-DELETE')
        });

        // Update totals
        const warehouse = batch.warehouse || 'Consignment';
        locationTotals.set(warehouse, (locationTotals.get(warehouse) || 0) + value);
        totalValue += value;

        try {
          // Categorize based on expiry
          if (expired) {
            console.debug('Adding to expired:', {
              batch: batch.batch_number,
              value,
              available
            });
            console.debug('EXPIRED:', {
              batch: batch.batch_number,
              expiry: batch.expiry_date,
              daysUntil,
              value
            });
            expiryTotals.expired.value += value;
            expiryTotals.expired.count++;
          } else if (daysUntil <= 30) {
            expiryTotals['30 days'].value += value;
            expiryTotals['30 days'].count++;
          } else if (daysUntil <= 60) {
            expiryTotals['60 days'].value += value;
            expiryTotals['60 days'].count++;
          } else {
            expiryTotals['61+ days'].value += value;
            expiryTotals['61+ days'].count++;
          }
        } catch (e) {
          console.error('Error processing batch:', {
            batch: batch.batch_number,
            expiry: batch.expiry_date,
            error: e
          });
        }
      });

      console.debug('Final metrics:', {
        today: today.toISOString(),
        expiryTotals,
        locationTotals: Object.fromEntries(locationTotals)
      });

      // Fetch invoices and their items
      let invoiceData = [];
      try {
        const { data: invoices, error: invError } = await supabase
          .from('invoices')
          .select(`
            date,
            invoice_items (
              unit_price,
              quantity,
              weight_kilos,
              measurement_type
            )
          `)
          .gte('date', startOfMonth(subMonths(today, 11)).toISOString());
        
        if (invError) throw invError;
        
        // Calculate total amounts for each invoice
        if (invoices) {
          invoiceData = invoices.map(inv => ({
            date: inv.date,
            total_amount: inv.invoice_items.reduce((sum, item) => {
              const amount = item.measurement_type === 'weight'
                ? (item.weight_kilos || 0)
                : (item.quantity || 1);
              return sum + (item.unit_price || 0) * amount;
            }, 0)
          }));
        }
      } catch (invError) {
        console.error('Error fetching invoices:', invError);
        // Continue without invoice data
      }

      // Calculate monthly costs and invoiced amounts
      const monthlyCosts = calculateMonthlyCosts(batches, invoiceData);

      return {
        totalValue,
        locationTotals: Array.from(locationTotals.entries()).map(([location, value]) => ({
          location,
          value
        })),
        expiryValues: Object.entries(expiryTotals).map(([range, { value, count }]) => ({
          range,
          value,
          count
        })),
        batches,
        monthlyCosts
      };
    }
  });

  // Handle error notifications
  React.useEffect(() => {
    if (error) {
      toast({
        variant: "destructive",
        title: "Error loading dashboard",
        description: error instanceof Error ? error.message : "An unexpected error occurred"
      });
    }
  }, [error, toast]);

  if (!canAccessDashboard) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-4rem)]">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Unauthorized Access</h1>
          <p className="text-gray-600">You do not have permission to access this page.</p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) return null;

  if (!metrics) return null;

  return (
    <>
      {isMobile ? (
        <>
          <MobileHeader title="Inventory Dashboard" />
          <div className="fixed inset-0 top-14 bg-background overflow-y-auto">
            <div className="px-4 py-3 border-b bg-white">
              <div className="text-sm font-medium">
                Total Value: CHF {Math.round(metrics.totalValue).toLocaleString()}
              </div>
              <div className="text-xs text-muted-foreground mt-0.5">
                Last updated: {format(new Date(), 'dd.MM.yyyy HH:mm')}
              </div>
            </div>
            
            <div className="p-3 pb-20">
              <ExpiryMetricsGrid metrics={metrics.expiryValues} className="mb-6" />
              <div className="space-y-6 mb-6">
                <LocationGrid totals={metrics.locationTotals} className="h-[250px]" />
                <ExpiryTimelineChart batches={metrics.batches} className="h-[300px]" />
              </div>
              <ExpiringBatchList batches={metrics.batches} />
              <YearlyCostChart data={metrics.monthlyCosts} className="mt-6" />
            </div>
          </div>
        </>
      ) : (
        <div className="p-6 space-y-6">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold mb-2">Inventory Dashboard</h1>
              <span className="text-xl font-semibold text-muted-foreground">
                Total Value: CHF {Math.round(metrics.totalValue).toLocaleString()}
              </span>
            </div>
            <div className="flex items-center gap-3">
              <span className="text-sm text-muted-foreground">
                Last updated: {format(new Date(), 'dd.MM.yyyy HH:mm')}
              </span>
              <ExportTablesButton />
            </div>
          </div>

          <ExpiryMetricsGrid metrics={metrics.expiryValues} />

          <div className="space-y-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="space-y-6">
                <LocationGrid totals={metrics.locationTotals} className="h-[250px]" />
                <ExpiryTimelineChart batches={metrics.batches} className="h-[300px]" />
              </div>
              <ExpiringBatchList batches={metrics.batches} className="h-[570px]" />
            </div>
            <YearlyCostChart data={metrics.monthlyCosts} />
          </div>
        </div>
      )}
    </>
  );
}
