import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { supabase } from '@/lib/supabase';
import { useToast } from '@/components/ui/use-toast';
import Logger from '@/utils/logger';
import { Trash2 } from 'lucide-react';
import { getAvailableQuantity } from '@/utils/batchUtils';

import type { Batch } from "@/types/batch";
import type { Invoice, InvoiceItem } from "@/types/invoice";

interface BatchData extends Batch {
  invoice_items?: InvoiceItem[];
  inventory: {
    is_weight_based: boolean;
    item_name: string;
  };
  shrinkage?: Array<{
    batch_number: string;
    weight_kilos: number | null;
    quantity: number | null;
    shrinkage_reason: string | null;
  }>;
}

interface BatchSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (selectedBatches: Array<{
    batch_number: string;
    quantity?: number;
    weight_kilos?: number;
    warehouse: string;
  }>) => void;
  sku: string;
  requiredAmount?: number;
}

export function BatchSelectionDialog({ open, onClose, onSelect, sku, requiredAmount }: BatchSelectionDialogProps) {
  const [batches, setBatches] = useState<BatchData[]>([]);
  const [allBatches, setAllBatches] = useState<BatchData[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedQuantities, setSelectedQuantities] = useState<Record<string, string>>({});
  const [isWeightBased, setIsWeightBased] = useState(false);
  const [itemName, setItemName] = useState('');
  const { toast } = useToast();

  useEffect(() => {
    if (open && sku) {
      fetchBatches();
    } else {
      setBatches([]);
      setAllBatches([]);
      setSelectedQuantities({});
    }
  }, [open, sku]);

  const fetchBatches = async () => {
    setLoading(true);
    try {
      const { data: inventoryData, error: inventoryError } = await supabase
        .from('inventory')
        .select('*')
        .eq('sku', sku)
        .single();

      if (inventoryError) throw inventoryError;
      if (!inventoryData) throw new Error('SKU not found');

      setIsWeightBased(inventoryData.is_weight_based);
      setItemName(inventoryData.item_name);
      Logger.info('Found inventory item', inventoryData);

      // Get original batches
      const { data: mainData, error: mainError } = await supabase
        .from('batches')
        .select(`
          *,
          inventory!inner(
            is_weight_based,
            item_name
          ),
          invoice_items:invoice_items(
            weight_kilos,
            quantity,
            invoice:invoices(status)
          )
        `)
        .eq('sku', sku)
        .not('batch_number', 'like', 'SHR-%')
        .order('expiry_date', { ascending: true });

      if (mainError) throw mainError;
      if (!mainData?.length) {
        toast({ title: "No Batches", description: `No batches found for SKU: ${sku}` });
        return;
      }

      // Get any shrinkage entries for these batches
      const { data: shrinkageData } = await supabase
        .from('batches')
        .select('*')
        .eq('sku', sku)
        .like('batch_number', 'SHR-%')
        .in('base_id', mainData.map(b => b.batch_number));

      // Process batches with their shrinkage entries
      const processedBatches = mainData.map(batch => ({
        ...batch,
        shrinkage: shrinkageData?.filter(s => s.base_id === batch.batch_number) || [],
        inventory: {
          is_weight_based: inventoryData.is_weight_based,
          item_name: inventoryData.item_name
        }
      })) as BatchData[];

      // Log batch processing info
      Logger.info('Processing batches', { 
        totalBatches: processedBatches.length,
        withShrinkage: processedBatches.filter(b => b.shrinkage?.length).length
      });

      // Get shrinkage batches separately
      const shrinkageBatches = (shrinkageData || []).map(batch => ({
        ...batch,
        inventory: {
          is_weight_based: inventoryData.is_weight_based,
          item_name: inventoryData.item_name
        }
      }));

      // Update all batches for availability calculations
      const fullBatchList = [...processedBatches, ...shrinkageBatches];
      setAllBatches(fullBatchList);

      // Filter main batches to get only those with available quantity
      const availableBatches = processedBatches
        .filter(batch => !batch.batch_number.startsWith('SHR-'))
        .filter(batch => {
          const available = getAvailableQuantity(batch, fullBatchList);

          Logger.debug('Batch availability check', {
            batch: batch.batch_number,
            available,
            initial: batch.quantity || batch.weight_kilos,
            has_shrinkage: batch.shrinkage?.length > 0,
            shrinkage_amount: batch.shrinkage?.reduce((sum, s) => 
              sum + (isWeightBased ? (s.weight_kilos || 0) : (s.quantity || 0)), 0)
          });

          return available > 0;
        });

      setBatches(availableBatches);
      Logger.info('Batches loaded', { 
        available: availableBatches.length,
        total: fullBatchList.length,
        shrinkage: shrinkageBatches.length
      });
    } catch (err) {
      Logger.error('Error in fetchBatches', err);
      toast({
        title: "Error",
        description: "Failed to load batches",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  const calculateAvailableAmount = (batch: BatchData): number => {
    const available = getAvailableQuantity(batch, allBatches);

    Logger.debug('Batch availability calculation', {
      batch: batch.batch_number,
      available,
      initial: batch.quantity || batch.weight_kilos,
      has_shrinkage: batch.shrinkage?.length > 0,
      total_batches: allBatches.length
    });

    return Math.max(0, available);
  };

  const handleConfirm = () => {
    const selectedBatches = Object.entries(selectedQuantities)
      .filter(([_, amount]) => amount && parseFloat(amount) > 0)
      .map(([batchNumber, amount]) => {
        const batch = batches.find(b => b.batch_number === batchNumber);
        if (!batch) return null;

        const amountNum = parseFloat(amount);
        const availableAmount = calculateAvailableAmount(batch);

        if (amountNum > availableAmount) {
          toast({
            title: "Invalid Amount",
            description: `Maximum available for batch ${batchNumber}: ${availableAmount}${isWeightBased ? ' kg' : ''}`
          });
          return null;
        }

        return {
          batch_number: batch.batch_number,
          ...(isWeightBased ? { weight_kilos: amountNum } : { quantity: amountNum }),
          warehouse: batch.warehouse
        };
      })
      .filter((batch): batch is NonNullable<typeof batch> => batch !== null);

    if (selectedBatches.length === 0) {
      toast({
        title: "No Batches Selected",
        description: "Please enter valid amounts for at least one batch"
      });
      return;
    }

    onSelect(selectedBatches);
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogTitle>Select Batches for SKU: {sku}</DialogTitle>
        <div className="text-sm text-gray-500 mb-4">
          Enter amounts for the batches you want to use
        </div>

        {loading ? (
          <div className="flex justify-center p-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
          </div>
        ) : (
          <div className="space-y-4">
            {batches.map((batch) => {
              const availableAmount = calculateAvailableAmount(batch);

              return (
                <div key={batch.batch_number} className="bg-white border border-gray-200 p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                  <div className="flex items-center justify-between mb-2">
                    <div className="font-semibold text-lg text-gray-900">{itemName}</div>
                  </div>
                  <div className="space-y-1.5 text-sm text-gray-600">
                    <div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <span className="font-medium">Batch:</span>
                          <span>{batch.batch_number}</span>
                          {/* Initial amount */}
                          <span className="ml-2 text-xs px-2 py-0.5 bg-gray-100 rounded">
                            Initial: {isWeightBased ? batch.weight_kilos : batch.quantity}
                            {isWeightBased ? ' kg' : ' units'}
                          </span>
                          {/* Show inventory movements */}
                          {((batch.invoice_items?.length || 0) > 0 || (batch.shrinkage?.length || 0) > 0) && (
                            <div className="ml-2 text-xs px-2 py-0.5 text-gray-600">
                              {batch.shrinkage?.length ? (
                                <span className="text-amber-700">
                                  (Shrinkage: {isWeightBased ? 
                                    batch.shrinkage.reduce((sum, s) => sum + (s.weight_kilos || 0), 0) :
                                    batch.shrinkage.reduce((sum, s) => sum + (s.quantity || 0), 0)
                                  } {isWeightBased ? 'kg' : 'units'})
                                </span>
                              ) : null}
                            </div>
                          )}
                        </div>
                        <div className={`text-sm font-medium ${
                          requiredAmount && availableAmount < requiredAmount
                            ? 'text-amber-600'
                            : 'text-gray-600'
                        }`}>
                          Available: {availableAmount} {isWeightBased ? 'kg' : ''}
                        </div>
                      </div>
                      {requiredAmount && availableAmount < requiredAmount && (
                        <div className="mt-2 text-sm text-amber-600 bg-amber-50 p-2 rounded-md">
                          Insufficient stock. Required: {requiredAmount} {isWeightBased ? 'kg' : 'units'}.
                          Please select a different batch with sufficient stock or create a new batch.
                        </div>
                      )}
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Location:</span>
                      <span>{batch.warehouse}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Expires:</span>
                      <span>{new Date(batch.expiry_date).toLocaleDateString()}</span>
                    </div>
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {isWeightBased ? "Weight (kg)" : "Quantity"}
                    </label>
                    <div className="flex items-center gap-2">
                      <Input
                        type="number"
                        value={selectedQuantities[batch.batch_number] || ''}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === '' || (isWeightBased ? /^\d*\.?\d*$/.test(value) : /^\d*$/.test(value))) {
                            setSelectedQuantities(prev => ({
                              ...prev,
                              [batch.batch_number]: value
                            }));
                          }
                        }}
                        className="w-32 border-gray-300 focus:border-blue-500 focus:ring-blue-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                        placeholder="Enter amount"
                      />
                      <button
                        onClick={() => {
                          setSelectedQuantities(prev => {
                            const newQuantities = { ...prev };
                            delete newQuantities[batch.batch_number];
                            return newQuantities;
                          });
                        }}
                        className="text-red-600 hover:text-red-800 p-1 rounded-md hover:bg-red-50"
                        title="Remove batch"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <DialogFooter className="flex justify-end gap-2 mt-6">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            onClick={handleConfirm}
            disabled={Object.values(selectedQuantities).every(amount => !amount || parseFloat(amount) <= 0)}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
