import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { 
  BatchWithInventory, 
  NotificationState, 
  DEFAULT_NOTIFICATION_STATE,
  Notification
} from '@/types/notification';
import { getAvailableQuantity, getAvailableValue, getDaysUntilExpiry } from '@/utils/batchUtils';

interface NotificationsContextType {
  state: NotificationState;
  unreadCount: number;
  markAsRead: (type: 'expiring' | 'newStock', batchNumber: string) => void;
  markAllAsRead: (type: 'expiring' | 'newStock') => void;
  toggleExpanded: (type: 'expiring' | 'newStock') => void;
  playNotificationSound: boolean;
  setPlayNotificationSound: (play: boolean) => void;
}

const NotificationsContext = createContext<NotificationsContextType | undefined>(undefined);

const STORAGE_KEY_PREFIX = 'notifications_read_status';

const formatNotification = (batch: BatchWithInventory): Notification => ({
  batchNumber: batch.batch_number,
  sku: batch.sku,
  itemName: batch.inventory.item_name,
  expiryDate: new Date(batch.expiry_date),
  warehouse: batch.warehouse,
  quantity: batch.inventory.is_weight_based ? undefined : getAvailableQuantity(batch),
  weightKilos: batch.inventory.is_weight_based ? getAvailableQuantity(batch) : undefined,
  purchaseCost: batch.purchase_cost,
  totalValue: getAvailableValue(batch),
  createdAt: new Date(batch.created_at),
  daysUntilExpiry: getDaysUntilExpiry(batch)
});

export function NotificationsProvider({ children }: { children: React.ReactNode }) {
  const { role } = useAuth();
  const [state, setState] = useState<NotificationState>(DEFAULT_NOTIFICATION_STATE);
  const [playNotificationSound, setPlayNotificationSound] = useState(false);

  // Initialize read status from storage for each section
  useEffect(() => {
    ['expiring', 'newStock'].forEach((type) => {
      const saved = localStorage.getItem(`${STORAGE_KEY_PREFIX}_${type}`);
      if (saved) {
        const readStatus = JSON.parse(saved);
        setState(prev => ({
          ...prev,
          [type]: {
            ...prev[type as keyof NotificationState],
            readStatus
          }
        }));
      }
    });
  }, []);

  // Calculate total unread count
  const unreadCount = 
    state.expiring.items.filter(n => !state.expiring.readStatus[n.batchNumber]).length +
    state.newStock.items.filter(n => !state.newStock.readStatus[n.batchNumber]).length;

  const processNotifications = (batches: BatchWithInventory[]): NotificationState => {
    const availableBatches = batches.filter(batch => getAvailableQuantity(batch) > 0);

    // Process expiring items
    const expiringItems = availableBatches
      .filter(batch => {
        const daysUntil = getDaysUntilExpiry(batch);
        return daysUntil <= 7; // Including expired items (negative days)
      })
      .sort((a, b) => getDaysUntilExpiry(a) - getDaysUntilExpiry(b))
      .slice(0, 15)
      .map(formatNotification);

    // Process new stock (last 48 hours only)
    const fortyEightHoursAgo = new Date(Date.now() - 48 * 60 * 60 * 1000);
    const newStockItems = availableBatches
      .filter(batch => new Date(batch.created_at) > fortyEightHoursAgo)
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      .slice(0, 15)
      .map(formatNotification);

    return {
      expiring: {
        ...state.expiring,
        items: expiringItems
      },
      newStock: {
        ...state.newStock,
        items: newStockItems
      }
    };
  };

  // Fetch notifications
  useEffect(() => {
    const fetchNotifications = async () => {
      const { data, error } = await supabase
        .from('batches')
        .select(`
          *,
          inventory(item_name, is_weight_based),
          invoice_items(quantity, weight_kilos)
        `);

      if (error || !data || !data.length) return;

      const batchesWithInventory = data
        .map(row => {
          if (!row.inventory) return null;
          return {
            ...row,
            inventory: Array.isArray(row.inventory) ? row.inventory[0] : row.inventory
          };
        })
        .filter(Boolean) as BatchWithInventory[];

      const newState = processNotifications(batchesWithInventory);
      setState(prev => ({
        expiring: { ...prev.expiring, items: newState.expiring.items },
        newStock: { ...prev.newStock, items: newState.newStock.items }
      }));

      // Check for unread notifications
      const hasUnread = Object.values(newState).some(section =>
        section.items.some(n => !section.readStatus[n.batchNumber])
      );
      
      if (hasUnread) {
        setPlayNotificationSound(true);
      }
    };

    fetchNotifications();

    const subscription = supabase
      .channel('batches_notifications')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'batches' }, fetchNotifications)
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [role]);

  const markAsRead = (type: 'expiring' | 'newStock', batchNumber: string) => {
    setState(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        readStatus: {
          ...prev[type].readStatus,
          [batchNumber]: true
        }
      }
    }));

    // Update storage for the specific type
    const updatedReadStatus = {
      ...state[type].readStatus,
      [batchNumber]: true
    };
    localStorage.setItem(`${STORAGE_KEY_PREFIX}_${type}`, JSON.stringify(updatedReadStatus));
  };

  const markAllAsRead = (type: 'expiring' | 'newStock') => {
    const updatedReadStatus = state[type].items.reduce((acc, item) => ({
      ...acc,
      [item.batchNumber]: true
    }), state[type].readStatus);

    setState(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        readStatus: updatedReadStatus
      }
    }));

    localStorage.setItem(`${STORAGE_KEY_PREFIX}_${type}`, JSON.stringify(updatedReadStatus));
  };

  const toggleExpanded = (type: 'expiring' | 'newStock') => {
    setState(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        expanded: !prev[type].expanded
      }
    }));
  };

  const value = {
    state,
    unreadCount,
    markAsRead,
    markAllAsRead,
    toggleExpanded,
    playNotificationSound,
    setPlayNotificationSound
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
}

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
};
