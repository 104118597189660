import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import { UserRole } from '@/types/auth';
import { useToast } from "@/components/ui/use-toast";
import { useLocation } from 'react-router-dom';
import { supabase } from '@/lib/supabase';

interface GoogleUser {
  email: string;
  name?: string;
  picture?: string;
}

interface AuthContextType {
  user: GoogleUser | null;
  role: UserRole | null;
  isAdmin: boolean;
  isInventory: boolean;
  isGuest: boolean;
  isInbound: boolean;
  canEdit: boolean;
  canAddBatches: boolean;
  canManageInventory: boolean;
  canViewInvoices: boolean;
  canManageInvoices: boolean;  // New permission
  canAccessDashboard: boolean; // Added for dashboard access control
  signInWithGoogle: () => void;
  signOut: () => Promise<void>;
  isInitialized: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<GoogleUser | null>(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [role, setRole] = useState<UserRole | null>(() => {
    const savedRole = localStorage.getItem('role');
    return savedRole ? JSON.parse(savedRole) : null;
  });
  const [isInitialized, setIsInitialized] = useState(false);

  const { toast } = useToast();
  const location = useLocation();

  // Function to check user authorization in Supabase
  const checkUserAuthorization = async (email: string): Promise<UserRole | null> => {
    try {
      const { data: user, error } = await supabase
        .from('authorized_users')
        .select('role, is_active')
        .eq('email', email)
        .single();

      if (error) throw error;

      if (!user || !user.is_active) {
        return null;
      }

      return user.role as UserRole;
    } catch (error) {
      console.error('Error checking user authorization:', error);
      return null;
    }
  };

  // Function to fetch user info
  const fetchUserInfo = async (accessToken: string) => {
    try {
      const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch user info');
      }
      
      const userInfo = await response.json();
      
      const newUser = {
        email: userInfo.email,
        name: userInfo.name,
        picture: userInfo.picture
      };
      
      // Check authorization in Supabase
      const userRole = await checkUserAuthorization(userInfo.email);

      if (userRole) {
        setUser(newUser);
        setRole(userRole);
        localStorage.setItem('user', JSON.stringify(newUser));
        localStorage.setItem('role', JSON.stringify(userRole));
      } else {
        console.warn(`Unauthorized access attempt from email: ${userInfo.email}`);
        setUser(null);
        setRole(null);
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        
        toast({
          variant: "destructive",
          title: "Access Denied",
          description: "Sorry, you are not authorized to access Chefland IMS. Please contact support.",
        });
        
        // Immediately sign out unauthorized users
        signOut();
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
      setUser(null);
      setRole(null);
      localStorage.removeItem('user');
      localStorage.removeItem('role');
    }
  };

  // Handle Google sign in
  const signInWithGoogle = useGoogleLogin({
    onSuccess: async (response: TokenResponse) => {
      await fetchUserInfo(response.access_token);
    },
    onError: (error) => {
      console.error('Login Failed:', error);
      setUser(null);
      setRole(null);
      localStorage.removeItem('user');
      localStorage.removeItem('role');
    },
    flow: 'implicit'
  });

  // Sign out
  const signOut = async () => {
    setUser(null);
    setRole(null);
    localStorage.removeItem('user');
    localStorage.removeItem('role');
  };

  // Refresh role from Supabase periodically
  useEffect(() => {
    if (user?.email) {
      const interval = setInterval(async () => {
        const refreshedRole = await checkUserAuthorization(user.email);
        if (!refreshedRole) {
          // User has been deactivated or removed
          signOut();
          toast({
            variant: "destructive",
            title: "Access Revoked",
            description: "Your access has been revoked. Please contact support.",
          });
        } else if (refreshedRole !== role) {
          // Role has changed
          setRole(refreshedRole);
          localStorage.setItem('role', JSON.stringify(refreshedRole));
        }
      }, 5 * 60 * 1000); // Check every 5 minutes

      return () => clearInterval(interval);
    }
  }, [user?.email, role]);

  // Set initialized after first render
  useEffect(() => {
    setIsInitialized(true);
  }, []);

  // Updated value with new role names
  const value = {
    user,
    role,
    isAdmin: role === 'admin',
    isInventory: role === 'inventory',
    isGuest: role === 'guest',
    isInbound: role === 'inbound',
    canEdit: role === 'admin' || role === 'invoice' ||
            (role === 'inventory' && !location.pathname.includes('/invoices')) ||
            (role === 'inbound' && location.pathname.includes('/batches')),
    canAddBatches: role === 'admin' || role === 'invoice' || role === 'inventory' || role === 'inbound',
    canManageInventory: role === 'admin' || role === 'invoice' || role === 'inventory',
    canViewInvoices: role === 'admin' || role === 'invoice',
    canManageInvoices: role === 'admin' || role === 'invoice', // New permission for invoice management
    canAccessDashboard: role === 'admin' || role === 'invoice', // Only admin and invoice roles can access dashboard
    signInWithGoogle,
    signOut,
    isInitialized
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Named export for the hook
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
