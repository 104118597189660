import React, { useState, useEffect } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ViewInvoiceDialog } from "./ViewInvoiceDialog";
import { EditInvoiceDialog } from "./EditInvoiceDialog";
import { AddInvoiceDialog } from "./AddInvoiceDialog";
import DeliveryNotesModal from "@/components/DeliveryNotesModal";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import Logger from "@/utils/logger";
import { Search, Columns3, Trash2, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, ChevronUp, ChevronDown } from "lucide-react";
import { useAuth } from '@/contexts/AuthContext';
import type { Invoice } from "@/types/invoice";
import { cn } from "@/lib/utils";

// Constants
const PAGE_SIZE_KEY = "invoices-table-page-size";
const DEFAULT_PAGE_SIZE = 20;
const PAGE_SIZE_OPTIONS = [20, 50, 100, 200];

interface EnhancedInvoicesTableProps {
  invoices: Invoice[];
  onDelete: () => Promise<void>;
}

export function EnhancedInvoicesTable({ 
  invoices, 
  onDelete 
}: EnhancedInvoicesTableProps) {
  const { toast } = useToast();
  const { canEdit, canManageInvoices } = useAuth();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);

  // Table state
  const [sorting, setSorting] = useState<SortingState>([
    { id: "date", desc: true }
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [pageSize, setPageSize] = useState(() => {
    try {
      const saved = localStorage.getItem(PAGE_SIZE_KEY);
      return saved ? parseInt(saved, 10) : DEFAULT_PAGE_SIZE;
    } catch {
      return DEFAULT_PAGE_SIZE;
    }
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize
  });

  // Persist page size changes
  useEffect(() => {
    localStorage.setItem(PAGE_SIZE_KEY, pageSize.toString());
  }, [pageSize]);

  // Reset page index when filters change
  useEffect(() => {
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
  }, [globalFilter, columnFilters]);

  const handleDelete = async (invoice: Invoice) => {
    try {
      Logger.info('Starting invoice deletion', { invoice });

      // Delete invoice items first
      const { error: itemsError } = await supabase
        .from('invoice_items')
        .delete()
        .eq('invoice_id', invoice.id);

      if (itemsError) {
        Logger.error('Error deleting invoice items', itemsError);
        throw itemsError;
      }

      // Delete the invoice
      const { error: invoiceError } = await supabase
        .from('invoices')
        .delete()
        .eq('id', invoice.id);

      if (invoiceError) {
        Logger.error('Error deleting invoice', invoiceError);
        throw invoiceError;
      }

      toast({
        title: "Success",
        description: "Invoice deleted successfully"
      });

      onDelete();
    } catch (error) {
      const err = error as Error;
      Logger.error('Error in handleDelete', err);
      toast({
        variant: "destructive",
        title: "Error deleting invoice",
        description: err.message
      });
    }
  };

  // Column definitions
  const columns: ColumnDef<Invoice>[] = [
    {
      accessorKey: "date",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Date</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => (
        <div className="text-sm text-gray-900">
          {new Date(row.getValue("date")).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
          })}
        </div>
      )
    },
    {
      accessorKey: "invoice_number",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Delivery Note #</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => {
        const invoice = row.original;
        return (
          <div className="flex items-center gap-3">
            <button
              className="text-sm font-medium hover:text-blue-600 transition-colors"
              onClick={() => setSelectedInvoice(invoice)}
            >
              {invoice.invoice_number}
            </button>
            {invoice.items && invoice.items.length > 0 && (
              <HoverCard>
                <HoverCardTrigger>
                  <span className="text-[11px] text-gray-500 bg-gray-100 px-1.5 py-0.5 rounded-full hover:bg-gray-200 transition-colors cursor-help">
                    {invoice.items.length} items
                  </span>
                </HoverCardTrigger>
                <HoverCardContent className="w-80 p-0 shadow-lg bg-white">
                  <div className="p-3 border-b bg-blue-50/50">
                    <h4 className="font-medium text-sm text-blue-900">Delivery Note Items</h4>
                    <p className="text-xs text-blue-700/75">DN #{invoice.invoice_number}</p>
                  </div>
                  <div className="max-h-[250px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-50">
                    {invoice.items.map((item, index) => (
                      <div key={index} className="p-2.5 border-b last:border-b-0 bg-white hover:bg-gray-50/75">
                        <div className="flex justify-between items-start">
                          <div>
                            <p className="text-xs font-medium">{item.item_name || 'Unknown Item'}</p>
                            <p className="text-[11px] text-gray-500">SKU: {item.sku}</p>
                          </div>
                          <span className="text-[11px] font-medium text-gray-900">
                            CHF {Number(item.unit_price || 0).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </HoverCardContent>
              </HoverCard>
            )}
          </div>
        );
      }
    },
    {
      accessorKey: "company",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Company Name</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => (
        <button 
          onClick={() => setSelectedInvoice(row.original)}
          className="text-sm hover:text-blue-600 transition-colors"
        >
          {row.getValue("company")}
        </button>
      )
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Status</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => {
        const status = row.getValue("status") as string;
        return (
          <div className={cn(
            "text-sm",
            status === 'IMS'
              ? "text-blue-600"
              : "text-amber-600"
          )}>
            {status}
          </div>
        );
      }
    },
    {
      accessorKey: "total_amount",
      header: ({ column }) => (
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          <span>Amount</span>
          {column.getIsSorted() === "asc" ? (
            <ChevronUp className="w-4 h-4" />
          ) : column.getIsSorted() === "desc" ? (
            <ChevronDown className="w-4 h-4" />
          ) : null}
        </div>
      ),
      cell: ({ row }) => (
        <div className="text-right">
          <span className="text-sm font-medium">
            CHF {(row.getValue("total_amount") as number)?.toFixed(2) || '0.00'}
          </span>
        </div>
      )
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => {
        const invoice = row.original;
        return (
          <div className="flex items-center gap-2 justify-end">
            {canManageInvoices && (
              <>
                <EditInvoiceDialog invoice={invoice} onInvoiceUpdated={onDelete} />
                <button
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this invoice?')) {
                      handleDelete(invoice);
                    }
                  }}
                  className="text-red-600 hover:text-red-800 p-1 rounded-md hover:bg-red-50"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </>
            )}
          </div>
        );
      }
    }
  ];

  // Table configuration
  const table = useReactTable({
    data: invoices,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      globalFilter,
      pagination
    }
  });

  return (
    <div className="space-y-4">
      {/* Search, Column Visibility and Actions */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="relative w-[300px]">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search all columns..."
              value={globalFilter ?? ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
              className="pl-8 h-9" // Match button height
            />
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="sm" className="h-9">
                <Columns3 className="mr-2 h-4 w-4" />
                Columns
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="bg-white border rounded-md shadow-md w-[180px] p-0"
            >
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  const headerLabels: { [key: string]: string } = {
                    'date': 'Date',
                    'invoice_number': 'Delivery Note Number',
                    'company': 'Company Name',
                    'status': 'Status',
                    'total_amount': 'Amount',
                    'actions': 'Actions'
                  };
                  
                  const label = headerLabels[column.id] || column.id;

                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="relative flex select-none items-center py-2 pl-8 pr-4 text-sm outline-none hover:bg-gray-100 cursor-pointer data-[state=checked]:bg-gray-50"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) => column.toggleVisibility(!!value)}
                    >
                      {label}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        
        {/* Action Buttons */}
        <div className="flex items-center gap-2 h-9">
          <AddInvoiceDialog onInvoiceAdded={onDelete} />
          <DeliveryNotesModal />
        </div>
      </div>

      {/* Table */}
      <div className="mt-4 rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="bg-gray-50">
                {headerGroup.headers.map((header) => (
                  <TableHead 
                    key={header.id}
                    className={cn(
                      "h-8 py-1 px-4 text-gray-900 text-sm font-medium",
                      header.column.getCanSort() &&
                      "transition-colors hover:bg-gray-100"
                    )}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className="hover:bg-gray-50/75"
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="h-8 py-1 px-4">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {/* Pagination Controls */}
      <div className="flex items-center justify-between space-x-2 py-4">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">Rows per page</p>
          <Select
            value={`${pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value));
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={pageSize} />
            </SelectTrigger>
            <SelectContent side="top" className="bg-white border rounded-md shadow-md p-1">
              {PAGE_SIZE_OPTIONS.map((size) => (
                <SelectItem key={size} value={`${size}`} className="cursor-pointer">
                  {size}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center space-x-2">
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to first page</span>
              <ChevronsLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRight className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to last page</span>
              <ChevronsRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>

      {/* View Invoice Dialog */}
      {selectedInvoice && (
        <ViewInvoiceDialog
          open={!!selectedInvoice}
          onClose={() => setSelectedInvoice(null)}
          invoice={selectedInvoice}
        />
      )}
    </div>
  );
}