import { supabase } from '../lib/supabase';
import { DeliveryNote, ValidatedPosition, ImportStatus, ValidatedDeliveryNote } from '../types/delivery-note';
import Logger from '../utils/logger';
import { validatePosition, transformDeliveryNote } from '../utils/validation';

interface ImportResult {
    success: boolean;
    message: string;
    invoiceId?: number;
    error?: any;
}

export class ImportService {
    static async checkExistingInvoice(deliveryNumber: string): Promise<boolean> {
        // Transform the number to sanitized format
        const { sanitized } = transformDeliveryNote(deliveryNumber);
        
        const { data } = await supabase
            .from('invoices')
            .select('id')
            .eq('invoice_number', sanitized)
            .maybeSingle();
        
        return !!data;
    }

    static async validateNote(
        note: DeliveryNote,
        previousPositions?: ValidatedPosition[]
    ): Promise<ValidatedPosition[]> {
        const validatedPositions = await Promise.all(
            note.positions
                .filter(pos => pos.number && pos.catalog_type !== 'S')
                .map(async position => {
                    const prevState = previousPositions?.find(
                        p => p.position.number === position.number
                    );
                    
                    const validated = await validatePosition(position, prevState);
                    
                    return {
                        ...validated,
                        skipped: prevState?.skipped || false
                    };
                })
        );

        return validatedPositions;
    }

    static async importNote(
        note: ValidatedDeliveryNote,
        validatedPositions: ValidatedPosition[]
    ): Promise<ImportResult> {
        try {
            // Count items by status
            const stats = {
                total: validatedPositions.length,
                skipped: validatedPositions.filter(p => p.skipped).length,
                withBatch: validatedPositions.filter(p => p.batchMatch.matchedBatch).length,
                withErrors: validatedPositions.filter(p => p.errors.length > 0).length,
                unhandled: validatedPositions.filter(p => 
                    !p.skipped && 
                    !p.batchMatch.matchedBatch && 
                    p.errors.length === 0
                ).length
            };

            Logger.info('Position analysis', {
                note_number: note.number,
                ...stats
            });

            // Log incoming positions
            Logger.info('ImportService: Processing positions for import', {
                noteNumber: note.number,
                totalPositions: validatedPositions.length,
                skippedCount: validatedPositions.filter(p => p.skipped).length,
                batchedCount: validatedPositions.filter(p => p.batchMatch.matchedBatch).length,
                errorCount: validatedPositions.filter(p => p.errors.length > 0).length
            });

            // Get items that should be imported (not skipped and have batch)
            const itemsToImport = validatedPositions.filter(p =>
                !p.skipped &&
                p.batchMatch.matchedBatch &&
                p.errors.length === 0
            );

            Logger.info('ImportService: Filtered items for import', {
                noteNumber: note.number,
                itemsToImport: itemsToImport.length,
                skippedItems: validatedPositions.length - itemsToImport.length
            });

            // Proceed only if we have items to import
            if (itemsToImport.length === 0) {
                // No items to import (all skipped or none ready)
                return {
                    success: false,
                    message: 'No items ready to import'
                };
            }

            // Transform delivery note number
            const transformation = transformDeliveryNote(note.number);
            const isReimport = await ImportService.checkExistingInvoice(note.number);

            // Just one simple header followed by raw Small Invoice content
            const descriptions = itemsToImport
                .map(item => item.position.description?.replace(/<[^>]*>/g, '').trim())
                .filter(Boolean);
            
            const notesText = [
                'IMPORTED FROM SMALL INVOICE',
                '',
                ...descriptions
            ].join('\n');

            // Start a Supabase transaction
            const { data: invoice, error: invoiceError } = await supabase
                .from('invoices')
                .upsert({
                    invoice_number: transformation.sanitized,
                    date: note.date,
                    company: note.contact?.name || 'N/A',
                    notes: notesText,
                    status: note.status,
                    updated_at: new Date().toISOString(),
                    created_at: isReimport ? undefined : new Date().toISOString()
                }, {
                    onConflict: 'invoice_number',
                    ignoreDuplicates: false
                })
                .select()
                .single();

            if (invoiceError) {
                Logger.error('Failed to upsert invoice:', invoiceError);
                throw new Error('Failed to create/update invoice');
            }

            // Delete existing items for this invoice
            const { error: deleteError } = await supabase
                .from('invoice_items')
                .delete()
                .eq('invoice_id', invoice.id);

            if (deleteError) {
                Logger.error('Failed to delete existing items:', deleteError);
                throw new Error('Failed to update invoice items');
            }

            // Prepare items for insert
            const itemsData = itemsToImport.map(item => ({
                invoice_id: invoice.id,
                sku: item.position.number!,
                quantity: item.measurementType === 'quantity' 
                    ? Math.round(Number(item.position.amount))
                    : null,
                weight_kilos: item.measurementType === 'weight'
                    ? Number(Number(item.position.amount).toFixed(3))
                    : null,
                unit_price: item.position.price,
                measurement_type: item.measurementType,
                batch_number: item.batchMatch.matchedBatch!,
                warehouse: '(+3°C)',
                created_at: new Date().toISOString()
            }));

            // Insert all items
            const { error: itemsError } = await supabase
                .from('invoice_items')
                .insert(itemsData);

            if (itemsError) {
                // Clean up invoice if items fail
                await supabase
                    .from('invoices')
                    .delete()
                    .eq('id', invoice.id);
                
                Logger.error('Failed to insert items:', itemsError);
                throw new Error('Failed to create invoice items');
            }

            // Build success message
            let message = `Imported ${itemsToImport.length} item${itemsToImport.length !== 1 ? 's' : ''}`;
            if (stats.skipped > 0) {
                message += ` (${stats.skipped} skipped)`;
            }

            Logger.info('Import successful', {
                note_number: note.number,
                invoice_id: invoice.id,
                imported: itemsToImport.length,
                skipped: stats.skipped
            });

            return {
                success: true,
                message,
                invoiceId: invoice.id
            };

        } catch (error) {
            Logger.error('Import failed', {
                note_number: note.number,
                error: error instanceof Error ? error.message : 'Unknown error'
            });

            return {
                success: false,
                message: error instanceof Error ? error.message : 'Import failed',
                error
            };
        }
    }

    static async importNotes(notes: ValidatedDeliveryNote[]): Promise<ImportResult[]> {
        const results: ImportResult[] = [];

        for (const note of notes) {
            try {
                Logger.info('Starting note import', {
                    number: note.number,
                    date: note.date
                });

                // Verify note has validated positions
                if (!note.validatedPositions) {
                    throw new Error('Note has not been validated');
                }

                // Check valid state
                const validationStats = {
                    total: note.validatedPositions.length,
                    skipped: note.validatedPositions.filter(p => p.skipped).length,
                    withBatch: note.validatedPositions.filter(p => p.batchMatch?.matchedBatch).length,
                    withErrors: note.validatedPositions.filter(p => p.errors?.length > 0).length,
                    unhandled: note.validatedPositions.filter(p => 
                        !p.skipped && 
                        !p.batchMatch?.matchedBatch && 
                        !(p.errors?.length > 0)
                    ).length
                };

                Logger.info('Note validation stats', {
                    ...validationStats,
                    number: note.number
                });

                // Proceed with import if we have valid items
                if (validationStats.withBatch > 0) {
                    const result = await this.importNote(note, note.validatedPositions);
                    results.push(result);
                } else {
                    throw new Error('No items ready to import');
                }

            } catch (error) {
                Logger.error('Note import failed', {
                    number: note.number,
                    error: error instanceof Error ? error.message : 'Unknown error'
                });

                results.push({
                    success: false,
                    message: `Failed to import note ${note.number}: ${error instanceof Error ? error.message : 'Unknown error'}`,
                    error
                });
            }
        }

        return results;
    }
}
