import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/contexts/AuthContext";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { MobileHeader } from "@/components/layout/MobileHeader";
import { AddUserDialog } from "@/components/users/AddUserDialog";
import { EditUserDialog } from "@/components/users/EditUserDialog";
import { Card } from "@/components/ui/card";

// Types for our users
interface User {
  id: string;
  email: string;
  role: 'admin' | 'inventory' | 'guest' | 'inbound' | 'invoice';
  is_active: boolean;
  display_name?: string;
  created_at: string;
}

const CompactPermissionsMatrix = () => {
  const roles = [
    {
      name: 'Admin',
      color: 'bg-purple-100 text-purple-800 border-purple-200',
      permissions: [
        { name: 'Access', access: 'Full access to all features including user management and dashboard' },
        { name: 'Restrictions', access: 'None - has all permissions' }
      ]
    },
    {
      name: 'Invoice',
      color: 'bg-amber-100 text-amber-800 border-amber-200',
      permissions: [
        { name: 'Access', access: <>Full access to <span className="font-medium">Inventory</span>, <span className="font-medium">Batches</span>, <span className="font-medium">Invoices</span>, and <span className="font-medium">Dashboard</span></> },
        { name: 'Restrictions', access: 'Cannot manage users' }
      ]
    },
    {
      name: 'Inventory',
      color: 'bg-blue-100 text-blue-800 border-blue-200',
      permissions: [
        { name: 'Access', access: <>Full access to <span className="font-medium">Inventory</span> and <span className="font-medium">Batches</span></> },
        { name: 'Restrictions', access: <>No access to <span className="font-medium">Invoices</span> and <span className="font-medium">Dashboard</span></> }
      ]
    },
    {
      name: 'Inbound',
      color: 'bg-green-100 text-green-800 border-green-200',
      permissions: [
        { name: 'Access', access: <>Full access to <span className="font-medium">Batches</span></> },
        { name: 'Restrictions', access: <>View-only for <span className="font-medium">Inventory</span>, no access to <span className="font-medium">Dashboard</span> or <span className="font-medium">Invoices</span></> }
      ]
    },
    {
      name: 'Guest',
      color: 'bg-gray-100 text-gray-800 border-gray-200',
      permissions: [
        { name: 'Access', access: <>View-only access to <span className="font-medium">Inventory</span> and <span className="font-medium">Batches</span></> },
        { name: 'Restrictions', access: <>No access to <span className="font-medium">Dashboard</span>, <span className="font-medium">Invoices</span>, or user management</> }
      ]
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
      {roles.map((role) => (
        <div key={role.name} className="bg-white rounded-lg border p-4 hover:shadow-sm transition-shadow">
          <div className="mb-3">
            <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${role.color}`}>
              {role.name}
            </span>
          </div>
          <div className="space-y-3">
            {role.permissions.map((perm, index) => (
              <div key={index} className="text-sm">
                <div className="font-medium text-gray-700">{perm.name}</div>
                <div className={`text-gray-600 mt-0.5 leading-relaxed ${index === 0 ? 'min-h-[5rem]' : ''}`}>{perm.access}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const RoleList = () => (
  <div className="px-4 py-2 bg-gray-50/50 mb-4 text-sm">
    <h2 className="font-medium mb-2">Role Permissions</h2>
    <ul className="space-y-2.5">
      <li className="flex gap-3">
        <div className="w-[60px] flex justify-end">
          <span className="inline-flex items-center px-1.5 py-0.5 rounded-full bg-purple-100 text-purple-800 text-xs font-semibold">Admin</span>
        </div>
        <span className="text-gray-600 text-xs flex-1">Full access to all features including user management and dashboard</span>
      </li>
      <li className="flex gap-3">
        <div className="w-[60px] flex justify-end">
          <span className="inline-flex items-center px-1.5 py-0.5 rounded-full bg-amber-100 text-amber-800 text-xs font-semibold">Invoice</span>
        </div>
        <span className="text-gray-600 text-xs flex-1">Full access to <span className="font-medium">Inventory</span>, <span className="font-medium">Batches</span>, <span className="font-medium">Invoices</span>, and <span className="font-medium">Dashboard</span>. Cannot manage users.</span>
      </li>
      <li className="flex gap-3">
        <div className="w-[60px] flex justify-end">
          <span className="inline-flex items-center px-1.5 py-0.5 rounded-full bg-blue-100 text-blue-800 text-xs font-semibold">Inventory</span>
        </div>
        <span className="text-gray-600 text-xs flex-1">Full access to <span className="font-medium">Inventory</span> and <span className="font-medium">Batches</span>. No access to <span className="font-medium">Invoices</span> or <span className="font-medium">Dashboard</span>.</span>
      </li>
      <li className="flex gap-3">
        <div className="w-[60px] flex justify-end">
          <span className="inline-flex items-center px-1.5 py-0.5 rounded-full bg-green-100 text-green-800 text-xs font-semibold">Inbound</span>
        </div>
        <span className="text-gray-600 text-xs flex-1">Full access to <span className="font-medium">Batches</span>. View-only for <span className="font-medium">Inventory</span>. No access to <span className="font-medium">Dashboard</span> or <span className="font-medium">Invoices</span>.</span>
      </li>
      <li className="flex gap-3">
        <div className="w-[60px] flex justify-end">
          <span className="inline-flex items-center px-1.5 py-0.5 rounded-full bg-gray-100 text-gray-800 text-xs font-semibold">Guest</span>
        </div>
        <span className="text-gray-600 text-xs flex-1">View-only access to <span className="font-medium">Inventory</span> and <span className="font-medium">Batches</span>. No access to <span className="font-medium">Dashboard</span> or <span className="font-medium">Invoices</span>.</span>
      </li>
    </ul>
  </div>
);

const Users = () => {
  const [filterText, setFilterText] = useState("");
  const isMobile = useIsMobile();
  const { isAdmin, role } = useAuth();
  const canAccessUsers = isAdmin || role === 'invoice';
  
  // Fetch users from Supabase
  const { data: users, refetch } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('authorized_users')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching users:', error);
        return [];
      }

      return data as User[];
    },
    enabled: canAccessUsers // Only fetch if user has access
  });

  // Filter users based on search text
  const filteredUsers = users?.filter(user => 
    user.email.toLowerCase().includes(filterText.toLowerCase()) ||
    user.display_name?.toLowerCase().includes(filterText.toLowerCase())
  );

  // If not authorized, show unauthorized message
  if (!canAccessUsers) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-4rem)]">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Unauthorized Access</h1>
          <p className="text-gray-600">You must be an administrator or invoice manager to view this page.</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* Desktop View */}
      {!isMobile && (
        <>
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">User Management</h1>
            <AddUserDialog onUserAdded={refetch} />
          </div>

          {/* Role permissions */}
          <div className="mb-8">
            <h2 className="text-lg font-semibold mb-4">Role Permissions</h2>
            <CompactPermissionsMatrix />
          </div>

          <div className="mb-8 flex items-center justify-between">
            <div className="flex-1 max-w-sm">
              <div className="relative">
                <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
                <Input
                  placeholder="Search users..."
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  className="pl-8"
                />
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
              <thead className="bg-gray-50/75">
                <tr className="text-left border-b border-gray-200">
                  <th className="py-2 px-4 text-sm font-medium text-gray-600">Email</th>
                  <th className="py-2 px-4 text-sm font-medium text-gray-600">Display Name</th>
                  <th className="py-2 px-4 text-sm font-medium text-gray-600">Role</th>
                  <th className="py-2 px-4 text-sm font-medium text-gray-600">Status</th>
                  <th className="py-2 px-4"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {(filteredUsers || []).map((user) => (
                  <tr key={user.id} className="hover:bg-gray-50/50 group">
                    <td className="py-1 px-4">{user.email}</td>
                    <td className="py-1 px-4">{user.display_name || '-'}</td>
                    <td className="py-1 px-4">
                      <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
                        user.role === 'admin'
                          ? 'bg-purple-100 text-purple-800'
                          : user.role === 'inventory'
                          ? 'bg-blue-100 text-blue-800'
                          : user.role === 'invoice'
                          ? 'bg-amber-100 text-amber-800'
                          : user.role === 'inbound'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        {user.role}
                      </span>
                    </td>
                    <td className="py-1 px-4">
                      <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
                        user.is_active
                          ? 'bg-green-100 text-green-800'
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        {user.is_active ? 'Active' : 'Inactive'}
                      </span>
                    </td>
                    <td className="py-1 px-4 text-right">
                      <EditUserDialog user={user} onUserUpdated={refetch} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {/* Mobile View */}
      {isMobile && (
        <div className="lg:hidden">
          <MobileHeader title="User Management" />

          {/* Role permissions in compact list format */}
          <RoleList />

          {/* Search bar */}
          <div className="sticky top-14 bg-white border-b z-10 px-4 py-2">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
              <Input
                type="text"
                placeholder="Search users..."
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className="pl-9"
              />
            </div>
          </div>

          {/* User cards */}
          <div className="p-4 space-y-3">
            {(filteredUsers || []).map((user) => (
              <div key={user.id} className="bg-white rounded-lg border p-3 shadow-sm">
                <div className="flex justify-between items-start gap-2">
                  <div className="min-w-0">
                    <h3 className="font-medium truncate">{user.email}</h3>
                    {user.display_name && (
                      <p className="text-sm text-gray-500 truncate">{user.display_name}</p>
                    )}
                  </div>
                  <span className={`shrink-0 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
                    user.role === 'admin'
                      ? 'bg-purple-100 text-purple-800'
                      : user.role === 'inventory'
                      ? 'bg-blue-100 text-blue-800'
                      : user.role === 'invoice'
                      ? 'bg-amber-100 text-amber-800'
                      : user.role === 'inbound'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {user.role}
                  </span>
                </div>
                <div className="mt-2 pt-2 border-t border-gray-100 flex justify-between items-center">
                  <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
                    user.is_active 
                      ? 'bg-green-100 text-green-800'
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {user.is_active ? 'Active' : 'Inactive'}
                  </span>
                  <EditUserDialog user={user} onUserUpdated={refetch} />
                </div>
              </div>
            ))}
          </div>

          {/* Floating Add Button */}
          <div className="fixed bottom-6 right-6">
            <AddUserDialog onUserAdded={refetch} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
