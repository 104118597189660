import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import {
  Home,
  PackageSearch,
  ArrowDownToLine,
  ArrowUpToLine,
  CalendarClock,
  Info,
  LayoutDashboard,
  Menu,
  Users
} from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { InfoModal } from "@/components/info/InfoModal";
import { useState } from 'react';

export default function Navigation() {
  const location = useLocation();
  const { isAdmin } = useAuth();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigation = [
    { name: 'Home', href: '/home', icon: Home },
    { name: 'Inventory', href: '/inventory', icon: PackageSearch },
    { name: 'Inbound', href: '/batches', icon: ArrowDownToLine, className: 'text-blue-500' },
    { name: 'Outbound', href: '/invoices', icon: ArrowUpToLine, className: 'text-red-500' },
    { name: 'Calendar', href: '/expiry-calendar', icon: CalendarClock },
    { name: 'Dashboard', href: '/dashboard', icon: LayoutDashboard },
    // Only show Users link for admins
    ...(isAdmin ? [{ name: 'Users', href: '/users', icon: Users }] : []),
  ];

  return (
    <nav className="flex items-center">
      {/* Logo removed */}

      {/* Desktop Navigation */}
      <div className="hidden md:flex items-center space-x-4">
        {navigation.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            className={cn(
              'flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-md transition-colors duration-200',
              location.pathname === item.href
                ? 'bg-[#99AE4C] text-white'
                : 'text-white hover:bg-[#C3D48A] hover:text-[#304661]'
            )}
          >
            <item.icon 
              className={cn(
                "h-5 w-5 flex-shrink-0",
                location.pathname !== item.href && item.className
              )} 
            />
            {item.name}
          </Link>
        ))}
      </div>

      {/* Mobile Menu Button */}
      <button 
        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        className="md:hidden ml-auto text-white p-2"
      >
        <Menu className="h-6 w-6" />
      </button>

      {/* Mobile Navigation */}
      {mobileMenuOpen && (
        <div className="absolute top-16 left-0 right-0 bg-[#304661] md:hidden">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              onClick={() => setMobileMenuOpen(false)}
              className={cn(
                'flex items-center gap-2 px-6 py-3 text-sm font-medium border-b border-[#C3D48A]/10',
                location.pathname === item.href
                  ? 'bg-[#99AE4C] text-white'
                  : 'text-white hover:bg-[#C3D48A] hover:text-[#304661]'
              )}
            >
              <item.icon 
                className={cn(
                  "h-5 w-5 flex-shrink-0",
                  location.pathname !== item.href && item.className
                )} 
              />
              {item.name}
            </Link>
          ))}
        </div>
      )}

      <div className="hidden md:block">
        <InfoModal onClose={() => {}} />
      </div>
    </nav>
  );
}