import React from 'react';

interface FormInputProps {
  label: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
  type?: string;
  required?: boolean;
}

export const FormInput: React.FC<FormInputProps> = ({
  label,
  id,
  value,
  onChange,
  type = 'text',
  required = false,
}) => {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium">
        {label} {required && '*'}
      </label>
      <input
        type={type}
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        className="mt-1 block w-full rounded-md bg-white/10 border-transparent text-white placeholder-white/50 focus:border-[#C3D48A] focus:ring-[#C3D48A]"
      />
    </div>
  );
};