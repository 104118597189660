import axios from 'axios';
import Logger from '@/utils/logger';

export const API = {
  BASE: 'https://api.smallinvoice.com/v2',
  AUTH: '/auth/access-tokens',
  DELIVERY_NOTES: '/receivables/delivery-notes',
  CLIENT_ID: import.meta.env.VITE_SMALL_INVOICE_CLIENT_ID,
  CLIENT_SECRET: import.meta.env.VITE_SMALL_INVOICE_CLIENT_SECRET
};

export const getToken = async () => {
  Logger.info('Requesting token with scope: delivery_note');
  const response = await axios.post(`${API.BASE}${API.AUTH}`, {
    grant_type: 'client_credentials',
    client_id: API.CLIENT_ID,
    client_secret: API.CLIENT_SECRET,
    scope: 'delivery_note'
  });
  Logger.info('Token received successfully');
  return response.data.access_token;
};

export const fetchDeliveryNotes = async (startDate: string, endDate: string) => {
  Logger.info('Fetching delivery notes...', { startDate, endDate });
  
  const token = await getToken();
  
  const response = await axios.get(`${API.BASE}${API.DELIVERY_NOTES}`, {
    headers: { 'Authorization': `Bearer ${token}` },
    params: {
      with: 'contact,positions',
      sort: '-date',
      date_from: startDate,
      date_to: endDate
    }
  });

  return response.data;
};