import React, { useEffect, useRef } from 'react';
import { useNotifications } from '@/contexts/NotificationsContext';

export function NotificationSound() {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { playNotificationSound, setPlayNotificationSound } = useNotifications();

  useEffect(() => {
    // Create audio element
    const audio = new Audio('/notification.wav');
    audio.preload = 'auto';
    audio.volume = 0.5; // Set volume to 50%
    audioRef.current = audio;

    return () => {
      if (audioRef.current) {
        audioRef.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (playNotificationSound && audioRef.current) {
      audioRef.current.play()
        .then(() => {
          setPlayNotificationSound(false);
        })
        .catch((error) => {
          setPlayNotificationSound(false);
        });
    }
  }, [playNotificationSound, setPlayNotificationSound]);

  return null;
}