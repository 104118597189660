import React from 'react';
import { Bell } from 'lucide-react';
import { useNotifications } from '@/contexts/NotificationsContext';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { NotificationsList } from '@/components/notifications/NotificationsList';
import { NotificationSound } from '@/components/notifications/NotificationSound';

export function NotificationBell() {
  const { state, unreadCount, markAllAsRead } = useNotifications();
  
  const hasExpired = state.expiring.items
    .some(n => n.daysUntilExpiry < 0 && !state.expiring.readStatus[n.batchNumber]);

  const hasExpiring = state.expiring.items
    .some(n => n.daysUntilExpiry >= 0 && n.daysUntilExpiry <= 7 && !state.expiring.readStatus[n.batchNumber]);

  return (
    <>
      <NotificationSound />
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            className="relative p-2 hover:bg-white/10 transition-colors duration-200"
            aria-label={`${unreadCount} unread notifications`}
          >
            <Bell className="h-5 w-5 text-[#C6D165]" />
            {unreadCount > 0 && (
              <Badge
                variant="destructive"
                className={`absolute -top-1 -right-1 h-5 min-w-[20px] px-1 flex items-center justify-center ${
                  hasExpired
                    ? "bg-red-500 hover:bg-red-600"
                    : hasExpiring
                      ? "bg-amber-500 hover:bg-amber-600"
                      : "bg-blue-500 hover:bg-blue-600"
                }`}
              >
                {unreadCount}
              </Badge>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="end"
          className="w-[380px] p-0"
          style={{
            backgroundColor: 'white',
            border: '1px solid #E5E7EB',
            borderRadius: '0.5rem',
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div className="flex items-center justify-between p-4 border-b border-gray-100">
            <h3 className="text-lg font-semibold text-[#304661]">
              Notifications
            </h3>
            {unreadCount > 0 && (
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  className="text-sm text-gray-500 hover:text-[#304661]"
                  onClick={() => {
                    markAllAsRead('expiring');
                    markAllAsRead('newStock');
                  }}
                >
                  Mark all as read
                </Button>
              </div>
            )}
          </div>
          <NotificationsList />
        </PopoverContent>
      </Popover>
    </>
  );
}
