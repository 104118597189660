import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Info, Package, ArrowDownToLine, ArrowUpToLine, Bell, Calendar, LayoutDashboard, ChevronDown } from 'lucide-react';

interface InfoModalProps {
  onClose?: () => void;
  children?: React.ReactNode;
}

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  className?: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description, className = "" }) => (
  <div className={`p-5 bg-gradient-to-br from-gray-50 to-slate-50 rounded-lg border border-gray-200 hover:shadow-md transition-all duration-200 ${className}`}>
    <div className="flex items-center gap-3 mb-3">
      <div className="w-10 h-10 rounded-lg bg-[#304661]/10 flex items-center justify-center text-[#304661]">
        {icon}
      </div>
      <h4 className="font-semibold text-gray-900">{title}</h4>
    </div>
    <p className="text-sm text-gray-600 leading-relaxed">
      {description}
    </p>
  </div>
);

const QuickRefSection: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  
  return (
    <div className="border rounded-lg overflow-hidden bg-white">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-4 py-3 flex items-center justify-between bg-gray-50 hover:bg-gray-100 transition-colors"
      >
        <span className="font-medium text-gray-900">{title}</span>
        <ChevronDown className={`w-5 h-5 transition-transform ${isOpen ? 'transform rotate-180' : ''}`} />
      </button>
      <div className={`overflow-hidden transition-all duration-200 ${isOpen ? 'max-h-96' : 'max-h-0'}`}>
        <div className="p-4 text-sm text-gray-700">
          {children}
        </div>
      </div>
    </div>
  );
};

export function InfoModal({ onClose }: InfoModalProps) {
  return (
    <Dialog onOpenChange={onClose}>
      <DialogTrigger asChild>
        <button className="w-full flex items-center gap-2 text-sm font-medium transition-colors duration-200 text-white hover:bg-[#C3D48A] hover:text-[#304661]">
          <Info className="h-5 w-5 flex-shrink-0" />
        </button>
      </DialogTrigger>
      <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
        <div className="space-y-8">
          {/* Header Banner */}
          <div className="relative">
            <div className="w-full h-44 overflow-hidden rounded-t-lg">
              <img
                src="/banner_welcome.jpg"
                alt="Welcome Banner"
                className="w-full object-cover -mt-16"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-[#304661]/90 to-transparent" />
            </div>
            
            <div className="absolute inset-0 flex items-center px-6">
              <div className="flex items-center space-x-4">
                <div className="p-2">
                  <img src="/ims_logo3b.png" alt="Chefland IMS Logo" className="w-32 h-auto" />
                </div>
                <div className="text-white">
                  <h2 className="text-2xl font-bold">Chefland IMS</h2>
                  <div className="text-blue-100 text-sm">Version 1.0</div>
                </div>
              </div>
            </div>
          </div>

          {/* System Overview */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <FeatureCard
              icon={<Package className="w-6 h-6" />}
              title="Inventory Control"
              description="Centralized product catalog with real-time stock tracking and dual measurement system support."
            />
            <FeatureCard
              icon={<ArrowDownToLine className="w-6 h-6" />}
              title="Stock Movement"
              description="Efficient batch management for inbound stock and automated delivery note processing."
            />
            <FeatureCard
              icon={<Bell className="w-6 h-6" />}
              title="Smart Features"
              description="Real-time notifications for expiry dates, stock levels, and automated batch matching."
            />
          </div>

          {/* Feature Grid */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-900">Key Features</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-4">
                <FeatureCard
                  icon={<Bell className="w-6 h-6" />}
                  title="Real-Time Notifications"
                  description="Stay informed about expiring items, stock levels, and important updates with our smart notification system."
                />
                <FeatureCard
                  icon={<ArrowDownToLine className="w-6 h-6" />}
                  title="Smart Batching"
                  description="Intelligent batch suggestions, FIFO tracking, and flexible multi-batch selection capabilities."
                />
              </div>
              <div className="space-y-4">
                <FeatureCard
                  icon={<ArrowUpToLine className="w-6 h-6" />}
                  title="Delivery Notes"
                  description="Seamless Small Invoice integration with automated matching and real-time processing."
                />
                <FeatureCard
                  icon={<LayoutDashboard className="w-6 h-6" />}
                  title="Analytics Dashboard"
                  description="Comprehensive stock monitoring, expiry tracking, and usage pattern analysis."
                />
              </div>
            </div>
          </div>

          {/* Quick Reference */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-900">Quick Reference</h3>
            <div className="space-y-2">
              <QuickRefSection title="SKU Format">
                <ul className="list-disc pl-4 space-y-2">
                  <li><strong>Format:</strong> Letters, numbers, and hyphens only</li>
                  <li><strong>Examples:</strong> CL09591, BT-50-ALT-IT</li>
                  <li><strong>Prefixes:</strong>
                    <ul className="pl-4 mt-1">
                      <li>CL: Chefland branded items</li>
                      <li>BT: Bottled products</li>
                      <li>BV: Bonverre products</li>
                    </ul>
                  </li>
                </ul>
              </QuickRefSection>

              <QuickRefSection title="Batch Numbering">
                <ul className="list-disc pl-4 space-y-2">
                  <li><strong>Base Format:</strong> B-XXX (e.g., B-045)</li>
                  <li><strong>With Date:</strong> B-045-MMDDYY</li>
                  <li><strong>Multiple Batches:</strong> B-045-MMDDYY-1</li>
                </ul>
              </QuickRefSection>

              <QuickRefSection title="Roles & Permissions">
                <div className="overflow-x-auto">
                  <table className="min-w-full border-collapse">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="border border-gray-200 px-4 py-2 text-left text-xs font-semibold text-gray-600">Role</th>
                        <th className="border border-gray-200 px-4 py-2 text-left text-xs font-semibold text-gray-600">Inventory</th>
                        <th className="border border-gray-200 px-4 py-2 text-left text-xs font-semibold text-gray-600">Batches</th>
                        <th className="border border-gray-200 px-4 py-2 text-left text-xs font-semibold text-gray-600">Invoices</th>
                        <th className="border border-gray-200 px-4 py-2 text-left text-xs font-semibold text-gray-600">Dashboard</th>
                        <th className="border border-gray-200 px-4 py-2 text-left text-xs font-semibold text-gray-600">Users</th>
                      </tr>
                    </thead>
                    <tbody className="text-sm">
                      <tr>
                        <td className="border border-gray-200 px-4 py-2 font-medium">Admin</td>
                        <td className="border border-gray-200 px-4 py-2 text-green-600">Full</td>
                        <td className="border border-gray-200 px-4 py-2 text-green-600">Full</td>
                        <td className="border border-gray-200 px-4 py-2 text-green-600">Full</td>
                        <td className="border border-gray-200 px-4 py-2 text-green-600">Full</td>
                        <td className="border border-gray-200 px-4 py-2 text-green-600">Full</td>
                      </tr>
                      <tr>
                        <td className="border border-gray-200 px-4 py-2 font-medium">Invoice</td>
                        <td className="border border-gray-200 px-4 py-2 text-green-600">Full</td>
                        <td className="border border-gray-200 px-4 py-2 text-green-600">Full</td>
                        <td className="border border-gray-200 px-4 py-2 text-green-600">Full</td>
                        <td className="border border-gray-200 px-4 py-2 text-green-600">Full</td>
                        <td className="border border-gray-200 px-4 py-2 text-red-600">No</td>
                      </tr>
                      <tr>
                        <td className="border border-gray-200 px-4 py-2 font-medium">Inventory</td>
                        <td className="border border-gray-200 px-4 py-2 text-green-600">Full</td>
                        <td className="border border-gray-200 px-4 py-2 text-green-600">Full</td>
                        <td className="border border-gray-200 px-4 py-2 text-red-600">No</td>
                        <td className="border border-gray-200 px-4 py-2 text-red-600">No</td>
                        <td className="border border-gray-200 px-4 py-2 text-red-600">No</td>
                      </tr>
                      <tr>
                        <td className="border border-gray-200 px-4 py-2 font-medium">Inbound</td>
                        <td className="border border-gray-200 px-4 py-2 text-blue-600">View</td>
                        <td className="border border-gray-200 px-4 py-2 text-green-600">Full</td>
                        <td className="border border-gray-200 px-4 py-2 text-red-600">No</td>
                        <td className="border border-gray-200 px-4 py-2 text-red-600">No</td>
                        <td className="border border-gray-200 px-4 py-2 text-red-600">No</td>
                      </tr>
                      <tr>
                        <td className="border border-gray-200 px-4 py-2 font-medium">Guest</td>
                        <td className="border border-gray-200 px-4 py-2 text-blue-600">View</td>
                        <td className="border border-gray-200 px-4 py-2 text-blue-600">View</td>
                        <td className="border border-gray-200 px-4 py-2 text-red-600">No</td>
                        <td className="border border-gray-200 px-4 py-2 text-red-600">No</td>
                        <td className="border border-gray-200 px-4 py-2 text-red-600">No</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </QuickRefSection>
            </div>
          </div>

          {/* Authentication Info */}
          <div className="bg-blue-50 rounded-lg p-6 border border-blue-100">
            <h3 className="text-lg font-semibold text-blue-900 mb-3">Access & Authentication</h3>
            <ul className="space-y-2 text-sm text-blue-800">
              <li className="flex items-center gap-2">
                <span className="w-2 h-2 rounded-full bg-blue-400"></span>
                <span>Google Sign-In authentication</span>
              </li>
              <li className="flex items-center gap-2">
                <span className="w-2 h-2 rounded-full bg-blue-400"></span>
                <span>Role-based access control</span>
              </li>
              <li className="flex items-center gap-2">
                <span className="w-2 h-2 rounded-full bg-blue-400"></span>
                <span>Access requests via info@chefland.ch</span>
              </li>
            </ul>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
