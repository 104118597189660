import React from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { Thermometer, Store, Building2 } from 'lucide-react';
import { cn } from "@/lib/utils";

interface LocationTotal {
  location: string;
  value: number;
}

interface LocationGridProps {
  totals: LocationTotal[];
  className?: string;
}

const LOCATIONS = [
  {
    key: '(+25°C)',
    label: '+25°C',
    icon: Thermometer,
    color: 'text-blue-700'
  },
  {
    key: '(+3°C)',
    label: '+3°C',
    icon: Thermometer,
    color: 'text-blue-700'
  },
  {
    key: '(-25°C)',
    label: '-25°C',
    icon: Thermometer,
    color: 'text-blue-700'
  },
  {
    key: 'Paradiso',
    label: 'Paradiso',
    icon: Store,
    color: 'text-blue-700'
  },
  {
    key: 'Consignment',
    label: 'Consignment',
    icon: Building2,
    color: 'text-blue-700'
  }
] as const;

export function LocationGrid({ totals, className }: LocationGridProps) {
  const totalValue = totals.reduce((sum, { value }) => sum + value, 0);
  
  return (
    <Card className={className}>
      <CardContent className="pt-6 pb-4">
        <div className="text-sm font-semibold mb-3">Location Distribution</div>

        <div className="space-y-2">
          {LOCATIONS.map(({ key, label, icon: Icon, color }) => {
            const entry = totals.find(t => t.location === key);
            const value = entry?.value || 0;
            const percentage = totalValue ? (value / totalValue) * 100 : 0;

            return (
              <div key={key} className="group">
                <div className="flex items-center gap-3">
                  <div className={cn(
                    "flex items-center gap-2 w-[100px] shrink-0",
                    color
                  )}>
                    <Icon className="h-5 w-5 shrink-0" />
                    <span className="text-sm font-medium truncate">{label}</span>
                  </div>

                  <div className="flex-1 flex items-center gap-3">
                    <div className="flex-1 h-2 bg-gray-100 rounded-full overflow-hidden">
                      <div
                        className={cn(
                          "h-full rounded-full transition-all",
                          "bg-blue-700/20 group-hover:bg-blue-700/30"
                        )}
                        style={{
                          width: `${percentage}%`
                        }}
                      />
                    </div>
                    <div className="text-sm tabular-nums w-[90px] text-right">
                      CHF {Math.round(value).toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-3 pt-2 border-t flex justify-between text-sm">
          <span className="font-medium">Total Value</span>
          <span className="font-semibold">
            CHF {Math.round(totalValue).toLocaleString()}
          </span>
        </div>
      </CardContent>
    </Card>
  );
}