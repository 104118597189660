import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Pencil } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import { Switch } from "@/components/ui/switch";
import { UserRole } from "@/types/auth";

interface User {
  id: string;
  email: string;
  role: UserRole;
  is_active: boolean;
  display_name?: string;
}

const userFormSchema = z.object({
  role: z.enum(["admin", "inventory", "guest", "inbound", "invoice"]),
  display_name: z.string().optional(),
  is_active: z.boolean(),
});

type UserFormValues = z.infer<typeof userFormSchema>;

interface EditUserDialogProps {
  user: User;
  onUserUpdated: () => void;
}

export function EditUserDialog({ user, onUserUpdated }: EditUserDialogProps) {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();

  const form = useForm<UserFormValues>({
    resolver: zodResolver(userFormSchema),
    defaultValues: {
      role: user.role,
      display_name: user.display_name || "",
      is_active: user.is_active,
    },
  });

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      form.reset();
    }
    setOpen(newOpen);
  };

  const handleDelete = async () => {
    try {
      // Check if this is the last admin
      if (user.role === 'admin') {
        const { data: admins, error: adminCheckError } = await supabase
          .from('authorized_users')
          .select('id')
          .eq('role', 'admin')
          .eq('is_active', true)
          .neq('id', user.id);

        if (adminCheckError) throw adminCheckError;

        if (!admins || admins.length < 1) {
          toast({
            title: "Error",
            description: "Cannot delete: At least one other active admin must exist",
            variant: "destructive",
          });
          return;
        }
      }

      // Delete the user
      const { error } = await supabase
        .from("authorized_users")
        .delete()
        .eq("id", user.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "User deleted successfully",
      });

      setOpen(false);
      onUserUpdated();
    } catch (error) {
      console.error("Failed to delete user:", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to delete user",
        variant: "destructive",
      });
    }
  };

  const onSubmit = async (values: UserFormValues) => {
    try {
      // Prevent removing the last admin
      if ((user.role === 'admin' && values.role !== 'admin') || 
          (user.role === 'admin' && !values.is_active)) {
        console.log('Checking admin count...');
        
        // Count active admins excluding the current user
        const { data: admins, error: adminCheckError } = await supabase
          .from('authorized_users')
          .select('id, email')
          .eq('role', 'admin')
          .eq('is_active', true)
          .neq('id', user.id); // Exclude current user

        if (adminCheckError) {
          console.error('Admin check error:', adminCheckError);
          throw adminCheckError;
        }

        console.log('Active admins found:', admins);

        if (!admins || admins.length < 1) {
          toast({
            title: "Error",
            description: "Cannot change role: At least one other active admin must exist",
            variant: "destructive",
          });
          return;
        }
      }

      // Update user
      const { error } = await supabase
        .from("authorized_users")
        .update({
          role: values.role,
          display_name: values.display_name || null,
          is_active: values.is_active,
          updated_at: new Date().toISOString(),
        })
        .eq("id", user.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "User updated successfully",
      });

      setOpen(false);
      onUserUpdated();
    } catch (error) {
      console.error("Failed to update user:", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to update user",
        variant: "destructive",
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button variant="ghost" className="text-sm text-blue-600 hover:text-blue-800">
          <Pencil className="h-4 w-4 mr-1" />
          Edit
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit User</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            {/* Email field (read-only) */}
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input value={user.email} disabled className="bg-gray-50" />
              </FormControl>
            </FormItem>

            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Role</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a role" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="admin">Admin</SelectItem>
                      <SelectItem value="invoice">Invoice</SelectItem>
                      <SelectItem value="inventory">Inventory</SelectItem>
                      <SelectItem value="inbound">Inbound</SelectItem>
                      <SelectItem value="guest">Guest</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="display_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Display Name (Optional)</FormLabel>
                  <FormControl>
                    <Input placeholder="Display name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="is_active"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3">
                  <div className="space-y-0.5">
                    <FormLabel>Active Status</FormLabel>
                    <div className="text-sm text-gray-500">
                      User can {field.value ? "access" : "not access"} the system
                    </div>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <div className="flex justify-between items-center">
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button type="button" variant="destructive">Delete User</Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                      This action cannot be undone. This will permanently delete the user account.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={handleDelete} className="bg-red-600 hover:bg-red-700">
                      Delete
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
              <Button type="submit">Update User</Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}