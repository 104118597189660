import React from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { AlertTriangle, Circle } from 'lucide-react';

interface ExpiryCardProps {
  title: string;
  value: number;
  count: number;
  variant: 'expired' | '30-days' | '60-days' | '61-plus';
}

const VARIANTS = {
  'expired': {
    icon: AlertTriangle,
    iconClass: "text-red-500 fill-red-500",
    bgClass: "bg-red-50"
  },
  '30-days': {
    icon: Circle,
    iconClass: "text-amber-500 fill-amber-500",
    bgClass: "bg-amber-50"
  },
  '60-days': {
    icon: Circle,
    iconClass: "text-emerald-500 fill-emerald-500",
    bgClass: "bg-emerald-50"
  },
  '61-plus': {
    icon: Circle,
    iconClass: "text-emerald-500 fill-emerald-500",
    bgClass: "bg-emerald-50/50"
  }
} as const;

export function ExpiryCard({ title, value, count, variant }: ExpiryCardProps) {
  const { icon: Icon, iconClass, bgClass } = VARIANTS[variant];

  return (
    <Card className={cn("transition-colors duration-200", bgClass)}>
      <CardContent className="p-4">
        <div className="flex items-start justify-between">
          <div className="space-y-1">
            <p className="text-sm font-medium">{title}</p>
            <div>
              <span className="text-2xl font-bold">
                CHF {Math.round(value).toLocaleString()}
              </span>
              <div className="text-xs text-muted-foreground mt-0.5">
                {count} items
              </div>
            </div>
          </div>
          <Icon className={cn("h-6 w-6", iconClass)} />
        </div>
      </CardContent>
    </Card>
  );
}