import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import InvoiceFormFields from "./InvoiceFormFields";
import { invoiceFormSchema, type InvoiceFormValues } from "@/types/invoice";
import Logger from "@/utils/logger";
import { useAuth } from '@/contexts/AuthContext';
import { useIsMobile } from '@/hooks/use-mobile';
import { cn } from "@/lib/utils";

interface AddInvoiceDialogProps {
  onInvoiceAdded: (invoiceId?: number) => void;
}

export function AddInvoiceDialog({ onInvoiceAdded }: AddInvoiceDialogProps) {
  const [open, setOpen] = useState(false);
  const [isDuplicateInvoice, setIsDuplicateInvoice] = useState(false);
  const { toast } = useToast();
  const { canEdit } = useAuth();
  const isMobile = useIsMobile();

  const form = useForm<InvoiceFormValues>({
    resolver: zodResolver(invoiceFormSchema),
    defaultValues: {
      date: new Date().toISOString().split("T")[0],
      invoice_number: "",
      company: "",
      notes: "",
      status: "IMS",
      items: [],
    },
  });

  const [companyOptions, setCompanyOptions] = useState<string[]>([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);

  // Fetch existing companies for autocomplete
  const fetchCompanies = useCallback(async (searchTerm: string) => {
    setIsLoadingCompanies(true);
    try {
      const { data, error } = await supabase
        .from('invoices')
        .select('company')
        .ilike('company', `${searchTerm}%`)
        .order('company')
        .limit(5);

      if (error) throw error;

      const uniqueCompanies = Array.from(new Set(data.map(d => d.company))).filter(Boolean);
      setCompanyOptions(uniqueCompanies);
    } catch (error) {
      Logger.error('Error fetching companies', error);
    } finally {
      setIsLoadingCompanies(false);
    }
  }, []);

  // Debounce company search
  const watchedCompany = form.watch('company');

  useEffect(() => {
    if (watchedCompany) {
      const timer = setTimeout(() => fetchCompanies(watchedCompany), 300);
      return () => clearTimeout(timer);
    } else {
      setCompanyOptions([]);
    }
  }, [fetchCompanies, watchedCompany]);

  const resetForm = useCallback(() => {
    form.reset({
      date: new Date().toISOString().split("T")[0],
      invoice_number: "",
      company: "",
      notes: "",
      status: "IMS",
      items: [],
    });
  }, [form]);

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      resetForm();
    }
    setOpen(newOpen);
  };

  const validateForm = (data: InvoiceFormValues) => {
    if (!data.items?.length) {
      toast({
        title: "Validation Error",
        description: "Please add at least one item to the invoice",
        variant: "destructive",
      });
      return false;
    }

    for (const item of data.items) {
      if (!item.sku || !item.selected_batches?.length) {
        toast({
          title: "Validation Error",
          description: "Please complete all item details including SKU and batch selection",
          variant: "destructive",
        });
        return false;
      }
    }

    return true;
  };

  const onSubmit = async (values: InvoiceFormValues) => {
    try {
      Logger.info("Submitting invoice form", { values });

      if (!validateForm(values)) {
        return;
      }

      // Create invoice
      const { data: invoice, error: invoiceError } = await supabase
        .from("invoices")
        .insert({
          invoice_number: values.invoice_number,
          date: values.date,
          company: values.company,
          notes: values.notes || "",
          status: values.status,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        })
        .select()
        .single();

      if (invoiceError) {
        Logger.error("Error creating invoice", { error: invoiceError });
        throw new Error(invoiceError.message);
      }

      if (!invoice) {
        throw new Error("Failed to create invoice");
      }

      // Create invoice items
      for (const item of values.items) {
        if (!item.selected_batches) continue;

        const invoiceItems = item.selected_batches.map((batch) => ({
          invoice_id: invoice.id,
          sku: item.sku,
          quantity: item.is_weight_based ? null : batch.quantity,
          weight_kilos: item.is_weight_based ? batch.weight_kilos : null,
          batch_number: batch.batch_number,
          warehouse: batch.warehouse,
          measurement_type: item.is_weight_based ? "weight" : "quantity",
          unit_price: item.unit_price || null,
        }));

        const { error: itemsError } = await supabase
          .from("invoice_items")
          .insert(invoiceItems);

        if (itemsError) {
          Logger.error("Error creating invoice items", { error: itemsError });
          // Rollback invoice
          await supabase.from("invoices").delete().eq("id", invoice.id);
          throw new Error(itemsError.message);
        }
      }

      // Update batch quantities - moved outside the invoice items loop
      // No need to update batch available_quantity anymore since we're using real-time calculations

      toast({
        title: "Success",
        description: "Invoice created successfully",
      });

      setOpen(false);
      resetForm();
      onInvoiceAdded?.();
    } catch (error) {
      Logger.error("Failed to create invoice", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to create invoice",
        variant: "destructive",
      });
    }
  };

  if (!canEdit) return null;

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button 
          onClick={() => {
            resetForm();
            setOpen(true);
          }}
          className="flex-1 bg-black hover:bg-gray-900 text-white h-9"
        >
          <Plus className="w-4 h-4 mr-1" />
          Create DN
        </Button>
      </DialogTrigger>
        <DialogContent className={cn(
          "max-w-4xl overflow-y-auto bg-white max-h-[90vh]",
          isMobile 
            ? "w-full !max-w-none !rounded-none !p-0" 
            : "sm:rounded-lg"
        )}>
          <DialogHeader className={cn(
            "bg-gray-50 sticky top-0 z-40 rounded-t-lg",
            isMobile ? "px-6 py-3" : "p-3"
          )}>
            <DialogTitle className="text-gray-900">Create New Delivery Note</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={cn(
              isMobile ? "px-6 py-4" : "p-4"
            )}>
            <InvoiceFormFields form={form} onDuplicateChange={setIsDuplicateInvoice} companyOptions={companyOptions} isLoadingCompanies={isLoadingCompanies} />
            <div className="flex justify-end space-x-2">
              <Button
                type="submit"
                disabled={isDuplicateInvoice}
                className={isDuplicateInvoice ? "opacity-50 cursor-not-allowed" : ""}
              >
                Submit
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
