import React from "react";
import { Loader2 } from "lucide-react";

interface ProgressIndicatorProps {
    current: number;
    total: number;
    message?: string;
}

export function ProgressIndicator({ current, total, message }: ProgressIndicatorProps) {
    return (
        <div className="fixed inset-0 bg-black/20 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-xl p-6 w-[300px]">
                <div className="flex flex-col items-center gap-4">
                    <Loader2 className="h-6 w-6 animate-spin" />
                    
                    {message && (
                        <p className="text-sm text-gray-600 text-center">
                            {message}
                        </p>
                    )}
                    
                    <div className="text-sm text-gray-500">
                        Processing item {current} of {total}
                    </div>

                    <div className="w-full bg-gray-200 rounded-full h-2">
                        <div 
                            className="bg-blue-600 h-2 rounded-full"
                            style={{ 
                                width: `${Math.min((current / total) * 100, 100)}%`
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}