import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { X } from 'lucide-react';
import { ContactFormData, Industry } from '@/types/contact';
import { FormInput } from './FormInput';
import { FormRadio } from './FormRadio';
import { Button } from './Button';

interface ContactFormProps {
  onClose: () => void;
}

const INDUSTRIES: { value: Industry; label: string }[] = [
  { value: 'hotel', label: 'Hotel' },
  { value: 'restaurant', label: 'Restaurant' },
  { value: 'pizzeria', label: 'Pizzeria' },
  { value: 'retail', label: 'Retail/Food' },
  { value: 'other', label: 'Other' }
];

// These should be in environment variables
const EMAIL_SERVICE_ID = import.meta.env.VITE_EMAIL_SERVICE_ID;
const EMAIL_TEMPLATE_ID = import.meta.env.VITE_EMAIL_TEMPLATE_ID;
const EMAIL_PUBLIC_KEY = import.meta.env.VITE_EMAIL_PUBLIC_KEY;

export const ContactForm: React.FC<ContactFormProps> = ({ onClose }) => {
  const [formData, setFormData] = useState<ContactFormData>({
    name: '',
    company: '',
    city: '',
    email: '',
    title: '',
    industry: 'hotel' as Industry,
    message: 'Please provide more information about Chefland IMS'
  });

  const [selectedIndustry, setSelectedIndustry] = useState<Industry | null>(null);
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!EMAIL_SERVICE_ID || !EMAIL_TEMPLATE_ID || !EMAIL_PUBLIC_KEY) {
      setStatus('error');
      return;
    }

    setStatus('loading');

    try {
      await emailjs.send(
        EMAIL_SERVICE_ID,
        EMAIL_TEMPLATE_ID,
        formData as Record<string, unknown>,
        EMAIL_PUBLIC_KEY
      );
      setStatus('success');
      setTimeout(onClose, 2000);
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-[#304661] text-white rounded-lg p-6 max-w-md w-full relative max-h-[90vh] overflow-y-auto">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white hover:text-[#C3D48A]"
          aria-label="Close form"
        >
          <X size={24} />
        </button>

        <h2 className="text-2xl font-bold mb-4">Contact Us</h2>

        {status === 'success' ? (
          <div className="text-[#C3D48A] text-center py-8">
            Thank you for your message! We'll get back to you soon.
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <FormInput
              label="Name"
              id="name"
              value={formData.name}
              onChange={(value) => setFormData({ ...formData, name: value })}
              required
            />

            <FormInput
              label="Company"
              id="company"
              value={formData.company}
              onChange={(value) => setFormData({ ...formData, company: value })}
              required
            />

            <FormInput
              label="City"
              id="city"
              value={formData.city}
              onChange={(value) => setFormData({ ...formData, city: value })}
              required
            />

            <FormInput
              label="Business Email"
              id="email"
              type="email"
              value={formData.email}
              onChange={(value) => setFormData({ ...formData, email: value })}
              required
            />

            <FormInput
              label="Title/Role"
              id="title"
              value={formData.title}
              onChange={(value) => setFormData({ ...formData, title: value })}
              required
            />

            <div className="space-y-2">
              <label className="block text-sm font-medium">Industry *</label>
              <div className="grid grid-cols-2 gap-2">
                {INDUSTRIES.map((industry) => (
                  <FormRadio
                    key={industry.value}
                    name="industry"
                    value={industry.value}
                    label={industry.label}
                    checked={selectedIndustry === industry.value}
                    onChange={(value) => {
                      setSelectedIndustry(value as Industry);
                      setFormData({ ...formData, industry: value as Industry });
                    }}
                  />
                ))}
              </div>
            </div>

            <div>
              <label htmlFor="message" className="block text-sm font-medium">
                How can we help you? We are happy to respond in Italian, French or German.
              </label>
              <textarea
                id="message"
                required
                rows={4}
                className="mt-1 block w-full rounded-md bg-white/10 border-transparent text-white placeholder-white/50 focus:border-[#C3D48A] focus:ring-[#C3D48A]"
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              />
            </div>

            <Button
              type="submit"
              variant="secondary"
              className="w-full"
              disabled={status === 'loading'}
            >
              {status === 'loading' ? 'Sending...' : 'Send Message'}
            </Button>

            {status === 'error' && (
              <p className="text-red-400 text-sm text-center">
                Something went wrong. Please try again later.
              </p>
            )}
          </form>
        )}
      </div>
    </div>
  );
};