import React from 'react';
import { useNotifications } from '@/contexts/NotificationsContext';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Tag, Thermometer, Store, Package, Star } from 'lucide-react';

const MAX_PREVIEW_ITEMS = 3;

const getWarehouseIcon = (location: string) => {
  if (location.includes('°C')) return Thermometer;
  if (location === 'Paradiso') return Store;
  return Package;
};

// Helper function to format currency
const formatCurrency = (value: number) => {
  return `CHF ${Math.round(value).toLocaleString()}`;
};

const NotificationSection = ({
  title,
  subtitle,
  section,
  type,
  onMarkAsRead,
  onToggleExpand,
  onMarkAllAsRead
}: {
  title: string;
  subtitle: string;
  section: { items: any[]; expanded: boolean; readStatus: Record<string, boolean> };
  type: 'expiring' | 'newStock';
  onMarkAsRead: (type: 'expiring' | 'newStock', id: string) => void;
  onToggleExpand: (type: 'expiring' | 'newStock') => void;
  onMarkAllAsRead: (type: 'expiring' | 'newStock') => void;
}) => {
  // Only show empty state message for expiring section
  if (section.items.length === 0 && type === 'expiring') {
    return (
      <div className="space-y-3">
        <div className="pb-2 border-b border-gray-100">
          <h3 className="text-sm font-medium text-gray-900">{title}</h3>
          <p className="text-xs text-gray-500">{subtitle}</p>
        </div>
        <div className="py-4 text-center text-sm text-gray-500">
          No expiring batches within the next 7 days!
        </div>
      </div>
    );
  }

  // For new stock section, return null if empty
  if (section.items.length === 0 && type === 'newStock') return null;

  // Get the correct items array based on expanded state
  const maxItems = section.expanded ? 15 : MAX_PREVIEW_ITEMS;
  const displayedItems = section.items.slice(0, maxItems);
  const hasMore = section.items.length > maxItems;

  return (
    <div className="space-y-3">
      <div className="flex justify-between items-center pb-2 border-b border-gray-100">
        <div>
          <h3 className="text-sm font-medium text-gray-900">{title}</h3>
          <p className="text-xs text-gray-500">{subtitle}</p>
        </div>
        {displayedItems.some(n => !section.readStatus[n.batchNumber]) && (
          <Button
            variant="ghost"
            size="sm"
            className="text-xs text-gray-500 hover:text-gray-900"
            onClick={() => onMarkAllAsRead(type)}
          >
            Mark all as read
          </Button>
        )}
      </div>
      
      {displayedItems.map((notification) => {
        const isExpired = type === 'expiring' && notification.daysUntilExpiry < 0;
        const isUnread = !section.readStatus[notification.batchNumber];
        
        return (
          <div
            key={notification.batchNumber}
            className={`
              relative p-3 rounded-lg transition-all duration-200 cursor-pointer
              overflow-hidden group
              ${isUnread
                ? type === 'newStock'
                  ? 'bg-blue-50 border border-blue-100'
                  : 'bg-amber-50 border border-amber-100'
                : 'bg-white border border-gray-200'
              }
              hover:bg-gray-50
            `}
            onClick={() => onMarkAsRead(type, notification.batchNumber)}
          >
            {isUnread && (
              <div className="absolute top-1 right-1 transition-opacity duration-200">
                <Star className="h-4 w-4 text-amber-400 fill-amber-400 opacity-90" />
              </div>
            )}
            <div className="flex flex-col gap-2">
              {/* Header Section */}
              <div className="grid grid-cols-[1fr_auto] items-start gap-3 min-w-0">
                <div className="min-w-0 flex-1">
                  <h4 className="font-semibold text-sm leading-5 text-[#304661]">
                    <span className="block truncate" title={notification.itemName}>
                      {notification.itemName}
                    </span>
                  </h4>
                  <div className="text-xs text-gray-500">
                    <span className="font-medium truncate block flex items-center gap-1.5">
                      <Tag className="h-3.5 w-3.5 text-muted-foreground shrink-0" />
                      {notification.batchNumber}
                    </span>
                  </div>
                </div>
              </div>

              {/* Footer Section */}
              <div className="mt-1 pt-2 border-t border-gray-200/30 grid grid-cols-[1fr_auto] gap-2 items-center">
                <div className="flex items-center gap-4 text-xs text-gray-600">
                  <div>
                    <span>{notification.weightKilos ? "Amt: " : "Qty: "}</span>
                    <span className="font-medium">
                      {notification.weightKilos
                        ? `${notification.weightKilos.toFixed(1)} kg`
                        : Math.round(notification.quantity || 0)}
                    </span>
                  </div>
                  <div className="flex items-center gap-1.5">
                    {React.createElement(getWarehouseIcon(notification.warehouse), {
                      className: "h-4 w-4 text-muted-foreground shrink-0"
                    })}
                    <span className="font-medium">{notification.warehouse}</span>
                  </div>
                </div>
                <div className="flex items-center gap-3 flex-shrink-0">
                  <span className="text-xs font-semibold whitespace-nowrap text-gray-900">
                    {formatCurrency(notification.totalValue)}
                  </span>
                  <Badge
                    className={`text-[10px] font-semibold whitespace-nowrap ${
                      isExpired
                        ? 'bg-red-600 text-white hover:bg-red-700'
                        : notification.daysUntilExpiry <= 30
                          ? 'bg-amber-500 text-white hover:bg-amber-600'
                          : 'bg-green-500 text-white hover:bg-green-600'
                    }`}
                  >
                    {isExpired ? 'Expired' :
                     notification.daysUntilExpiry === 0 ? 'Today' :
                     notification.daysUntilExpiry === 1 ? '1d left' :
                     notification.daysUntilExpiry > 60 ? '60+ days' :
                     `${notification.daysUntilExpiry}d left`}
                  </Badge>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {hasMore && (
        <Button
          variant="ghost"
          className="w-full text-sm text-blue-600 hover:text-blue-700 mt-2"
          onClick={() => onToggleExpand(type)}
        >
          {section.expanded ? 'Show less' : `Show all (${section.items.length})`}
        </Button>
      )}
    </div>
  );
};

export function NotificationsList() {
  const { state, markAsRead, markAllAsRead, toggleExpanded } = useNotifications();
  
  // Base container styles - consistent across empty and populated states
  const containerStyles = "h-[400px] min-w-[320px] max-w-[384px] w-full rounded-md border bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]";

  const hasAnyNotifications = 
    state.expiring.items.length > 0 || 
    state.newStock.items.length > 0;

  if (!hasAnyNotifications) {
    return (
      <ScrollArea className={containerStyles}>
        <div className="p-4 space-y-2 divide-y divide-gray-100">
          <div className="pb-2">
            <h3 className="text-sm font-medium text-gray-900">Notifications</h3>
            <p className="text-xs text-gray-500">No notifications to show</p>
          </div>
          <div className="py-8 text-center text-sm text-gray-500">
            No items to display
          </div>
        </div>
      </ScrollArea>
    );
  }

  return (
    <ScrollArea className={`${containerStyles} z-50 animate-in fade-in-0 zoom-in-95 backdrop-blur-xl bg-black/5`}>
      <div className="p-4 divide-y-4 divide-white [&>*]:py-4 [&>*:first-child]:pt-0 [&>*:last-child]:pb-0">
        {/* Expiring Section */}
        <NotificationSection
          title="Expiring Items"
          subtitle="Items expiring within 7 days"
          section={state.expiring}
          type="expiring"
          onMarkAsRead={markAsRead}
          onToggleExpand={toggleExpanded}
          onMarkAllAsRead={markAllAsRead}
        />

        {/* New Stock Section */}
        <NotificationSection
          title="New Stock"
          subtitle="Added in the last 48 hours"
          section={state.newStock}
          type="newStock"
          onMarkAsRead={markAsRead}
          onToggleExpand={toggleExpanded}
          onMarkAllAsRead={markAllAsRead}
        />
      </div>
    </ScrollArea>
  );
}
