import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { format, subDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import Logger from '../utils/logger';
import DeliveryNoteDetails from '../components/DeliveryNoteDetails';
import { Button } from '../components/ui/button';
import { Checkbox } from '../components/ui/checkbox';
import { useToast } from '../components/ui/use-toast';
import { DeliveryNote, ImportStatus, ValidatedPosition, ValidatedDeliveryNote } from '../types/delivery-note';
import { ImportService } from '../services/importService';
import { Loader2, DollarSign } from 'lucide-react';
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@/components/ui/hover-card";

const API = {
    BASE: 'https://api.smallinvoice.com/v2',
    AUTH: '/auth/access-tokens',
    DELIVERY_NOTES: '/receivables/delivery-notes',
    CLIENT_ID: import.meta.env.VITE_SMALL_INVOICE_CLIENT_ID,
    CLIENT_SECRET: import.meta.env.VITE_SMALL_INVOICE_CLIENT_SECRET
};

export default function DeliveryNotes() {
    const navigate = useNavigate();
    const [deliveryNotes, setDeliveryNotes] = useState<ValidatedDeliveryNote[]>([]);
    const [selectedNotes, setSelectedNotes] = useState<number[]>([]);
    const [currentNoteIndex, setCurrentNoteIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [importing, setImporting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [importStep, setImportStep] = useState<'list' | 'details' | 'summary'>('list');
    
    const searchParams = new URLSearchParams(window.location.search);
    const noteNumber = searchParams.get('noteNumber');
    const startDate = noteNumber
      ? format(subDays(new Date(), 30), 'yyyy-MM-dd')
      : searchParams.get('startDate');
    const endDate = noteNumber
      ? format(new Date(), 'yyyy-MM-dd')
      : searchParams.get('endDate');
    const { toast } = useToast();

    // Auto-select note when it's loaded
    useEffect(() => {
      if (noteNumber && deliveryNotes.length > 0 && !loading) {
        Logger.info('Searching for note to auto-select', {
          noteNumber,
          availableNotes: deliveryNotes.length,
          noteNumbers: deliveryNotes.map(n => n.number)
        });

        const noteToSelect = deliveryNotes.find(note => note.number === noteNumber);
        if (noteToSelect) {
          Logger.info('Auto-selecting note', {
            noteId: noteToSelect.id,
            noteNumber: noteToSelect.number
          });
          setSelectedNotes([noteToSelect.id]);
          setImportStep('details');
        } else {
          Logger.warn('Note not found for auto-selection', { noteNumber });
          toast({
            title: "Note Not Found",
            description: `Could not find note #${noteNumber}`,
            variant: "destructive"
          });
        }
      }
    }, [noteNumber, deliveryNotes, loading, toast]);

    const handleNoteSelection = (noteId: number) => {
        setSelectedNotes(prev => 
            prev.includes(noteId)
                ? prev.filter(id => id !== noteId)
                : [...prev, noteId]
        );
    };

    const handleNextNote = async () => {
        Logger.info('Navigating to next note', {
            current: currentNoteIndex,
            total: selectedNotes.length
        });

        if (currentNoteIndex < selectedNotes.length - 1) {
            // Allow state to settle and UI to update
            await new Promise(resolve => setTimeout(resolve, 100));
            
            // Update index and reset the current note's validation state
            setCurrentNoteIndex(prev => {
                const nextIndex = prev + 1;
                Logger.info('Moving to note', {
                    from: prev,
                    to: nextIndex,
                    noteId: selectedNotes[nextIndex]
                });
                return nextIndex;
            });

            // Clear previous note's validation state
            const nextNoteId = selectedNotes[currentNoteIndex + 1];
            setDeliveryNotes(prev => prev.map(note =>
                note.id === nextNoteId
                    ? { ...note, validatedPositions: [] }
                    : note
            ));

        } else {
            Logger.info('Reached end of notes, moving to summary');
            setImportStep('summary');
        }
    };

    const handleValidationChange = (noteId: number, positions: ValidatedPosition[]) => {
        // Filter out skipped positions immediately to prevent them from being stored
        const nonSkippedPositions = positions.filter(pos => !pos.skipped);
        
        Logger.info('Updating note positions after validation', {
            noteId,
            totalPositions: positions.length,
            nonSkippedPositions: nonSkippedPositions.length,
            skippedPositions: positions.length - nonSkippedPositions.length
        });

        setDeliveryNotes(prev => prev.map(note => 
            note.id === noteId 
                ? { ...note, validatedPositions: nonSkippedPositions }
                : note
        ));
    };

    const handleImport = async () => {
        let selectedNote: ValidatedDeliveryNote | undefined;
        
        try {
            setImporting(true);
            
            selectedNote = deliveryNotes.find(note => note.id === selectedNotes[currentNoteIndex]);
            if (!selectedNote) {
                throw new Error('Selected note not found');
            }

            // Show progress toast
            toast({
                title: 'Importing Delivery Notes',
                description: `Processing note ${currentNoteIndex + 1} of ${selectedNotes.length}`,
            });
            
            // Set note to importing status
            setDeliveryNotes(prev => prev.map(n =>
                n.id === selectedNote!.id
                    ? { ...n, importStatus: ImportStatus.IN_PROGRESS }
                    : n
            ));

            // Verify validatedPositions exists
            if (!selectedNote.validatedPositions || selectedNote.validatedPositions.length === 0) {
                throw new Error('Note has not been validated');
            }

            Logger.info('Starting import with validated positions', {
                noteNumber: selectedNote.number,
                totalPositions: selectedNote.validatedPositions.length,
                skipped: selectedNote.validatedPositions.filter(p => p.skipped).length,
                withBatch: selectedNote.validatedPositions.filter(p => p.batchMatch?.matchedBatch).length
            });

            // Check if note has positions and filter ready items
            const validatedPositions = selectedNote.validatedPositions || [];

            // Log validation state before import
            Logger.info('Note import validation state', {
                noteNumber: selectedNote.number,
                totalPositions: validatedPositions.length,
                readyToImport: validatedPositions.filter(p => !p.skipped && p.batchMatch?.matchedBatch).length,
                skipped: validatedPositions.filter(p => p.skipped).length
            });

            // Import using service with validated positions
            const result = await ImportService.importNote(selectedNote, validatedPositions);
            
            if (!result.success) {
                throw new Error(result.message);
            }

            // Update status on success
            setDeliveryNotes(prev => prev.map(n => 
                n.id === selectedNote!.id 
                    ? { ...n, importStatus: ImportStatus.COMPLETE, exists: true }
                    : n
            ));

            // Update the current note's status before proceeding
            const notesProcessed = currentNoteIndex + 1;
            
            toast({
                title: notesProcessed === selectedNotes.length ? 'Final Note Processed' : 'Note Processed',
                description: `${result.message} (Note ${notesProcessed} of ${selectedNotes.length})`,
                variant: "default"
            });

            if (currentNoteIndex < selectedNotes.length - 1) {
                // More notes to process
                Logger.info('Moving to next note', {
                    current: currentNoteIndex,
                    total: selectedNotes.length,
                    nextNoteId: selectedNotes[currentNoteIndex + 1]
                });
                
                // Small delay to allow UI update
                await new Promise(resolve => setTimeout(resolve, 1000));
                setCurrentNoteIndex(prev => prev + 1);
            } else {
                // Final note processed
                Logger.info('All notes processed', {
                    totalProcessed: selectedNotes.length
                });
                
                await new Promise(resolve => setTimeout(resolve, 1500));
                toast({
                    title: 'All Notes Complete',
                    description: `Successfully processed ${selectedNotes.length} delivery note${selectedNotes.length > 1 ? 's' : ''}`,
                    variant: "default"
                });
                
                // Navigate to invoices page after successful import
                navigate('/invoices');
            }

        } catch (error) {
            Logger.error('Error importing note:', error);
            
            // Update status on error if we have a selected note
            if (selectedNote) {
                setDeliveryNotes(prev => prev.map(n => 
                    n.id === selectedNote!.id 
                        ? { ...n, importStatus: ImportStatus.ERROR }
                        : n
                ));
            }

            toast({
                title: 'Import Failed',
                description: error instanceof Error ? error.message : 'Unknown error',
                variant: 'destructive'
            });
        } finally {
            setImporting(false);
        }
    };

    const getToken = async () => {
        Logger.info('Requesting token with scope: delivery_note');
        const response = await axios.post(`${API.BASE}${API.AUTH}`, {
            grant_type: 'client_credentials',
            client_id: API.CLIENT_ID,
            client_secret: API.CLIENT_SECRET,
            scope: 'delivery_note'
        });
        Logger.info('Token received successfully');
        return response.data.access_token;
    };

    const getDeliveryNotes = async (token: string) => {
        Logger.info('Fetching delivery notes...');
        
        if (noteNumber) {
            Logger.info('Fetching specific note', { noteNumber });
        } else {
            if (!startDate || !endDate) {
                Logger.warn('Missing date parameters');
                return { items: [] };
            }

            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            if (!dateRegex.test(startDate) || !dateRegex.test(endDate)) {
                Logger.error('Invalid date format');
                return { items: [] };
            }
        }

        const response = await axios.get(`${API.BASE}${API.DELIVERY_NOTES}`, {
            headers: { 'Authorization': `Bearer ${token}` },
            params: {
                with: 'contact,positions',  // Need full data for import
                sort: '-date',
                date_from: startDate || format(subDays(new Date(), 30), 'yyyy-MM-dd'),
                date_to: endDate || format(new Date(), 'yyyy-MM-dd')
            }
        });

        // Filter notes
        const filteredNotes = response.data.items.filter((note: DeliveryNote) => {
            // If looking for a specific note, only match by number
            if (noteNumber) {
                const matches = note.number === noteNumber;
                Logger.info('Note number check', {
                    number: note.number,
                    searching: noteNumber,
                    matches
                });
                return matches;
            }

            // Otherwise filter by date range
            const noteDate = new Date(note.date);
            const inRange = noteDate >= new Date(startDate!) && noteDate <= new Date(endDate!);
            Logger.info('Note date check', {
                number: note.number,
                date: note.date,
                inRange
            });
            return inRange;
        });

        // Warn if specific note not found
        if (noteNumber && filteredNotes.length === 0) {
            Logger.warn('Requested note not found', { noteNumber });
        }

        return { ...response.data, items: filteredNotes };
    };

    // Fetch existing invoice status
    const checkExistingInvoices = async (notes: DeliveryNote[]) => {
        const invoiceNumbers = notes.map(note => note.number);
        if (invoiceNumbers.length === 0) return {};

        const existingInvoices = await Promise.all(
            invoiceNumbers.map(number => ImportService.checkExistingInvoice(number))
        );

        return invoiceNumbers.reduce((acc, number, index) => {
            acc[number] = existingInvoices[index];
            return acc;
        }, {} as Record<string, boolean>);
    };

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                setLoading(true);
                const token = await getToken();
                const notes = await getDeliveryNotes(token);
                
                // Get existing invoice status
                const existingInvoices = await checkExistingInvoices(notes.items);
                
                setDeliveryNotes(notes.items.map((note: DeliveryNote) => ({
                    ...note,
                    exists: existingInvoices[note.number] || false,
                    importStatus: ImportStatus.NOT_STARTED,
                    validatedPositions: []
                })));
            } catch (err) {
                setError(err instanceof Error ? err.message : 'Failed to fetch notes');
            } finally {
                setLoading(false);
            }
        };

        fetchNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]); // getDeliveryNotes is stable and captures latest params

    if (loading) {
        return (
            <div className="flex items-center justify-center p-8">
                <Loader2 className="h-8 w-8 animate-spin" />
                <span className="ml-2">Loading delivery notes...</span>
            </div>
        );
    }

    if (error) {
        return <div className="p-4 text-red-600">Error: {error}</div>;
    }

    if (importStep === 'details') {
        const currentNote = deliveryNotes.find(note => note.id === selectedNotes[currentNoteIndex]);
        if (!currentNote) {
            return <div className="p-4">Note not found</div>;
        }
        
        return (
            <>
                <div className="container mx-auto px-4 mb-4">
                    <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                        <div className="flex items-center justify-between mb-2">
                            <div className="flex items-center gap-2">
                                <span className="text-blue-700 font-medium">Note {currentNoteIndex + 1} of {selectedNotes.length}</span>
                                <span className="text-xs bg-blue-100 px-2 py-0.5 rounded text-blue-700">#{currentNote.number}</span>
                            </div>
                            {currentNoteIndex > 0 && (
                                <span className="text-xs text-blue-600">
                                    {currentNoteIndex} note{currentNoteIndex !== 1 ? 's' : ''} processed
                                </span>
                            )}
                        </div>
                        <div className="w-full bg-blue-100 rounded-full h-2">
                            <div
                                className="bg-blue-600 h-2 rounded-full transition-all duration-300"
                                style={{ width: `${(currentNoteIndex / selectedNotes.length) * 100}%` }}
                            />
                        </div>
                    </div>
                </div>
                <DeliveryNoteDetails
                    key={currentNote.id} // Force full remount on note change
                    note={currentNote}
                    onNext={handleNextNote}
                    isLast={currentNoteIndex === selectedNotes.length - 1}
                    onImport={handleImport}
                    onValidationChange={(positions) => {
                        Logger.info('Validation state updated', {
                            noteId: currentNote.id,
                            noteNumber: currentNote.number,
                            positionsCount: positions.length
                        });
                        handleValidationChange(currentNote.id, positions);
                    }}
                />
            </>
        );
    }

    return (
        <div className="container mx-auto p-2 sm:p-4">
            <h1 className="text-2xl font-bold mb-4">Delivery Notes</h1>
            {deliveryNotes.length > 0 ? (
                <div className="overflow-x-auto -mx-2 sm:mx-0">
                    <div className="inline-block min-w-full align-middle">
                    <table className="min-w-full bg-white border border-gray-200 rounded-lg overflow-hidden">
                        <thead className="bg-gray-50/75 hidden sm:table-header-group">
                            <tr className="text-left">
                                <th className="py-2.5 px-4 border-b"></th>
                                <th className="py-2.5 px-4 border-b text-sm font-medium text-gray-600">Date</th>
                                <th className="py-2.5 px-4 border-b text-sm font-medium text-gray-600">Delivery Note Number</th>
                                <th className="py-2.5 px-4 border-b text-sm font-medium text-gray-600">Company Name</th>
                                <th className="py-2.5 px-4 border-b text-sm font-medium text-gray-600">Status</th>
                                <th className="py-2.5 px-4 border-b text-sm font-medium text-gray-600 text-right">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deliveryNotes.map((note) => (
                                <tr key={note.id} className="hover:bg-gray-50/50 flex flex-col sm:table-row">
                                    <td className="py-2 px-3 sm:py-1.5 sm:px-4 border-b flex sm:table-cell">
                                        <Checkbox
                                            checked={selectedNotes.includes(note.id)}
                                            onCheckedChange={() => handleNoteSelection(note.id)}
                                            disabled={importing || note.importStatus === ImportStatus.IN_PROGRESS}
                                            className={note.exists ? "bg-blue-100 border-blue-500" : ""}
                                        />
                                    </td>
                                    <td className="py-2 px-3 sm:py-1.5 sm:px-4 border-b text-sm sm:table-cell">
                                        <span className="sm:hidden text-xs text-gray-500 block mb-1">Date:</span>
                                        {note.date}
                                    </td>
                                    <td className="py-2 px-3 sm:py-1.5 sm:px-4 border-b sm:table-cell">
                                        <div className="flex items-center gap-3">
                                            <span className="font-medium">{note.number}</span>
                                            {note.positions.length > 0 && (
                                                <HoverCard>
                                                    <HoverCardTrigger>
                                                        <span className="text-xs text-gray-500 bg-gray-100 px-2 py-0.5 rounded-full hover:bg-gray-200 transition-colors cursor-help">
                                                            {note.positions.filter(p => p.catalog_type !== 'S' && p.number).length} items
                                                        </span>
                                                    </HoverCardTrigger>
                                                    <HoverCardContent className="w-80 p-0 shadow-lg bg-white">
                                                        <div className="p-3 border-b bg-blue-50/50">
                                                            <h4 className="font-medium text-sm text-blue-900">Delivery Note Items</h4>
                                                            <p className="text-xs text-blue-700/75">DN #{note.number}</p>
                                                        </div>
                                                        <div className="max-h-[250px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-50">
                                                            {note.positions
                                                                .filter(p => p.catalog_type !== 'S' && p.number)
                                                                .map((position, index) => (
                                                                    <div key={index} className="p-2.5 border-b last:border-b-0 bg-white hover:bg-gray-50/75">
                                                                        <div className="flex justify-between items-start">
                                                                            <div>
                                                                                <p className="text-xs font-medium">{position.name}</p>
                                                                                <p className="text-[11px] text-gray-500">SKU: {position.number}</p>
                                                                            </div>
                                                                            <span className="text-[11px] font-medium text-gray-900">
                                                                                CHF {Number(position.price).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </HoverCardContent>
                                                </HoverCard>
                                            )}
                                            {note.importStatus === ImportStatus.IN_PROGRESS && (
                                                <div className="flex items-center gap-1">
                                                    <Loader2 className="h-3.5 w-3.5 animate-spin text-blue-600" />
                                                    <span className="text-xs text-blue-600">Importing</span>
                                                </div>
                                            )}
                                            {note.importStatus === ImportStatus.ERROR && (
                                                <span className="inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                                    Failed
                                                </span>
                                            )}
                                        </div>
                                    </td>
                                    <td className="py-2 px-3 sm:py-1.5 sm:px-4 border-b sm:table-cell">
                                        <span className="sm:hidden text-xs text-gray-500 block mb-1">Company:</span>
                                        <span className="text-sm">{note.contact?.name || 'N/A'}</span>
                                    </td>
                                    <td className="py-2 px-3 sm:py-1.5 sm:px-4 border-b sm:table-cell">
                                        <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
                            'bg-emerald-50 text-emerald-700'
                                        }`}>
                            {note.status || 'IMS'}
                                        </span>
                                    </td>
                                    <td className="py-2 px-3 sm:py-1.5 sm:px-4 border-b sm:text-right sm:table-cell">
                                        <span className="sm:hidden text-xs text-gray-500 block mb-1">Amount:</span>
                                        <span className="text-sm font-medium">CHF {note.total}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                    <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t shadow-lg z-50">
                        <div className="container mx-auto flex justify-end">
                            <Button
                                onClick={() => {
                                    setCurrentNoteIndex(0); // Reset to first note
                                    setImportStep('details');
                                }}
                                disabled={selectedNotes.length === 0 || importing}
                                className="bg-blue-600 hover:bg-blue-700 text-white font-medium px-6 shadow-sm"
                                size="sm"
                            >
                                {importing ? (
                                    <>
                                        <Loader2 className="h-3.5 w-3.5 animate-spin mr-2" />
                                        Importing...
                                    </>
                                ) : (
                                    <>
                                        Review Selected
                                        <span className="ml-2 px-1.5 py-0.5 bg-blue-500 text-xs rounded-full">
                                            {selectedNotes.length}
                                        </span>
                                    </>
                                )}
                            </Button>
                        </div>
                    </div>
                    {/* Add padding at the bottom to prevent content from being hidden behind fixed button */}
                    <div className="h-20" />
                </div>
            ) : (
                <p>No delivery notes found.</p>
            )}
        </div>
    );
}
