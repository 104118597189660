import React from 'react';
import { InventoryItem } from "@/types/inventory";
import { Input } from "@/components/ui/input";
import { Search, Trash2, Pencil } from "lucide-react";
import { AddProductDialog } from "./AddProductDialog";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from '@/contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { MobileHeader } from '../layout/MobileHeader';
import { Button } from "@/components/ui/button";

interface MobileInventoryViewProps {
  inventory: InventoryItem[];
  filterText: string;
  onFilterChange: (value: string) => void;
  stockInfo: Record<string, { totalQuantity: number; batchCount: number; }>;
  onInventoryChange: () => void;
}

export function MobileInventoryView({
  inventory,
  filterText,
  onFilterChange,
  stockInfo,
  onInventoryChange
}: MobileInventoryViewProps) {
  const { canManageInventory } = useAuth();
  const { toast } = useToast();

  const handleDelete = async (item: InventoryItem) => {
    if (!window.confirm('Are you sure you want to delete this item?')) return;

    if (stockInfo[item.sku]?.batchCount > 0) {
      toast({
        variant: "destructive",
        title: "Cannot delete item",
        description: "This item has active batches. Please delete all batches first."
      });
      return;
    }

    const { error } = await supabase
      .from('inventory')
      .delete()
      .eq('sku', item.sku);

    if (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Could not delete item. It may be referenced in invoices."
      });
      return;
    }

    toast({
      title: "Success",
      description: "Item deleted successfully"
    });
    onInventoryChange();
  };

  return (
    <div className="lg:hidden">
      <MobileHeader />
      <div className="fixed top-14 left-0 right-0 bottom-0 bg-white z-10 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
        {/* Header */}
        <div className="px-4 py-3 bg-white border-b">
          <div className="flex items-center gap-2 mb-2">
            <h1 className="text-xl font-bold">Inventory</h1>
            <div className="bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full text-sm font-medium">
              {inventory.length} items
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
              <Input
                placeholder="Search by SKU or name..."
                value={filterText}
                onChange={(e) => onFilterChange(e.target.value)}
                className="pl-9 pr-3 w-full"
                autoFocus={false}
              />
            </div>
            {canManageInventory && (
              <div className="flex-none">
                <AddProductDialog 
                  onProductAdded={onInventoryChange}
                  variant="icon"
                />
              </div>
            )}
          </div>
        </div>

        {/* Items List */}
        {inventory.map((item) => (
          <div key={item.sku} className="mx-4 my-3 bg-amber-50 rounded-lg shadow border-b border-gray-200">
            <div className="p-3">
              <div className="flex justify-between items-start gap-2">
                <h3 className="text-sm font-medium text-gray-900 leading-tight max-w-[75%]">{item.item_name}</h3>
                <div className="flex items-center gap-2">
                  {canManageInventory && (
                    <>
                      <AddProductDialog
                        mode="edit"
                        initialItem={item}
                        onProductAdded={onInventoryChange}
                        variant="icon"
                        trigger={
                          <Button
                            variant="ghost"
                            size="icon"
                            className="text-blue-600 hover:text-blue-800 p-1 rounded-md hover:bg-blue-50 h-8 w-8"
                          >
                            <Pencil className="h-4 w-4" />
                          </Button>
                        }
                      />
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleDelete(item)}
                        className="text-red-600 hover:text-red-800 p-1 rounded-md hover:bg-red-50 h-8 w-8"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </>
                  )}
                </div>
              </div>

              {/* Grid layout for item details */}
              <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
                <div className="bg-gray-50 p-1.5 rounded">
                  <span className="text-gray-500">SKU: </span>
                  <span className="font-medium text-gray-900">{item.sku}</span>
                </div>
                <div className="bg-gray-50 p-1.5 rounded">
                  <span className="text-gray-500">Available: </span>
                  <span className="font-medium text-gray-900">
                    {item.is_weight_based
                      ? `${(stockInfo[item.sku]?.totalQuantity || 0).toFixed(2)} kg`
                      : Math.round(stockInfo[item.sku]?.totalQuantity || 0)}
                  </span>
                </div>
                <div className="bg-gray-50 p-1.5 rounded">
                  <span className="text-gray-500">Active Batches: </span>
                  <span className="font-medium text-gray-900">
                    {stockInfo[item.sku]?.batchCount || 0}
                  </span>
                </div>
                <div className="bg-gray-50 p-1.5 rounded">
                  <span className="text-gray-500">VAT: </span>
                  <span className="font-medium text-gray-900">{item.vat_rate}%</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
