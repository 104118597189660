import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { EnhancedInventoryTable } from "@/components/inventory/EnhancedInventoryTable";
import { MobileHeader } from "@/components/layout/MobileHeader";
import type { InventoryItem } from "@/types/inventory";
import { Badge } from "@/components/ui/badge";
import { MobileInventoryView } from "@/components/inventory/MobileInventoryView";
import { AddProductDialog } from "@/components/inventory/AddProductDialog";

export default function InventoryPage() {
  const [lastRefreshTime, setLastRefreshTime] = useState(() => new Date());
  const [mobileFilterText, setMobileFilterText] = useState("");

  const { data: inventory, refetch: refetchInventory, isLoading: isInventoryLoading } = useQuery({
    queryKey: ['inventory'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('inventory')
        .select('*')
        .order('item_name');
      
      if (error) throw error;
      return data as InventoryItem[];
    },
    staleTime: Infinity
  });

  const { data: batches, refetch: refetchBatches, isLoading: isBatchesLoading } = useQuery({
    queryKey: ['batches'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('batches')
        .select(`
          *,
          inventory:inventory(*),
          invoice_items:invoice_items(
            weight_kilos,
            quantity,
            invoice:invoices(status)
          )
        `);
      
      if (error) throw error;
      return data;
    },
    staleTime: Infinity
  });

  const stockInfo = React.useMemo(() => {
    if (!batches || !inventory) return {};
    
    const stockData: Record<string, { totalQuantity: number; batchCount: number }> = {};
    
    // Initialize stockData for all inventory items
    inventory.forEach(item => {
      stockData[item.sku] = {
        totalQuantity: 0,
        batchCount: 0
      };
    });

    // Group batches by SKU
    const batchesBySku = inventory.reduce((acc, item) => {
      acc[item.sku] = batches.filter(b => b.sku === item.sku);
      return acc;
    }, {} as Record<string, typeof batches>);

    // Calculate totals for each SKU using base_id grouping
    for (const [sku, skuBatches] of Object.entries(batchesBySku)) {
      const item = inventory.find(i => i.sku === sku);
      if (!item) continue;

      // Count base batches (non-shrinkage batches)
      stockData[sku].batchCount = skuBatches.filter(b => !b.batch_number.startsWith('SHR-')).length;

      // Calculate total quantity using base_id grouping
      const baseBatches = new Set(skuBatches.map(b => b.base_id || b.batch_number));
      baseBatches.forEach(baseId => {
        const relatedBatches = skuBatches.filter(b => 
          b.batch_number === baseId || 
          b.base_id === baseId
        );

        // Calculate net quantity for this batch group
        const netQuantity = relatedBatches.reduce((sum, batch) => {
          const amount = item.is_weight_based ? 
            (batch.weight_kilos || 0) : 
            (batch.quantity || 0);
          const used = (batch.invoice_items || []).reduce((u, invItem) => 
            u + (item.is_weight_based ? (invItem.weight_kilos || 0) : (invItem.quantity || 0)), 0);
          return sum + (amount - used);
        }, 0);

        // Only add positive quantities
        if (netQuantity > 0) {
          stockData[sku].totalQuantity += netQuantity;
        }
      });
    }

    return stockData;
  }, [batches, inventory]);

  const handleRefresh = async () => {
    await Promise.all([refetchInventory(), refetchBatches()]);
    setLastRefreshTime(new Date());
  };

  // Attach stockInfo to inventory items
  const enrichedInventory = React.useMemo(() => {
    if (!inventory) return [];
    return inventory.map(item => ({
      ...item,
      stockInfo: stockInfo[item.sku]
    }));
  }, [inventory, stockInfo]);

  // Filtered inventory for mobile view
  const filteredMobileInventory = React.useMemo(() => 
    enrichedInventory.filter(item => 
      item.sku.toLowerCase().includes(mobileFilterText.toLowerCase()) ||
      item.item_name.toLowerCase().includes(mobileFilterText.toLowerCase())
    ), [enrichedInventory, mobileFilterText]
  );

  // Loading state combines both inventory and batches loading
  const isLoading = isInventoryLoading || isBatchesLoading;

  return (
    <div className="min-h-screen">
      {/* Mobile View */}
      <div className="lg:hidden">
        <MobileInventoryView
          inventory={filteredMobileInventory}
          filterText={mobileFilterText}
          onFilterChange={setMobileFilterText}
          onInventoryChange={refetchInventory}
          stockInfo={stockInfo}
        />
      </div>

      {/* Desktop View */}
      <div className="hidden lg:block min-h-screen">
        <div className="flex items-center gap-3 mb-8">
          <h1 className="text-2xl font-bold">Master Inventory</h1>
          <Badge variant="secondary" className="h-7 px-3">
            {inventory?.length || 0} items
          </Badge>
          <div className="text-sm text-gray-500">
            Last updated: {lastRefreshTime.toLocaleString()}
          </div>
        </div>

        <EnhancedInventoryTable 
          inventory={enrichedInventory}
          onItemUpdated={refetchInventory}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
