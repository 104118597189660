import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Batch } from "@/types/batch";
import { supabase } from "@/lib/supabase";
import { useQuery } from "@tanstack/react-query";
import { TrendingUp, TrendingDown } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { calculateAvailable } from "@/utils/batchUtils";

import { EditBatchDialog } from "./EditBatchDialog";

interface ViewBatchDialogProps {
  batch: Batch;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onBatchUpdated?: () => void;
}

interface PriceHistoryProps {
  sku: string;
  currentBatchNumber: string;
}

function PriceHistory({ sku, currentBatchNumber }: PriceHistoryProps) {
  const { data: recentBatches } = useQuery({
    queryKey: ['batch-history', sku],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('batches')
        .select('batch_number, date_received, purchase_cost')
        .eq('sku', sku)
        .order('date_received', { ascending: false })
        .limit(10);

      if (error) throw error;
      return data || [];
    },
  });

  if (!recentBatches?.length) return null;

  // Calculate percentage changes
  const batchesWithChanges = recentBatches.map((batch, index) => {
    let percentChange = null;
    if (index < recentBatches.length - 1) {
      const prevCost = recentBatches[index + 1].purchase_cost;
      const currentCost = batch.purchase_cost;
      if (prevCost !== null && prevCost !== undefined && 
          currentCost !== null && currentCost !== undefined) {
        percentChange = ((currentCost - prevCost) / prevCost) * 100;
      }
    }
    return { ...batch, percentChange };
  });

  return (
    <div className="divide-y divide-gray-100">
      {batchesWithChanges.map((batch) => (
        <div 
          key={batch.batch_number}
          className={cn(
            "flex items-center justify-between px-3 py-2",
            batch.batch_number === currentBatchNumber ? "bg-blue-50" : "hover:bg-gray-50"
          )}
        >
          <div className="flex-1">
            <div className="font-medium">
              {new Date(batch.date_received).toLocaleDateString()}
            </div>
            <div className="text-sm text-gray-500 flex items-center gap-1">
              {batch.batch_number}
              {batch.batch_number === currentBatchNumber && (
                <span className="text-xs bg-blue-100 text-blue-800 px-1.5 py-0.5 rounded-sm">Current</span>
              )}
            </div>
          </div>
          <div className="text-right flex items-center gap-2">
            <div>
              <div className="font-medium">
                {batch.purchase_cost !== null && batch.purchase_cost !== undefined 
                  ? `CHF ${batch.purchase_cost.toFixed(2)}` 
                  : '-'}
              </div>
              {batch.percentChange !== null && (
                <div className={cn(
                  "text-sm flex items-center justify-end gap-0.5",
                  batch.percentChange > 0 ? "text-red-600" : batch.percentChange < 0 ? "text-green-600" : "text-gray-500"
                )}>
                  {batch.percentChange > 0 ? (
                    <TrendingUp className="h-3 w-3" />
                  ) : batch.percentChange < 0 ? (
                    <TrendingDown className="h-3 w-3" />
                  ) : null}
                  {batch.percentChange > 0 ? "+" : ""}
                  {batch.percentChange.toFixed(1)}%
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export function ViewBatchDialog({ batch, open, onOpenChange, onBatchUpdated }: ViewBatchDialogProps) {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [batchDetails, setBatchDetails] = useState(null);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBatchDetails = async () => {
      if (!open || !batch.batch_number) return;
      
      setLoading(true);
      
      try {
        // Determine if this is a shrinkage entry
        const isShrinkage = batch.batch_number.startsWith('SHR-');
        
        // If this is a shrinkage entry, use its base_id to get the original batch
        // Otherwise, use the batch number directly
        const queryId = isShrinkage && batch.base_id ? batch.base_id : batch.batch_number;
        
        console.log("Querying with ID:", queryId, "isShrinkage:", isShrinkage);
        
        // First, get the original batch
        let { data: originalBatchData, error: originalBatchError } = await supabase
          .from("batches")
          .select("*, inventory(*)")
          .eq("batch_number", queryId)
          .single();
          
        if (originalBatchError && isShrinkage) {
          console.error("Error fetching original batch:", originalBatchError);
          setLoading(false);
          return;
        }

        // For shrinkage entries, we need to get all related batches including the original
        // For regular batches, just get all related shrinkage entries
        const baseId = isShrinkage ? batch.base_id : batch.batch_number;
        
        // Get all related batches (original + shrinkage entries)
        const { data: relatedBatches, error: relatedError } = await supabase
          .from("batches")
          .select("*, inventory(*)")
          .or(`batch_number.eq.${baseId},base_id.eq.${baseId}`)
          .order("created_at", { ascending: false });
        
        if (relatedError) {
          console.error("Error fetching related batches:", relatedError);
          // Even with an error, continue with what we have
        }
        
        // Combine the data
        let batchData = relatedBatches || [];
        
        // If this is a shrinkage entry and we couldn't get related batches,
        // at least include the current batch
        if (batchData.length === 0) {
          if (isShrinkage && originalBatchData) {
            batchData = [originalBatchData, batch];
          } else {
            batchData = [batch];
          }
        }

        console.log("Combined batch data:", batchData);

        if (batchData && batchData.length > 0) {
          // For display purposes in details, use the current batch
          const currentBatchDetails = isShrinkage && originalBatchData 
            ? { ...batch, inventory: originalBatchData.inventory }
            : batchData.find(b => b.batch_number === batch.batch_number) || batchData[0];
            
          setBatchDetails(currentBatchDetails);
          
          // Calculate available balance using the original batch and all shrinkage entries
          // Get the original batch (non-shrinkage) 
          const originalBatch = batchData.find(b => !b.batch_number.includes('SHR-')) || batchData[0];
          const shrinkageEntries = batchData.filter(b => b.batch_number.includes('SHR-'));
          
          // Get the quantity or weight based on what the item uses
          const isWeightBased = originalBatch.inventory?.is_weight_based;
          let availableBalance = isWeightBased 
            ? (originalBatch.weight_kilos || 0)
            : (originalBatch.quantity || 0);
          
          // Add shrinkage entries (which are negative values)
          shrinkageEntries.forEach(entry => {
            const shrinkageAmount = isWeightBased 
              ? (entry.weight_kilos || 0)
              : (entry.quantity || 0);
            
            availableBalance += shrinkageAmount;
          });
          
          console.log("Calculated available balance:", availableBalance, 
                     "isWeightBased:", isWeightBased, 
                     "originalValue:", isWeightBased ? originalBatch.weight_kilos : originalBatch.quantity);
          
          setAvailableBalance(availableBalance);
        } else {
          console.log("No batch data found");
          setBatchDetails(null);
          setAvailableBalance(0);
        }
      } catch (error) {
        console.error("Error in batch fetching:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBatchDetails();
  }, [open, batch]);

  const getAvailableQuantity = () => {
    if (loading) return "Loading...";
    if (!batchDetails) return "0";
    
    const isWeightBased = batchDetails.inventory?.is_weight_based;
    return availableBalance.toFixed(isWeightBased ? 2 : 0);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] w-[95vw] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <div className="flex items-center gap-2">
            <DialogTitle className="text-xl font-semibold tracking-tight">Batch Details</DialogTitle>
            
            {/* Add Shrinkage Badge if batch is a shrinkage entry */}
            {batch.batch_number.startsWith('SHR-') && (
              <div className="bg-red-100 text-red-700 px-3 py-1 rounded-md text-sm font-medium">
                Shrinkage Entry
              </div>
            )}
            
            <div className="text-muted-foreground hover:text-accent transition-colors ml-auto">
              <EditBatchDialog
                batch={batch}
                onBatchUpdated={() => {
                  onBatchUpdated?.();
                  onOpenChange?.(false);
                }}
              />
            </div>
          </div>
        </DialogHeader>

        {/* If this is a shrinkage entry, show the original batch it's linked to */}
        {batch.batch_number.startsWith('SHR-') && batch.base_id && (
          <div className="bg-red-50 border border-red-100 rounded-md p-3 mb-4">
            <div className="text-sm text-red-700 font-medium">Shrinkage Information</div>
            <div className="mt-1 text-sm">
              <span className="text-gray-600">Original Batch: </span>
              <span className="font-medium">{batch.base_id}</span>
            </div>
            {batch.shrinkage_reason && (
              <div className="mt-1 text-sm">
                <span className="text-gray-600">Reason: </span>
                <span className="font-medium">{batch.shrinkage_reason}</span>
              </div>
            )}
          </div>
        )}

        <div className="space-y-6">
          {/* Batch Info Section */}
          <div className="rounded-lg border border-accent/20 bg-accent/5 p-3">
            <div className="flex flex-wrap items-center gap-4">
              <div className="flex items-center gap-4 min-w-[200px]">
                <div>
                  <div className="text-sm text-muted-foreground mb-1">Batch Number</div>
                  <div className="text-lg font-semibold text-accent">{batch.batch_number}</div>
                </div>
                <div className="h-8 w-[1px] bg-accent/20" />
                <div>
                  <div className="text-sm text-muted-foreground mb-1">SKU</div>
                  <div className="text-lg font-semibold">{batch.sku}</div>
                </div>
              </div>
              {batchDetails?.inventory?.item_name && (
                <div className="text-sm text-muted-foreground flex-1 text-right">
                  {batchDetails.inventory.item_name}
                </div>
              )}
            </div>
          </div>

          {/* Key Info Section */}
          <div className="space-y-4">
            {/* Row 1: Purchase Cost and Available */}
            <div className="grid grid-cols-2 gap-4">
              <div className="rounded-lg border border-blue-100 bg-blue-50/50 p-3 flex flex-col h-[80px]">
                <div className="text-sm font-medium text-blue-600 mb-1">Purchase Cost</div>
                <div className="flex flex-col justify-between flex-1">
                  <div className="font-semibold">
                    {batch.purchase_cost !== null && batch.purchase_cost !== undefined
                      ? `CHF ${batch.purchase_cost.toFixed(2)}/${batchDetails?.inventory?.is_weight_based ? 'kilo' : 'unit'}`
                      : '-'}
                  </div>
                </div>
              </div>

              <div className="rounded-lg border p-3 flex flex-col h-[80px]">
                <div className="text-sm font-medium text-muted-foreground mb-1">Available</div>
                <div className="flex flex-col justify-between flex-1">
                  <div className="font-semibold text-accent">
                    {batchDetails ? (
                      <span>
                        {batchDetails.inventory?.is_weight_based
                          ? `${getAvailableQuantity()} kg`
                          : `${getAvailableQuantity()} units`}
                      </span>
                    ) : (
                      <span className="text-muted-foreground">Loading...</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Row 2: QTY Received and Warehouse */}
            <div className="grid grid-cols-2 gap-4">
              <div className="rounded-lg border p-3 flex flex-col h-[80px]">
                <div className="text-sm font-medium text-muted-foreground mb-1">QTY Received</div>
                <div className="flex flex-col justify-between flex-1">
                  <div className="font-semibold">
                    {batchDetails?.inventory?.is_weight_based ? (
                      <span>{batch.weight_kilos} kg</span>
                    ) : (
                      <span>{batch.quantity} units</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="rounded-lg border p-3 flex flex-col h-[80px]">
                <div className="text-sm font-medium text-muted-foreground mb-1">Warehouse</div>
                <div className="flex flex-col justify-between flex-1">
                  <div className="font-semibold">{batch.warehouse}</div>
                </div>
              </div>
            </div>

            {/* Row 3: Dates */}
            <div className="grid grid-cols-2 gap-4">
              <div className="rounded-lg border bg-secondary/5 p-3">
                <div className="text-sm font-medium text-muted-foreground mb-1">Date Received</div>
                <div className="font-medium">{batch.date_received}</div>
              </div>

              <div className="rounded-lg border bg-secondary/5 p-3">
                <div className="text-sm font-medium text-muted-foreground mb-1">Expiry Date</div>
                <div className="font-medium">{batch.expiry_date || 'N/A'}</div>
              </div>
            </div>
          </div>

          {/* Notes Section (if present) */}
          {batch.notes && (
            <div className="rounded-lg border p-3">
              <div className="text-sm font-medium text-muted-foreground mb-1">Notes</div>
              <div className="text-sm text-muted-foreground whitespace-pre-wrap">{batch.notes}</div>
            </div>
          )}

          {/* Shrinkage History Section */}
          {!batch.batch_number.startsWith('SHR-') && batchDetails?.related_shrinkage?.length > 0 && (
            <div className="rounded-lg border border-red-200 bg-red-50/50 p-3">
              <div className="text-sm font-medium text-red-600 mb-2">Shrinkage History</div>
              <div className="divide-y divide-red-100">
                {batchDetails.related_shrinkage.map((shrinkage) => (
                  <div key={shrinkage.batch_number} className="py-2">
                    <div className="flex items-center justify-between">
                      <div className="text-sm font-medium">{shrinkage.shrinkage_reason}</div>
                      <div className="text-sm text-red-600">
                        {batchDetails.inventory?.is_weight_based
                          ? `${Math.abs(shrinkage.weight_kilos || 0)} kg`
                          : `${Math.abs(shrinkage.quantity || 0)} units`}
                      </div>
                    </div>
                    <div className="text-xs text-gray-500 mt-1">
                      {new Date(shrinkage.date_received).toLocaleDateString()}
                    </div>
                    {shrinkage.notes && (
                      <div className="text-xs text-gray-600 mt-1">{shrinkage.notes}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Price History Section */}
          <div className="rounded-lg border p-3">
            <div className="text-sm font-medium text-muted-foreground mb-2">Price History</div>
            <PriceHistory sku={batch.sku} currentBatchNumber={batch.batch_number} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
