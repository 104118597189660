import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { ArrowUpFromLine, FileText, Upload, Download } from "lucide-react";

interface DataActionsMenuProps {
  onExport?: () => void;
  onImport?: () => void;
  onAnalyzeCsv?: () => void;
  // Additional action handlers can be added as needed
}

export function DataActionsMenu({ 
  onExport, 
  onImport,
  onAnalyzeCsv
}: DataActionsMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm">
          <ArrowUpFromLine className="mr-2 h-4 w-4" />
          Actions
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="bg-white border rounded-md shadow-md">
        {onImport && (
          <DropdownMenuItem 
            onClick={onImport}
            className="cursor-pointer flex items-center p-2 hover:bg-gray-100"
          >
            <Upload className="h-4 w-4 mr-2" />
            Import Data
          </DropdownMenuItem>
        )}
        {onExport && (
          <DropdownMenuItem 
            onClick={onExport}
            className="cursor-pointer flex items-center p-2 hover:bg-gray-100"
          >
            <Download className="h-4 w-4 mr-2" />
            Export Data
          </DropdownMenuItem>
        )}
        {onAnalyzeCsv && (
          <DropdownMenuItem 
            onClick={onAnalyzeCsv}
            className="cursor-pointer flex items-center p-2 hover:bg-gray-100"
          >
            <FileText className="h-4 w-4 mr-2" />
            Analyze CSV
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
