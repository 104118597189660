import React, { useMemo } from 'react';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Legend
} from 'recharts';
import { cn } from "@/lib/utils";
import { useIsMobile } from "@/hooks/use-mobile";

import { MonthlyCosts } from '@/utils/costAnalysis';

interface YearlyCostChartProps {
  data: MonthlyCosts[];
  className?: string;
}

export function YearlyCostChart({ data, className }: YearlyCostChartProps) {
  const isMobile = useIsMobile();

  const formattedData = useMemo(() => {
    return data.map(item => {
      const totalCost = Number(item.totalCost.toFixed(2));
      const totalInvoiced = item.totalInvoiced === 0 ? null : Number(item.totalInvoiced.toFixed(2));
      
      // Calculate margin percentage for non-zero invoice amounts
      let marginPercent = null;
      if (totalInvoiced && totalCost > 0) {
        marginPercent = ((totalInvoiced - totalCost) / totalCost) * 100;
      }

      return {
        ...item,
        totalCost,
        totalInvoiced,
        marginPercent
      };
    });
  }, [data]);

  interface CustomTooltipProps {
    active?: boolean;
    payload?: Array<{ value: number; dataKey: string }>;
    label?: string;
  }

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (!active || !payload || !payload.length) return null;

    const purchase = payload.find(p => p.dataKey === 'totalCost');
    const invoiced = payload.find(p => p.dataKey === 'totalInvoiced');
    const margin = payload.find(p => p.dataKey === 'marginPercent');
    
    const purchaseValue = purchase?.value;
    const invoicedValue = invoiced?.value;
    const marginValue = margin?.value;

    return (
      <div className="rounded-lg bg-white shadow-lg border border-gray-100 p-3">
        <div className="font-medium text-sm text-gray-600">{label}</div>
        <div className="space-y-1 mt-1">
          {purchaseValue !== undefined && (
            <div className="text-base font-semibold text-blue-600">
              Purchase: CHF {Math.round(purchaseValue).toLocaleString()}
            </div>
          )}
          {invoicedValue !== undefined && (
            <div className="text-base font-semibold text-orange-500">
              Invoiced: CHF {Math.round(invoicedValue).toLocaleString()}
            </div>
          )}
          {marginValue !== undefined && marginValue !== null && (
            <div className={cn(
              "text-base font-semibold mt-1",
              marginValue > 0 ? "text-emerald-600" : "text-red-600"
            )}>
              Margin: {marginValue > 0 ? '+' : ''}{Math.round(marginValue)}%
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={cn(
      "w-full rounded-xl border bg-white shadow-sm",
      className
    )}>
      <div className="p-4 sm:p-6">
        <h3 className="font-semibold text-lg text-gray-900 mb-6">
         Purchase Costs vs. Delivery Note Totals
       </h3>
       <div className="w-full" style={{
         height: isMobile ? 300 : 250
       }}>
         <ResponsiveContainer width="100%" height="100%">
           <ComposedChart
             data={formattedData}
             margin={{
               top: 20,
               right: isMobile ? 40 : 60,
               left: isMobile ? 0 : 8,
               bottom: isMobile ? 30 : 20
             }}
             barSize={isMobile ? 32 : 48}
           >
             <XAxis
               dataKey="month"
               axisLine={false}
               tickLine={false}
               tick={{
                 fontSize: isMobile ? 11 : 12,
                 fill: '#64748b',
               }}
               dy={8}
             />
             <YAxis
               yAxisId="cost"
               tickFormatter={(value) => `${value/1000}k`}
               axisLine={false}
               tickLine={false}
               tick={{
                 fontSize: isMobile ? 11 : 12,
                 fill: '#64748b',
               }}
               width={40}
               domain={[0, 30000]}
               ticks={[0, 5000, 10000, 15000, 20000, 25000, 30000]}
             />
             <YAxis
               yAxisId="invoiced"
               orientation="right"
               tickFormatter={(value) => `${value/1000}k`}
               axisLine={false}
               tickLine={false}
               tick={{
                 fontSize: isMobile ? 11 : 12,
                 fill: '#64748b',
               }}
               width={40}
               domain={[0, 30000]}
               ticks={[0, 5000, 10000, 15000, 20000, 25000, 30000]}
             />
             <Tooltip
               cursor={{ fill: '#f8fafc' }}
               content={CustomTooltip}
             />
             <Legend
               verticalAlign="top"
               height={36}
               formatter={(value) => {
                 return value === 'totalCost' ? 'Purchase Cost' : 'Invoiced Amount';
               }}
             />
             <Bar
               yAxisId="cost"
               dataKey="totalCost"
               fill="#3b82f6"
               radius={[4, 4, 0, 0]}
               name="totalCost"
             >
               <LabelList
                 dataKey="totalCost"
                 position="top"
                 formatter={(value: number) => `${Math.round(value/1000)}k`}
                 style={{
                   fontSize: isMobile ? '10px' : '11px',
                   fill: '#64748b',
                   fontWeight: 500
                 }}
               />
               <LabelList
                 dataKey="marginPercent"
                 position="center"
                 formatter={(value: number | null) => {
                   if (value === null || isMobile) return '';
                   const rounded = Math.round(value);
                   return `${rounded > 0 ? '+' : ''}${rounded}%`;
                 }}
                 style={{
                   fontSize: isMobile ? '11px' : '12px',
                   fill: 'white',
                   fontWeight: 700
                 }}
               />
             </Bar>
             <Line
               yAxisId="invoiced"
               type="monotone"
               dataKey="totalInvoiced"
               stroke="#f97316"
               strokeWidth={2}
               name="totalInvoiced"
               connectNulls
               dot={{
                 fill: '#f97316',
                 r: 4
               }}
             />
             {/* Hidden line to make marginPercent available in tooltip */}
             <Line
               yAxisId="cost"
               type="monotone"
               dataKey="marginPercent"
               stroke="transparent"
               name="marginPercent"
               dot={false}
               label={false}
             />
           </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}