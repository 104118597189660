import React from 'react';
import { cn } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Invoice } from '@/types/invoice';
import { useIsMobile } from '@/hooks/use-mobile';

interface ViewInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  invoice: Invoice | null;
}

export const ViewInvoiceDialog: React.FC<ViewInvoiceDialogProps> = ({ open, onClose, invoice }) => {
  const isMobile = useIsMobile();
  if (!invoice) return null;

  const renderMobileItems = () => (
    <div className="space-y-4">
      {invoice.items?.map((item, index) => (
        <Card key={index} className="p-4">
          {/* Item Header */}
          <div className="flex flex-col mb-3">
            <h4 className="font-medium text-base">{item.item_name}</h4>
            <span className="text-sm text-gray-500">SKU: {item.sku}</span>
          </div>

          {/* Item Details */}
          <div className="grid grid-cols-2 gap-2 text-sm mb-3">
            <div className="space-y-1">
              <span className="text-gray-500 block text-xs">Batch</span>
              <span className="font-medium">{item.batch_number}</span>
            </div>
            <div className="space-y-1">
              <span className="text-gray-500 block text-xs">Warehouse</span>
              <span className="font-medium">{item.warehouse}</span>
            </div>
          </div>

          {/* Item Amounts */}
          <div className="grid grid-cols-3 gap-2 text-sm pt-3 border-t">
            <div className="text-right space-y-1">
              <span className="text-gray-500 block text-xs">Amount</span>
              <span className="font-medium">
                {item.measurement_type === 'weight'
                  ? `${item.weight_kilos} kg`
                  : item.quantity}
              </span>
            </div>
            <div className="text-right space-y-1">
              <span className="text-gray-500 block text-xs">Unit Price</span>
              <span className="font-medium">
                CHF {item.unit_price?.toFixed(2) || '0.00'}
              </span>
            </div>
            <div className="text-right space-y-1">
              <span className="text-gray-500 block text-xs">Total</span>
              <span className="font-medium">
                CHF {((item.unit_price || 0) * (
                  item.measurement_type === 'weight'
                    ? (item.weight_kilos || 0)
                    : (item.quantity || 1)
                )).toFixed(2)}
              </span>
            </div>
          </div>
        </Card>
      ))}
      
      {/* Total Card */}
      <Card className="p-4 bg-gray-50">
        <div className="flex justify-between items-center">
          <span className="font-medium text-gray-700">Total Amount</span>
          <span className="text-lg font-bold">
            CHF {invoice.total_amount?.toFixed(2) || '0.00'}
          </span>
        </div>
      </Card>
    </div>
  );

  const renderDesktopItems = () => (
    <div className="rounded-lg overflow-hidden border">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <Table>
            <TableHeader>
              <TableRow className="bg-gray-50">
                <TableHead className="font-semibold">SKU</TableHead>
                <TableHead className="font-semibold">Item Name</TableHead>
                <TableHead className="font-semibold text-center">Batch #</TableHead>
                <TableHead className="font-semibold text-center">Warehouse</TableHead>
                <TableHead className="font-semibold text-right">Amount</TableHead>
                <TableHead className="font-semibold text-right">Unit Price</TableHead>
                <TableHead className="font-semibold text-right">Total</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {invoice.items?.map((item, index) => (
                <TableRow key={index} className="hover:bg-gray-50">
                  <TableCell className="font-medium whitespace-nowrap">{item.sku}</TableCell>
                  <TableCell>{item.item_name}</TableCell>
                  <TableCell className="text-center whitespace-nowrap">{item.batch_number}</TableCell>
                  <TableCell className="text-center whitespace-nowrap">{item.warehouse}</TableCell>
                  <TableCell className="text-right whitespace-nowrap">
                    {item.measurement_type === 'weight'
                      ? `${item.weight_kilos} kg`
                      : item.quantity}
                  </TableCell>
                  <TableCell className="text-right whitespace-nowrap">
                    CHF {item.unit_price?.toFixed(2) || '0.00'}
                  </TableCell>
                  <TableCell className="text-right whitespace-nowrap">
                    CHF {((item.unit_price || 0) * (
                      item.measurement_type === 'weight'
                        ? (item.weight_kilos || 0)
                        : (item.quantity || 1)
                    )).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow className="border-t-2">
                <TableCell colSpan={6} className="text-right font-medium">
                  Total Amount:
                </TableCell>
                <TableCell className="text-right font-bold whitespace-nowrap">
                  CHF {invoice.total_amount?.toFixed(2) || '0.00'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );

  return (
    <Dialog open={open} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className={cn(
        // Base styles
        "w-[calc(100vw-24px)] sm:w-auto sm:max-w-[800px]",
        // Responsive styles
        isMobile
          ? "!fixed !inset-0 !top-0 !left-0 !translate-x-0 !translate-y-0 !w-full !rounded-none !h-full overflow-y-auto p-3"  // Mobile: full screen
          : "max-h-[85vh] overflow-y-auto !p-0"  // Desktop: scrollable with max height
      )}>
        <DialogHeader className={cn(
          "relative",
          isMobile && "sticky top-0 bg-white z-10 pb-3 border-b"
        )}>
          <div className={cn(
            !isMobile && "p-6 pb-4" // Add padding for desktop view
          )}>
            <div className="flex items-center justify-between gap-4">
              <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                <div className="flex flex-col">
                  <DialogTitle className="text-2xl font-bold">
                    Delivery Note #{invoice.invoice_number}
                  </DialogTitle>
                  <span className="mt-2 inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium self-start bg-green-100 text-green-800">
                    Status: {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                  </span>
                </div>
              </div>
              {isMobile && (
                <Button 
                  variant="ghost" 
                  size="icon"
                  onClick={onClose}
                  className="h-8 w-8 rounded-full hover:bg-gray-100"
                >
                  ✕
                </Button>
              )}
            </div>
          </div>
        </DialogHeader>

        <div className={cn(
          "space-y-4 sm:space-y-6 mt-4",
          !isMobile ? "p-6 pt-4" : "px-3" // Add padding for desktop view
        )}>
          {/* Invoice Details Card */}
          <Card className="p-4 sm:p-6 bg-gray-50">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              <div>
                <h3 className="text-sm font-medium text-gray-500">Company</h3>
                <p className="mt-1 text-lg font-semibold">{invoice.company}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Date</h3>
                <p className="mt-1 text-lg font-semibold">
                  {invoice.date ? new Date(invoice.date).toLocaleDateString() : 'Invalid Date'}
                </p>
              </div>
              {invoice.notes && (
                <div className="col-span-2">
                  <h3 className="text-sm font-medium text-gray-500">Notes</h3>
                  <div className="mt-1 space-y-2 text-gray-700 whitespace-pre-wrap font-mono text-sm">
                    {invoice.notes.split('\n').map((line, i) => {
                      if (line === 'IMPORTED FROM SMALL INVOICE') {
                        return <p key={i} className="text-sm text-gray-500 font-medium mb-2">{line}</p>;
                      } else if (line === '') {
                        return <br key={i} />;
                      } else {
                        return <p key={i} className="font-mono text-sm">{line}</p>;
                      }
                    })}
                  </div>
                </div>
              )}
            </div>
          </Card>

          {/* Items Section - Conditional Rendering */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Items</h3>
            {isMobile ? renderMobileItems() : renderDesktopItems()}
          </div>

          {/* Mobile-only bottom close button */}
          {isMobile && (
            <div className="sticky bottom-0 bg-white pt-4 pb-6 border-t mt-8">
              <Button variant="outline" onClick={onClose} className="w-full">
                Close
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
